<template>
<div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
        <q-card>
            <q-card-section class="aj-container row justify-between items-center">
                <div class="col-10">
                    <div class="text-h5 text-weight-medium">
                        <template v-if="editTitle">
                            <input v-model="tempWidgetTitle" class="inline" id="inline-input"/>
                            <q-btn flat round color="grey-8" icon="mdi-check" size="sm" @click="onClickTitleSave"
                                   :loading="saveTitleLoading" :disable="!tempWidgetTitle.trim()"/>
                            <q-btn flat round color="grey-8" icon="mdi-close" size="sm"  @click="onClickTitleCancel"/>
                        </template>
                        <template v-else>{{widgetTitle}}
                            <q-btn flat round color="grey-8" icon="mdi-pencil-outline" size="sm"  @click="onClickTitleEdit"/>
                        </template>

                    </div>
                </div>
                <div class="col-2 text-right">
                    <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog(true)">
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </div>

            </q-card-section>
            <q-card-section class="q-mt-lg aj-container">
                <q-tabs v-model="tab" dense align="left" class="text-grey" active-color="primary"
                        indicator-color="primary" narrow-indicator>
                    <q-tab class="text-capitalize text-h6" style="font-size: 24px !important" name="payment_details" label="Payment Details"/>
                    <q-tab class="text-capitalize text-h6" style="font-size: 24px !important" name="qr_code" label="QR Code"/>
                </q-tabs>
                <q-separator class="q-mb-lg"></q-separator>
                <q-tab-panels v-model="tab" animated>
                    <q-tab-panel name="payment_details">
                        <div class="row q-col-gutter-md">
                            <div class="col-12">
                                <q-form ref="qFormPayment">
                                <form autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false">
                                    <q-editor v-model="paymentForm.description" :toolbar="Constants.editorOptions($q)"
                                              :fonts="Constants.fonts" min-height="450px" dense/>
                                </form>
                                </q-form>
                            </div>
                        </div>
                        <div class="bg-white text-right q-pt-lg aj-container">
                            <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" size="md" padding="sm md"
                                   unelevated @click="closeDialog(true)"/>
                            <q-btn label="Save Changes" color="primary" class="text-capitalize" size="md" padding="sm md" unelevated
                                   @click="handleSubmitPayment" :loading="saveDescriptionLoading"/>
                        </div>
                    </q-tab-panel>
                    <q-tab-panel name="qr_code">
                        <div class="row q-col-gutter-md">
                            <div class="col-12">
                                <q-card bordered flat>
                                    <q-card-section class="row q-py-none q-pr-none">
                                      <template v-if="!responsive || firstPage">
                                        <div class="q-py-md" :class="responsive?'col-12':'col'">
                                            <div class="row items-center justify-between q-pr-md q-pb-md">
                                                <div>
                                                    <h6 style="font-weight: 600" class="text-subtitle1 text-grey-9">{{qrcodes.length}} QR Codes</h6>
                                                </div>
                                                <div class="row">
                                                    <q-input class="q-mr-md mt-10-sm" square outlined v-model="search" label="Search" dense @update:model-value="onSearch">
                                                        <template v-slot:prepend><q-icon name="mdi-magnify" size="20px"/></template>
                                                    </q-input>

                                                    <q-btn class="no-shadow mt-10-sm" icon="add" unelevated size="sm" text-color="primary"
                                                           color="blue-1" label="New" @click="onAddQrcode" v-if="qrcodes.length<maxQrcodes"/>

                                                </div>
                                            </div>
                                            <div style="max-height: 67vh; overflow-y: auto">
                                                <q-list>
                                                  <draggable @end="drag=false; updateSequence()" @start="drag=true" v-model="qrcodes" item-key="id"
                                                               :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }" v-bind="dragOptions">
                                                    <template #item="{element:q}">
                                                    <q-item v-if="!q.isHidden" clickable class="q-px-none q-mb-sm list-item" @click="onEditQrcode(q)"
                                                            :active="activeQrcodeId===q.id" active-class="bg-blue-1 text-primary">
                                                        <q-btn round icon="drag_indicator" class="d-icon" text-color="grey"/>
                                                        <q-item-section avatar top>
                                                            <q-img height="52px" width="52px" class="rounded-borders" :src="imgWpx(q.image_url, 200)"/>
                                                        </q-item-section>
                                                        <q-item-section>
                                                            <q-item-label><p class="text-weight-medium q-mb-xs">{{q.name}}</p></q-item-label>
                                                        </q-item-section>
                                                        <q-item-section side>
                                                            <div class="text-grey-8 q-gutter-xs">
                                                                <q-btn class="gt-xs list-delete-icon" size="12px" flat dense round
                                                                       icon="mdi-delete-outline" @click="onDeleteQrcode(q)"/>
                                                                <q-toggle @click.stop="onChangeQrcodeStatus(q)" v-model="q.is_active"/>
                                                            </div>
                                                        </q-item-section>
                                                    </q-item>
                                                    </template>
                                                  </draggable>
                                                  <div v-if="noSearchResult">No QR Codes found.</div>
                                                </q-list>
                                            </div>
                                        </div>
                                        <q-separator vertical></q-separator>
                                      </template>
                                      <template v-if="!responsive || !firstPage">
                                        <div v-if="showQrcodeForm" class="q-py-md q-pl-md" :class="responsive?'col-12':'col'">
                                            <h6 style="font-weight: 600" class="text-subtitle1 text-grey-9 q-mb-lg">{{form.id?'Edit QR Code':'Add New'}}</h6>
                                            <div class="q-pr-md" style="height: calc(100vh - 350px); overflow-y: auto">
                                              <q-form ref="qForm">
                                                <q-input class="q-mb-md" square outlined v-model="form.name" label="Title*" maxlength="50" counter
                                                         :rules="[v => !!v || 'Please enter Title', v => v.length>=2 || 'Enter minimum 2 characters']" lazy-rules/>
                                                <h6 class="text-subtitle2 text-weight-bold text-grey-9 q-mt-lg ">Image</h6>
                                                <span class="text-caption text-grey-8">File type required JPG, PNG (Max 1MB)</span>
                                                <div class="row q-col-gutter-md q-mb-sm q-mt-xs">
                                                    <div class="col-6" @click="openUploadFile()" style="position: relative">
                                                        <div v-if="form.image_url" class="aj-uploader" style="width: 127px; height: 127px">
                                                            <img :src="imgWpx(form.image_url, 400)" class="img-tag">
                                                            <q-btn @click.stop="removeImage()" icon="cancel" text-color="grey" style="position: absolute;left: 6px" title="Remove Image"/>
                                                        </div>
                                                        <div v-else class="aj-uploader row justify-center items-center" style="height: 127px; width: 127px">
                                                            <q-btn round icon="mdi-cloud-upload-outline" unelevated text-color="primary"
                                                                   color="blue-1"/>
                                                        </div>
                                                    </div>
                                                </div>
                                              </q-form>
                                            </div>
                                            <div class="text-right q-mt-md q-mr-md">
                                                <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" unelevated
                                                       size="md" padding="sm md" @click="closeDialog(!responsive)"/>
                                                <q-btn label="Save" color="primary" class="text-capitalize" unelevated size="md"
                                                       padding="sm md" @click="handleSubmit" :loading="formLoading"/>
                                            </div>
                                        </div>
                                        <div v-else class="q-py-md q-pl-md text-center" :class="responsive?'col-12':'col'" style="height: calc(100vh - 220px);line-height: 80vh">
                                            <div style="line-height: 2;display: inline-block;">
                                                <q-avatar class="bg-blue-grey-1" size="100px" text-color="primary" icon="local_offer" />
                                                <div v-if="qrcodes.length">Please select any QR code to make changes.</div>
                                                <div v-else>No QR codes added yet. Click on Add New button to add QR code.</div>
                                            </div>
                                        </div>
                                      </template>
                                    </q-card-section>
                                </q-card>
                            </div>
                        </div>
                    </q-tab-panel>
                </q-tab-panels>
            </q-card-section>
        </q-card>

        <q-card>
            <q-separator/>
        </q-card>
    </q-dialog>

    <UploadFile v-model:dialog="uploadFileDialog" @fileResponse="onReceiveFileResponse" fileTypes=".jpg, .jpeg, .png" :maxSize="maxFileSize"/>
</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref, toRefs} from 'vue';
import {useSubscription, useMutation, useQuery} from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import UploadFile from "../Upload/UploadFile";
import {CREATE_UPDATE_DC_CMS, CREATE_UPDATE_DC_QR_CODES} from "../../graphql/mutations/dc";
import {SUBSCRIBE_DC_QR_CODES_BY_CUSTOMER_ID} from "../../graphql/subscriptions/organizations";
import {generate_DELETE_RECORD, generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
import updateRecord from "../../composables/updateRecord";
import deleteRecord from "../../composables/deleteRecord";
import draggable from 'vuedraggable';
import {GET_PAYMENT_DETAILS_BY_CUSTOMER_ID} from "../../graphql/queries/dc";
import useWidget from "../../composables/widgetTitle";
export default {
    name: "PaymentDetailForm",
    components: {UploadFile, draggable},
    props: ['dialog'],
    emits: ['update:dialog'],
    setup (props) {
        const { dialog } = toRefs(props);
        const customerId = ref(JSON.parse(localStorage.user).id);

      /* ---------------------- Payment Details section -------------------------*/
        const paymentForm = reactive({description:''});
        const {onResult: onResultPayment} = useQuery(GET_PAYMENT_DETAILS_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog, fetchPolicy:'network-only'}));
        onResultPayment(r => {
            if(r.data.dc_cms[0]?.payment_details) Object.copyExistingKeys(paymentForm, r.data.dc_cms[0].payment_details);
        });
        const { mutate: mDcCms } = useMutation(CREATE_UPDATE_DC_CMS);
      /* ------------------x----------------------x------------------------ */
      /* ---------------------- QR Codes section -------------------------*/
        const form = reactive({name:'', image_url:''});
        const qrcodes = ref([]);
        const {onResult } = useSubscription(SUBSCRIBE_DC_QR_CODES_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog}));
        onResult(r => qrcodes.value = r.data.qrcodes);

        const {widgetTitle, tempWidgetTitle, editTitle, saveTitleLoading,
            onClickTitleEdit, onClickTitleSave, onClickTitleCancel} = useWidget('qrcodes', dialog, customerId);
        const { mutate: mQrcode } = useMutation(CREATE_UPDATE_DC_QR_CODES);
        const { mutate: mUpdateQrcode } = useMutation(generate_UPDATE_RECORD('dc_customer_qrcodes'));
        const { mutate: mDeleteQrcode } = useMutation(generate_DELETE_RECORD('dc_customer_qrcodes'));
      /* ------------------x----------------------x------------------------ */
        return { customerId, form, mQrcode, qrcodes, mUpdateQrcode, mDeleteQrcode, paymentForm, mDcCms, widgetTitle,
                 tempWidgetTitle, editTitle, saveTitleLoading, onClickTitleEdit, onClickTitleSave, onClickTitleCancel};
    },
    data(){
        return{
            Constants, moment,
            formLoading: false,
            uploadFileDialog: false,
            search: '',
            noSearchResult: false,
            activeQrcodeId: '',
            tab: 'payment_details',
            maxFileSize: 1048576,        /* 1MB */
            maxQrcodes: 25,
            drag: false,
            showQrcodeForm: false,
           /* --- Payment Details --- */
            saveDescriptionLoading: false,
           /* -------x---------x----- */
          firstPage: true
        }
    },
    computed: {
        dragOptions() {
            return { animation: 200, group: "description", disabled: false, ghostClass: "ghost"};
        }
    },
    methods:{
      /* -------- Payment Details -------- */
        handleSubmitPayment(){
            this.$refs['qFormPayment'].validate().then(async (success) => {
                if(success) {
                    this.saveDescriptionLoading = true;
                    await this.savePaymentDetails();
                    this.saveDescriptionLoading = false;
                    this.$q.notify('Payment Details saved successfully');
                    this.closeDialog();
                }
            })
        },
        async savePaymentDetails(){
            await mutation({
                mutate: this.mDcCms,
                variables:{
                    dc_cms: {
                        customer_id: this.customerId, payment_details: this.paymentForm,
                        created_by: this.customerId, updated_by: this.customerId
                    },
                    updateColumns: ['payment_details', 'updated_by'],
                }
            });
        },
        resetFormPayment(){
            // this.paymentForm.description = '';
            this.editTitle = false;
            this.$refs['qFormPayment']?.resetValidation();
        },
      /* ----------x------------x--------- */
        onSearch(val){
            val = val?.toLowerCase() || '';
            this.qrcodes.forEach(q => q.isHidden = q.name.toLowerCase().indexOf(val) === -1);
            this.noSearchResult = !this.qrcodes.find(q => !q.isHidden);
        },
        async updateSequence(){
            this.qrcodes.forEach((q, index) => { q.sequence = index+1; q.customer_id = this.customerId; delete q.__typename; });
            await mutation({
                mutate: this.mQrcode,
                variables:{
                    qrcode: this.qrcodes,
                    updateColumns: ['sequence'],
                }
            });
            this.$q.notify('QR Codes sequence updated successfully');
        },
        onAddQrcode(){
            this.resetForm();
            this.showQrcodeForm = true;
            this.firstPage = false;
        },
        onEditQrcode(row){
            this.activeQrcodeId = row.id;
            this.form.id = '';
            Object.copyExistingKeys(this.form, JSON.parse(JSON.stringify(row)));
            this.showQrcodeForm = true;
            this.firstPage = false;
        },
        removeImage(){
            this.form.image_url = '';
        },
        openUploadFile(){
            this.uploadFileDialog = true;
        },
        onReceiveFileResponse(fileResponse){
            this.form.image_url = fileResponse;
        },
        onChangeQrcodeStatus(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to ${row.is_active?'active':'inactive'} QR code ?`,
            }).onOk(() => this.updateQrcodeStatus(row)).onCancel(() => row.is_active = !row.is_active)
        },
        async updateQrcodeStatus(row){
            await updateRecord(this.mUpdateQrcode, row.id, {is_active: row.is_active});
            this.$q.notify('QR Code Status updated successfully');
        },
        onDeleteQrcode(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to delete QR code ?`,
            }).onOk(() => this.deleteQrcode(row))
        },
        async deleteQrcode(row){
            await deleteRecord(this.mDeleteQrcode, row.id);
            this.$q.notify('QR Code deleted successfully');
            let index = this.qrcodes.findIndex(q => q.id === row.id);
            if(index > -1) this.qrcodes.splice(index, 1);
            this.updateSequence();
        },
        handleSubmit(){
            if(this.qrcodes.length >= this.maxQrcodes && !this.form.id)
                return this.$q.notify(`Cannot add more than ${this.maxQrcodes} QR codes`);
            this.$refs['qForm'].validate().then(success => {
                if(success && !this.form.image_url) this.$q.notify(`Please upload image`);
                else if(success && this.form.image_url) this.saveQrcode();
            })
        },
        async saveQrcode(){
            this.formLoading = true;
            await mutation({
                mutate: this.mQrcode,
                variables:{
                    qrcode: {
                        ...this.form, customer_id: this.customerId, sequence:this.qrcodes.length + 1
                    },
                    updateColumns: [...Object.keys(this.form)],
                }
            });
            this.formLoading = false;
            this.$q.notify('QR Code saved successfully');
            // this.closeDialog();
            this.resetForm();
        },
        resetForm(){
            this.form.name = '';
            this.form.image_url = '';
            delete this.form.id;
            this.$refs['qForm']?.resetValidation();
            this.activeQrcodeId = '';
            this.firstPage = true;
        },
        closeDialog(close){
            this.resetForm();
            this.resetFormPayment();
            this.showQrcodeForm = false;
          if(close) this.$emit('update:dialog', false);
        }
    }
}
</script>

<style lang="scss">
#inline-input{
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
}
.img-tag{
    width: 100%;
    height: 100%;
    object-fit: contain; /* cover */
}
.list-delete-icon {
    visibility: hidden;
}
.list-item:hover {
    .list-delete-icon {
        visibility: visible;
        color: red;
    }
    .drag-icon {
        visibility: visible;
    }
}
.drag-icon {
    visibility: hidden;
}
.rounded-borders{
    border: 1px solid grey;
    border-radius: 5px !important;
}
@media only screen and (max-width: 600px) {
  #inline-input{
    width: 66%;
  }
}
</style>
