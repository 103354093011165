<template>
  <div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
      <q-card>
        <q-card-section class="aj-container row justify-between items-center">
          <div>
            <div class="text-h5 text-weight-medium">Business Details</div>
          </div>
          <div>
            <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog" v-if="showCancelButton">
              <q-tooltip content-class="text-primary">Close</q-tooltip>
            </q-btn>
          </div>

        </q-card-section>
        <q-card-section class="q-mt-lg aj-container">
          <q-form ref="qForm">
            <div class="row q-col-gutter-md" style="margin-bottom: 100px;">

              <div class="q-mt-lg col-12 "
                   style="align-items: center; border: 1px solid #00abec; border-radius: 10px; background-color: rgba(0,171,236,0.05)">
                <div class="row q-col-gutter-md wrap">
                  <div class="col-12 col-sm-12 col-md-4">
                    <h3 class="text-subtitle1 text-weight-medium mb-2">Your Domain Name</h3>
                    <!-- Name (xyz.${Constants.publish_app_domain}) -->
                    <q-input square outlined v-model="form.website" dense reactive-rules
                             :label="`Sub-domain*`" @blur="onBlurDomain" ref="sub-domain"
                             :rules="[v => !!v || 'Please enter Sub-domain Name', v => v.length >= 2 || 'Enter minimum 2 characters',
                                      v => /^[a-zA-Z0-9\-]+$/g.test(v) || 'Invalid Sub-domain Name',
                                      v => isUniqueDomain || 'That domain is taken. Try another.']" maxlength="20">
                      <template v-slot:before>
                        <div class="side-text">https://</div>
                      </template>
                      <template v-slot:after>
                        <div class="side-text">{{ '.' + Constants.publish_app_domain }}</div>
                      </template>
                    </q-input>
                  </div>
                  <div class="col-12 col-sm-12 col-md-8 q-pl-md-xl">
                    <a class="text-h5 text-weight-regular text-indigo-6" :href="url" target="_blank" style="word-break: break-word">{{ url }}</a>
                    <p class="q-mt-sm text-body1 text-grey-7 q-mb-none">This URL will be used to generate your unique
                      URL, Which
                      you can share with your customers.</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3">
                <h3 class="text-subtitle1 text-weight-medium">Company Details</h3>
              </div>
              <div class="col-12 col-sm-12 col-md-9 q-mb-xl">
                <div class="row q-col-gutter-md">
                 <!-- <div class="col-12">
                    <div class="cursor-pointer d-flex" @click="uploadFileDialog=true">
                      <div v-if="form.logo" style="position: relative">
                        <img :src="imgWpx(form.logo, 400)" class="aj-avatar-upload" @error="form.logo = ''">
                        <q-btn round @click.stop="removeImage()" icon="cancel" text-color="red" size="md"
                               style="position: absolute; left: 35px; top: -10px" title="Remove Image"/>
                      </div>
                      <div v-else class="aj-avatar-upload bg-grey-3 row justify-center bg-blue-1 items-center">
                        <span class="mdi mdi-cloud-upload-outline text-h6 text-primary"></span>
                                                        <q-btn round icon="mdi-cloud-upload-outline" unelevated text-color="primary" color="blue-1"/>
                      </div>
                      <div class="q-ml-md">
                        <h6 class="text-body2 text-weight-medium q-mt-sm">Company Logo</h6>
                        <span class="text-caption text-grey-8">Image size should be 512px x 512px, File type: PNG or JPG (File size: Max 1MB)</span>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <q-input class="q-pb-none" square outlined v-model="form.name" label="Company Name*" maxlength="50"
                             :rules="[v => !!(v&&v.trim()) || 'Please enter Company name']"/>
                  </div>

                 <!-- <div class="col-6">
                    <q-input class="q-pb-none" square label="Company Slogan" v-model="form.slogan"
                             outlined maxlength="70"/>
                  </div> -->


                  <div class="col-6">
                    <q-input class="q-pb-none" square outlined v-model="form.address.addressLine1"
                             label="Address Line 1*"
                             :rules="[v => !!(v&&v.trim()) || 'Please enter Address Line 1']"/>
                  </div>
                  <div class="col-6">
                    <q-input class="q-pb-none" square outlined v-model="form.address.addressLine2"
                             label="Address Line 2"/>
                  </div>
                  <div class="col-12">
                    <q-input square outlined v-model="form.address.area" label="Area"/>
                  </div>
                  <div class="col-6">
                    <q-select square label="City/Dist" v-model="form.address.city" outlined
                              debounce="400" use-input clearable :options="cityArr" option-label="city"
                              @filter="searchCity"
                              :loading="cityLoading" @update:model-value="autoFillStateAndCountry">
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">No results</q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>
                  <div class="col-6">
                    <q-input square outlined v-model="form.address.pincode" label="Pincode"/>
                  </div>
                  <div class="col-6">
                    <q-select square label="State" v-model="form.address.state" outlined
                              debounce="500" use-input clearable :options="stateArr" option-label="state"
                              @filter="searchState"
                              :loading="stateLoading" @update:model-value="autoFillCountry">
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">No results</q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>
                  <div class="col-6">
                    <q-select square label="Country" v-model="form.address.country" outlined debounce="500"
                              clearable :options="countryArr" option-label="name" @update:model-value="autoFillDialCode"
                              emit-value :loading="countryLoading">
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">No results</q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>
                </div>
              </div>


              <div class="col-12 col-sm-12 col-md-3">
                <h3 class="text-subtitle1 text-weight-medium">Personal Details</h3>
              </div>
              <div class="col-12 col-sm-12 col-md-9">
                <div class="row q-col-gutter-md">
                  <div class="col-6">
                    <q-input class="q-pb-none" square outlined v-model="form.business_detail.personName"
                             label="Person Name*"
                             maxlength="50"
                             :rules="[v => !!(v&&v.trim()) || 'Please enter Person name', v => v.length >= 3 || 'Enter minimum 3 characters',
                                          v => /^[a-zA-Z0-9 ]{1,}$/g.test(v) || 'Please enter valid String']"/>
                  </div>
                  <div class="col-6">
                    <q-input class="q-pb-none" square label="Designation" v-model="form.designation"
                             outlined maxlength="30"/>
                  </div>

                  
                  <div class="col-6">
                    <div class="row">
                      <q-select class=" col-2 text-no-wrap" square outlined :options="Countries"
                                v-model="form.mobile_country_code" option-value="dialCode" emit-value label=""
                                :rules="[v => !!v || 'Please enter Country Code']">
                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps">
                            <q-item-section>
                              <q-item-label>{{ scope.opt.name }} ({{ scope.opt.dialCode }})</q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                      <q-input class=" col-10" square outlined v-model="form.mobile" label="Phone*"
                               mask="##########"
                               :rules="[v => !!v || 'Please enter Contact Number', v => v.length === 10 || 'Invalid Contact Number']"/>
                    </div>
                  </div>
                  <q-input class=" col-6" square outlined v-model="form.email" label="Email*" disable
                           :rules="[v => !!v || 'Please enter Email', v => Constants.EmailPattern.test(v) || 'Invalid Email']"/>
                </div>
              </div>

             <!-- <div class="col-12 col-sm-12 col-md-3">
                <h3 class="text-subtitle1 text-weight-medium">
                  <template v-if="editTitle">
                    <input v-model="tempServiceTitle" class="inline" id="inline-input"/>
                    <q-btn flat round color="grey-8" icon="mdi-check" size="sm" @click="onClickTitleSave"
                           :disable="!tempServiceTitle.trim()"/>
                    <q-btn flat round color="grey-8" icon="mdi-close" size="sm"  @click="onClickTitleCancel"/>
                  </template>
                  <template v-else>{{form.service_title}}
                    <q-btn flat round color="grey-8" icon="mdi-pencil-outline" size="sm"  @click="onClickTitleEdit"/>
                  </template>
                </h3>
                <img src="../../assets/images/business-services.jpg" alt="business-services.jpg" style="width: 80%; border: 1px solid lightgrey; border-radius: 13px" class="q-mt-md">
                <p class="text-caption text-gray">Example Image</p>
              </div>
              <div class="col-12 col-sm-12 col-md-9  q-mb-xl">
                <p class="text-caption text-gray">If you will add your service, we will show in your ByteCard (Backside) Image else ByteCard (Backside) will be blank with logo. For example, you can check Example Image</p>
                <div class="col-6">
                  <template v-for="(service, idx) in form.services">
                    <div class="row q-col-gutter-xs q-mb-lg">
                      <q-input class="q-pb-none" square label="Services" v-model="form.services[idx]" outlined
                               :class="idx!==0? 'col-11' :'col-12'" maxlength="30"/>
                      <div class="col-1 text-right">
                        <q-btn color="negative" round dense flat icon="close" class="mt-3"
                               @click="removeService(idx)" v-if="idx!==0"/>
                      </div>
                    </div>
                    <q-btn text-color="primary" color="blue-1" @click="addService"
                           v-if="form.services.length === idx+1 && form.services.length<14" icon="mdi-plus" label="Add New"/>
                  </template>
                </div>
              </div>  -->






                        <!--          <div class="col-12  row justify-center">
                                      <div class="q-mb-lg cursor-pointer" @click="uploadFileDialog=true">
                                          <div v-if="form.logo" style="position: relative" class="row justify-center">
                                              <img :src="imgWpx(form.logo, 400)" class="aj-avatar-upload" @error="form.logo = ''">
                                              <q-btn round @click.stop="removeImage()" icon="cancel" text-color="red" size="md" style="position: absolute; right: 155px" title="Remove Image"/>
                                          </div>
                                          <div v-else class="aj-avatar-upload bg-grey-3 row justify-center bg-blue-1 items-center" style="margin: 0 auto">
                                              <span class="mdi mdi-cloud-upload-outline text-h3 text-primary"></span>
                                          </div>
                                          <div class="text-center">
                                            <h6 class="text-body2 text-grey-8 q-mt-sm">Company Logo<br><span class="text-caption">Image size should be 512px x 512px, File type: PNG or JPG (File size: Max 1MB)</span></h6>
                                          </div>
                                      </div>
                                  </div> -->


            </div>
          </q-form>
        </q-card-section>
        <q-footer class="bg-white" style="box-shadow: 0px -2px 10px 0px rgba(0,0,0,0.13)">
          <div class="aj-container text-right q-py-sm">
            <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" size="md" @click="closeDialog"
                   v-if="showCancelButton"/>
            <q-btn label="Save Changes" color="primary" class="text-capitalize" size="md" unelevated @click="handleSubmit"
                   :loading="formLoading"/>
          </div>
        </q-footer>
      </q-card>
    </q-dialog>

   <!-- <UploadFile v-model:dialog="uploadFileDialog" @fileResponse="form.logo=$event" fileTypes=".jpg, .jpeg"/> -->
  </div>
</template>

<script>
import moment from 'moment';
import Constants from "../../constants/Constants";
import {reactive, ref} from 'vue';
import {useQuery, useMutation} from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {GET_ORGANIZATION_BY_ID} from "../../graphql/queries/organizations";
import {CREATE_UPDATE_CUSTOMER} from "../../graphql/mutations/organizations";
/* import UploadFile from "../Upload/UploadFile"; */
import useSearchCity from "../../composables/searchCity";
import useSearchState from "../../composables/searchState";
import useSearchCountry from "../../composables/searchCountry";
import {countryCode} from '../../mixins/countryCode';
import checkDomain from "../../composables/checkDomain";

export default {
  name: "BizDetailForm",
 /* components: {UploadFile}, */
  mixins: [countryCode],
  props: ['dialog'],
  emits: ['update:dialog'],
  setup(props) {
    const customerId = ref(JSON.parse(localStorage.user).id);

    const form = reactive({
      id: '', name: '', mobile: null, email: '', whatsapp: null, website: null, /*logo: '',*/ mobile_country_code: '+91',
      address: {addressLine1: '', addressLine2: '', area: '', city: '', state: '', country: '', pincode: ''},
      business_detail: {personName: '', instagramLink: null, twitterLink: null}, designation:null /*slogan:null,
      services: [''], service_title: 'Our Services' */
    });
    const existingDomain = ref(null);
    const {onResult} = useQuery(GET_ORGANIZATION_BY_ID, () => ({id: customerId.value}),
        () => ({enabled: !!customerId.value && props.dialog, fetchPolicy: 'network-only'}));
    onResult(r => {
      Object.copyExistingKeys(form, JSON.parse(JSON.stringify(r.data.organizations[0])));
      form.address = form.address || {
        addressLine1: '',
        addressLine2: '',
        area: '',
        city: '',
        state: '',
        country: '',
        pincode: ''
      };
      form.business_detail = form.business_detail || {personName: '', instagramLink: null, twitterLink: null};
     /* form.services = form.services&&form.services.length?form.services:['']; */
      existingDomain.value = form.website;
    });

    const {cityArr, searchCity, cityLoading} = useSearchCity();
    const {stateArr, searchState, stateLoading} = useSearchState();
    const {countryArr, searchCountry, countryLoading} = useSearchCountry();
    const {mutate: mSaveBusinessDetail} = useMutation(CREATE_UPDATE_CUSTOMER);

    return {
      form, mSaveBusinessDetail, cityArr, searchCity, cityLoading, stateArr, searchState, stateLoading,
      countryArr, searchCountry, countryLoading, existingDomain
    };
  },
  data() {
    return {
      Constants, moment,
      formLoading: false,
      showCancelButton: false,
     /* uploadFileDialog: false, */
      isUniqueDomain: true,
     /* tempServiceTitle: '',
      editTitle: false */
    }
  },
  computed: {
    url() {
      return `https://${this.form.website ? (this.form.website.toLowerCase() + '.') : ''}${Constants.publish_app_domain}`
    },
  },
  watch: {
    'dialog': function () {
      this.showCancelButton = !!localStorage.isBusinessDetailCompleted
    }
  },
  methods: {
   /* addService(){
      this.form.services.push('');
    },
    removeService(index){
      this.form.services.splice(index, 1);
    },
    onClickTitleEdit(){
      this.tempServiceTitle = this.form.service_title;
      this.editTitle = true;
    },
    onClickTitleCancel(){
      this.editTitle = false;
    },
    onClickTitleSave() {
      this.form.service_title = this.tempServiceTitle;
      this.editTitle = false;
    }, */
    async onBlurDomain() {
      this.isUniqueDomain = true;
      this.$refs['sub-domain'].validate();
      if (!this.$refs['sub-domain'].hasError && this.form.website !== this.existingDomain) {
        let flag = await checkDomain(this.form.website);
        this.isUniqueDomain = flag;
        this.$refs['sub-domain'].validate();
      }
    },
    autoFillStateAndCountry(val) {
      if (val) {
        this.form.address.state = val.state;
        this.form.address.country = val.country;
        this.form.address.city = val.city;
        this.form.mobile_country_code = val.dial_code;
      }
    },
    autoFillCountry(val) {
      if (val) {
        this.form.address.state = val.state;
        this.form.address.country = val.country;
        this.form.mobile_country_code = val.dial_code;
      }
    },
    autoFillDialCode(val) {
      if (val) {
        this.form.address.country = val.name;
        this.form.mobile_country_code = val.dial_code;
      }
    },
   /* removeImage() {
      this.$q.dialog({
        title: 'Confirm', cancel: true, persistent: true,
        message: `Are you sure you want to remove Company Logo ?`,
      }).onOk(() => this.form.logo = '')
    }, */
    handleSubmit() {
      this.$refs['qForm'].validate().then(async (success) => {
        if (success) {
          await this.onBlurDomain();
          if (this.isUniqueDomain) this.saveBusinessDetail().then();
        }
      })
    },
    async saveBusinessDetail() {
     /* let services = this.form.services.filter(s => !!s); */
      this.formLoading = true;
      await mutation({
        mutate: this.mSaveBusinessDetail,
        variables: {
          customer: {...this.form, website: this.form.website?.toLowerCase()/*, services*/},
          updateColumns: [...Object.keys(this.form)],
        }
      });
      this.formLoading = false;
      this.$q.notify('Business Detail saved successfully');
      this.closeDialog();
    },
    resetForm() {
      this.form.name = '';
      this.form.mobile = null;
      this.form.email = '';
      this.form.whatsapp = null;
      this.form.designation = null;
     /* this.form.slogan = null;
      this.form.services = [''];
      this.form.service_title = 'Our Services';
      this.editTitle = false; */
      this.form.website = null;
     /* this.form.logo = ''; */
      this.form.mobile_country_code = '+91';
      this.form.address = {addressLine1: '', addressLine2: '', area: '', city: '', state: '', country: '', pincode: ''};
      this.form.business_detail = {personName: '', instagramLink: null, twitterLink: null};
      this.isUniqueDomain = true;
    },
    closeDialog() {
      this.$refs['qForm'].resetValidation();
      this.resetForm();
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>
.pb-20 {
  padding-bottom: 20px;
}

/deep/ .q-field__native {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.side-text {
  font-size: 20px;
  color: black;
}
</style>
