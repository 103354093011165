<template>
<div>
    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
        <q-card>
            <q-form ref="qForm">
            <q-card-section class="aj-container row justify-between items-center">
                <div class="col-10">
                    <div class="text-h5 text-weight-medium">
                        <template v-if="editTitle">
                            <input v-model="tempWidgetTitle" class="inline" id="inline-input"/>
                            <q-btn flat round color="grey-8" icon="mdi-check" size="sm" @click="onClickTitleSave"
                                   :loading="saveTitleLoading" :disable="!tempWidgetTitle.trim()"/>
                            <q-btn flat round color="grey-8" icon="mdi-close" size="sm"  @click="onClickTitleCancel"/>
                        </template>
                        <template v-else>{{widgetTitle}}
                            <q-btn flat round color="grey-8" icon="mdi-pencil-outline" size="sm"  @click="onClickTitleEdit"/>
                        </template>

                    </div>
                </div>
                <div class="col-2 text-right">
                    <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog">
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </div>

            </q-card-section>
            <q-card-section class="q-mt-lg aj-container">
                <div class="row q-col-gutter-md">
                    <div class="col-12" style="margin-bottom: 100px">
                        <form autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false">
                            <q-editor v-model="form.description" :toolbar="Constants.editorOptions($q)"
                                      :fonts="Constants.fonts" min-height="450px" dense/>
                        </form>
                    </div>
                </div>
                <div class="bg-white text-right q-pb-md aj-container">
                    <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" size="md" padding="sm md" unelevated @click="closeDialog"/>
                    <q-btn label="Save Changes" color="primary" size="md" padding="sm md" class="text-capitalize" unelevated @click="handleSubmit" :loading="formLoading"/>
                </div>
            </q-card-section>
            </q-form>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref, toRefs} from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {CREATE_UPDATE_DC_CMS} from "../../graphql/mutations/dc";
import {GET_ABOUT_US_BY_CUSTOMER_ID} from "../../graphql/queries/dc";
import useWidget from "../../composables/widgetTitle";
export default {
    name: "AboutUsForm",
    props: ['dialog'],
    emits: ['update:dialog'],
    setup (props) {
        const { dialog } = toRefs(props);
        const customerId = ref(JSON.parse(localStorage.user).id);

        const form = reactive({description:''});
        const {onResult} = useQuery(GET_ABOUT_US_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog, fetchPolicy:'network-only'}));
        onResult(r => {
            if(r.data.dc_cms[0]?.about_us) Object.copyExistingKeys(form, r.data.dc_cms[0].about_us);
        });

        const {widgetTitle, tempWidgetTitle, editTitle, saveTitleLoading, onClickTitleEdit, onClickTitleSave,
               onClickTitleCancel} = useWidget('about_us', dialog, customerId);
        const { mutate: mDcCms } = useMutation(CREATE_UPDATE_DC_CMS);
        return { customerId, form, mDcCms, widgetTitle, tempWidgetTitle, editTitle, saveTitleLoading, onClickTitleEdit,
                 onClickTitleSave, onClickTitleCancel };
    },
    data(){
        return{
            Constants, moment,
            formLoading: false,
        }
    },
    methods:{
        handleSubmit(){
            this.$refs['qForm'].validate().then(async (success) => {
                if(success) {
                    this.formLoading = true;
                    await this.saveAboutUs();
                    this.formLoading = false;
                    this.$q.notify('About Us saved successfully');
                    this.closeDialog();
                    console.log('form submitted')
                }
            })
        },
        async saveAboutUs(){
            await mutation({
                mutate: this.mDcCms,
                variables:{
                    dc_cms: {
                        customer_id: this.customerId, about_us: this.form,
                        created_by: this.customerId, updated_by: this.customerId
                    },
                    updateColumns: ['about_us', 'updated_by'],
                }
            });
        },
        resetForm(){
            this.form.description = '';
            this.editTitle = false;
        },
        closeDialog(){
            this.$refs['qForm'].resetValidation();
            this.resetForm();
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>
#inline-input{
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
}
@media only screen and (max-width: 600px) {
  #inline-input{
    width: 66%;
  }
}
</style>
