<template>
<div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
        <q-card>
            <q-card-section class="aj-container row justify-between items-center">
                <div>
                    <div class="text-h5 text-weight-medium">Videos</div>
                </div>
                <div>
                    <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog(true)">
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </div>
            </q-card-section>
            <q-card-section class="q-mt-lg aj-container">
                <div class="row q-col-gutter-md">
                    <div class="col-12">
                        <q-card bordered flat>
                            <q-card-section class="row q-py-none q-pr-none">
                              <template v-if="!responsive || firstPage">
                                <div class="q-py-md" :class="responsive?'col-12':'col'">
                                    <div class="row items-center justify-between q-pr-md q-pb-md">
                                        <div>
                                            <h6 style="font-weight: 600" class="text-subtitle1 text-weight-bold text-grey-9">{{videos.length}} Videos</h6>
                                            <span class="text-body2 text-grey-8">You can add upto {{maxVideos}} Videos</span>
                                        </div>
                                        <div class="row">
                                            <q-input class="q-mr-md mt-10-sm" square outlined label="Search" v-model="search" dense @update:model-value="onSearch">
                                                <template v-slot:prepend><q-icon name="mdi-magnify" size="20px"/></template>
                                            </q-input>

                                            <q-btn class="no-shadow mt-10-sm" icon="add" unelevated size="sm" text-color="primary"
                                                   color="blue-1" label="New" @click="onAddVideo" v-if="videos.length<maxVideos"/>

                                        </div>
                                    </div>
                                    <div style="max-height: 67vh; overflow-y: auto">
                                        <q-list>
                                          <draggable @end="drag=false; updateSequence()" @start="drag=true" v-model="videos" item-key="id"
                                                       :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }" v-bind="dragOptions">
                                            <template #item="{element:v}">
                                            <q-item v-if="!v.isHidden" clickable class="q-px-none q-mb-sm list-item" @click="onEditVideo(v)"
                                                    :active="activeVideoId===v.id" active-class="bg-blue-1 text-primary">
                                                <q-btn round icon="drag_indicator" class="drag-icon" text-color="grey"/>
                                                <q-item-section avatar>
                                                    <q-img height="52px" width="52px" class="rounded-borders" :src="getYoutubeThumbnail(v.videoUrl)"/>
                                                </q-item-section>
                                              <q-item-section>

                                              <q-item-label lines="1" class="text-body1 text-weight-medium">{{v.name}}</q-item-label>
                                              </q-item-section>
                                                <q-item-section side>
                                                    <div class="text-grey-8 q-gutter-xs">
                                                        <q-btn class="gt-xs list-delete-icon" size="12px" flat dense round
                                                               icon="mdi-delete-outline" @click.stop="onDeleteVideo(v)"/>
                                                        <q-toggle @click.stop="onChangeVideoStatus(v)" v-model="v.is_active"/>
                                                    </div>
                                                </q-item-section>
                                            </q-item>
                                            </template>
                                          </draggable>
                                          <div v-if="noSearchResult">No Videos found.</div>
                                        </q-list>
                                    </div>
                                </div>
                                <q-separator vertical/>
                              </template>
                              <template v-if="!responsive || !firstPage">
                                <div v-if="showVideoForm" class="q-py-md q-pl-md" :class="responsive?'col-12':'col'">
                                    <h6 style="font-weight: 600" class="text-subtitle1 text-weight-bold text-grey-9 q-mb-lg">{{form.id?'Edit Video':'Add New'}}</h6>
                                    <div class="q-pr-md" style="height: 63vh; overflow-y: auto">
                                        <q-form ref="qForm">
                                        <q-input class="q-mb-lg" square outlined v-model="form.name" label="Title*" maxlength="50" counter
                                                 :rules="[v => !!v || 'Please enter Title', v => v.length>=3 || 'Enter minimum 3 characters']" lazy-rules/>
                                        <q-input class="q-mb-lg" square outlined v-model="form.videoUrl" label="Enter URL*" lazy-rules
                                                 :rules="[v => !!v || 'Please enter URL', v => Constants.YoutubePattern.test(v) || 'Invalid URL']"/>
                                        <div class="aj-video-preview-container bg-grey-3 row justify-center items-center">
                                            <q-img v-if="form.videoUrl" height="100%" width="100%" :src="getYoutubeThumbnail(form.videoUrl)"/>
                                            <q-icon v-else name="mdi-video" class="text-grey-8" style="font-size: 4em;"/>
                                        </div>
                                        </q-form>
                                    </div>
                                    <div class="text-right q-mt-lg q-mr-md">
                                        <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" unelevated size="md"
                                               padding="sm md" @click="closeDialog(!responsive)"/>
                                        <q-btn label="Save" color="primary" class="text-capitalize" unelevated size="md" padding="sm md"
                                               @click="handleSubmit" :loading="formLoading"/>
                                    </div>
                                </div>
                                <div v-else class="q-py-md q-pl-md text-center" :class="responsive?'col-12':'col'" style="height: 80vh;line-height: 80vh">
                                    <div style="line-height: 2;display: inline-block;">
                                        <q-avatar class="bg-blue-grey-1" size="100px" text-color="primary" icon="videocam" />
                                        <div v-if="videos.length">Please select any video to make changes.</div>
                                        <div v-else>No Videos added yet. Click on Add New button to add video.</div>
                                    </div>
                                </div>
                              </template>
                            </q-card-section>
                        </q-card>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref} from 'vue';
import { useSubscription, useMutation } from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {CREATE_UPDATE_DC_VIDEO} from "../../graphql/mutations/dc";
import {SUBSCRIBE_DC_VIDEOS_BY_CUSTOMER_ID} from "../../graphql/subscriptions/organizations";
import {generate_DELETE_RECORD, generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
import updateRecord from "../../composables/updateRecord";
import deleteRecord from "../../composables/deleteRecord";
import draggable from 'vuedraggable';
export default {
    name: "VideoForm",
    components: {draggable},
    props: ['dialog'],
    emits: ['update:dialog'],
    setup (props) {
        const customerId = ref(JSON.parse(localStorage.user).id);
        const form = reactive({name:'', videoUrl:null});

        const videos = ref([]);
        const {onResult } = useSubscription(SUBSCRIBE_DC_VIDEOS_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog}));
        onResult(r => videos.value = r.data.videos);

        const { mutate: mVideo } = useMutation(CREATE_UPDATE_DC_VIDEO);
        const { mutate: mUpdateVideo } = useMutation(generate_UPDATE_RECORD('dc_customer_videos'));
        const { mutate: mDeleteVideo } = useMutation(generate_DELETE_RECORD('dc_customer_videos'));
        return { customerId, form, mVideo, videos, mUpdateVideo, mDeleteVideo};
    },
    data(){
        return{
            Constants, moment,
            formLoading: false,
            search: '',
            noSearchResult: false,
            activeVideoId: '',
            drag: false,
            showVideoForm: false,
            maxVideos: 25,
            firstPage: true
        }
    },
    computed: {
        dragOptions() {
            return { animation: 200, group: "description", disabled: false, ghostClass: "ghost"};
        }
    },
    methods:{
        getYoutubeThumbnail(videoUrl){
          let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
          let match = videoUrl.match(regExp);
          let videoId = (match && match[7].length == 11) ? match[7] : false;
          return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
        },
        onSearch(val){
            val = val?.toLowerCase() || '';
            this.videos.forEach(v => v.isHidden = v.name.toLowerCase().indexOf(val) === -1);
            this.noSearchResult = !this.videos.find(v => !v.isHidden);
        },
        async updateSequence(){
            this.videos.forEach((v, index) => { v.sequence = index+1; v.customer_id = this.customerId; delete v.__typename; });
            await mutation({
                mutate: this.mVideo,
                variables:{
                    video: this.videos,
                    updateColumns: ['sequence'],
                }
            });
            this.$q.notify('Videos sequence updated successfully');
        },
        onAddVideo(){
            this.resetForm();
            this.showVideoForm = true;
            this.firstPage = false;
        },
        onEditVideo(row){
            this.activeVideoId = row.id;
            this.form.id = '';
            Object.copyExistingKeys(this.form, JSON.parse(JSON.stringify(row)));
            this.showVideoForm = true;
            this.firstPage = false;
        },
        onChangeVideoStatus(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to ${row.is_active?'active':'inactive'} video ?`,
            }).onOk(() => this.updateVideoStatus(row)).onCancel(() => row.is_active = !row.is_active)
        },
        async updateVideoStatus(row){
            await updateRecord(this.mUpdateVideo, row.id, {is_active: row.is_active});
            this.$q.notify('Video Status updated successfully');
        },
        onDeleteVideo(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to delete video ?`,
            }).onOk(() => this.deleteVideo(row))
        },
        async deleteVideo(row){
            await deleteRecord(this.mDeleteVideo, row.id);
            this.$q.notify('Video deleted successfully');
            let index = this.videos.findIndex(v => v.id === row.id);
            if(index > -1) this.videos.splice(index, 1);
            this.updateSequence();
        },
        handleSubmit(){
            if(this.videos.length >= this.maxVideos && !this.form.id)
                return this.$q.notify(`Cannot add more than ${this.maxVideos} videos`);
            this.$refs['qForm'].validate().then(success => {
                if(success) this.saveVideo();
            })
        },
        async saveVideo(){
            this.formLoading = true;
            await mutation({
                mutate: this.mVideo,
                variables:{
                    video: {
                        ...this.form, customer_id: this.customerId, sequence:this.videos.length + 1
                    },
                    updateColumns: [...Object.keys(this.form)],
                }
            });
            this.formLoading = false;
            this.$q.notify('Video saved successfully');
            this.resetForm();
        },
        resetForm(){
            this.form.name = '';
            this.form.videoUrl = null;
            delete this.form.id;
            this.$refs['qForm']?.resetValidation();
            this.activeVideoId = '';
            this.firstPage = true;
        },
        closeDialog(close){
            this.resetForm();
            this.showVideoForm = false;
          if(close) this.$emit('update:dialog', false);
        }
    }
}
</script>

<style lang="scss">
.video-tag{
    width: 100%;
    height: 100%;
    object-fit: contain; /* cover */
}
.list-delete-icon {
    visibility: hidden;
}
.list-item:hover {
    .list-delete-icon {
        visibility: visible;
        color: red;
    }
    .drag-icon {
        visibility: visible;
    }
}
.drag-icon {
    visibility: hidden;
}
.rounded-borders{
    border: 1px solid grey;
    border-radius: 5px !important;
}
</style>
