<template>
  <div class="aj-container">
    <div class="row justify-between items-center q-mt-lg">
      <h1 class="text-h5 q-my-none" style="font-weight: 600">Settings</h1>
    </div>
    <p>Manage your ByteCard account from here</p>
    <div class="row q-col-gutter-lg">
      <div class="col-xs-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <q-card-section>
            <div class="row justify-between">
              <div>
                <h1 class="text-h6 text-weight-bold" style="font-weight: 600">Whatsapp Settings</h1>
                <div>Set Default Whatsapp message</div>
                <q-btn outline class="text-capitalize q-mt-lg text-grey-8" @click.native="openWhatsappSettings" label="Show Details"/>
              </div>
            </div>
            <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -18px -6px 0px 0px">
              <q-tooltip class="bg-blue text-body2" max-width="50%">
                In the Product Section, we have a whatsapp button which allows
                your customer to send you WhatsApp with a default message. You can set this default
                message from here, which will be received by you when the Customer sends
                WhatsApp
              </q-tooltip>
            </q-icon>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <WaMessageForm v-model:dialog="waMessageDialog"/>
  </div>
</template>

<script>
import WaMessageForm from "../components/Settings/WaMessageForm";
export default {
  name: "Settings",
  components: {WaMessageForm},
  data(){
    return{
      waMessageDialog: false
    }
  },
  methods: {
    openWhatsappSettings(){
        this.waMessageDialog = true;
    }
  }
}
</script>

<style scoped>
.info-icon{
  float: right;
  visibility: hidden;
  cursor: pointer;
}
.my-card:hover .info-icon{
  visibility: visible;
}
</style>