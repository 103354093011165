<template>
  <div class="aj-login-bg">
    <div class="aj-container">
      <div class="row">
        <div class="col-sm-12 col-md-6" style="z-index: 999">
          <div class="p-4 q-mt-lg">
            <div class="row">
              <img src="../assets/images/ByteCardLogo.png" alt="ByteCardLogo" style="width: 140px">
             <!-- <img src="../assets/images/dummyLogo.png" alt="dummyLogo">
              <h6 class="text-subtitle1 text-weight-medium text-white ml-2">ByteCard</h6> -->
            </div>
            <p class="text-caption mb-0 text-white">Come with us and let's
              have your own brand on Digital Platform</p>

            <div class="mb-5 mt-5">
              <h3 class="text-h5 text-white" style="font-weight: 600">Login to Your Account</h3>
              <p class="text-caption mb-0 text-white">Login yourself with registered Email ID and
                Password to build your ByteCard</p>
            </div>

            <q-form ref="qForm">
              <div class="row justify-center">
                <div class="col-12">
                  <q-input label="Email*" v-model="form.email" lazy-rules filled dark
                           :rules="[v => !!v || 'Please enter Email', v => Constants.EmailPattern.test(v) || 'Invalid Email']"/>
                  <q-input label="Password*" v-model="form.password" lazy-rules filled dark
                           :type="showPassword?'text':'password'" @keyup.enter="handleSubmit"
                           :rules="[v => !!v || 'Please enter Password', v => v.length >= 8 || 'Password must be at least 8 characters']">
                    <template v-slot:append>
                      <q-icon :name="showPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
                              @click="showPassword = !showPassword"/>
                    </template>
                  </q-input>
                  <div class="text-right">
                    <a class="text-body2" style="text-decoration: none; color: #99ACC1; font-weight: 600; cursor: pointer" @click="forgotPasswordDialog=true">Forgot
                      Password?</a>
                    </div>
                </div>
              </div>
              <div class="text-center q-mt-lg">
                <q-btn label="Login to Your Account" class="full-width text-capitalize q-mb-md" color="white" size="lg" padding="md" style="color: #003065 !important; font-size: 14px" @click="handleSubmit" :loading="formLoading"/>
                <div class="row justify-center q-px-none items-center">
                  <hr class="q-my-md col-5" style="border: 0.5px solid #274D78">
                  <span class="col-1 text-white">Or</span>
                <hr class="q-my-md col-5" style="border: 0.5px solid #274D78">
                </div>

                <div class="row q-col-gutter-md mt-2">
               <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6"> -->
                <div class="col-12">
                  <q-btn id="customBtn" label="Login with Google" icon="mdi-google" class="text-capitalize w-100" text-color="white"
                         @click="socialLogin('google')"  outline color="red" size="lg" padding="md" style="font-size: 14px;"/>
                </div>
                 <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <q-btn label="Login with facebook" icon="mdi-facebook" class="text-capitalize w-100" text-color="white"
                          @click="socialLogin('facebook')" outline color="red" size="lg" padding="md" style="font-size: 14px;"/>
                  </div> -->
                </div>
                <q-btn class="full-width text-capitalize q-mt-xl q-mb-xl" style="background-color: green; color:white; font-size: 14px" size="lg" padding="md" @click="redirectToSignup">
                  Don't have your account? Create an Account</q-btn>
              </div>
            </q-form>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 login-hand-container">
          <img src="../assets/images/loginHand.png" alt="loginHand" class="login-img">
        </div>
      </div>
    </div>
    <q-dialog persistent full-height position="right" square maximized v-model="forgotPasswordDialog">
      <Forgot @closeForgotPasswordDialog="forgotPasswordDialog=false"/>
    </q-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import Constants from "../constants/Constants";
import {reactive} from 'vue';
import Forgot from '../components/Login/Forgot';
import {wsLinkA} from "../vue-apollo";
export default {
  name: "Login",
  components: {Forgot},
  setup() {
    const form = reactive({email: '', password: '', product_id: Constants.ByteCardProductId});
    return {form};
  },
  data() {
    return {
      Constants,
      formLoading: false,
      showPassword: false,
      forgotPasswordDialog: false,
      social: 'AJ',
      socialRequest: {
        type: 0,
        id: '',
        name: '',
        email: ''
      },
      loading: false
    }
  },
  mounted() {
    gapi.load('auth2', () => {
      let auth2 = gapi.auth2.init({
        client_id: Constants.g_client_id,
        cookiepolicy: 'single_host_origin'
      });
      auth2.attachClickHandler(document.getElementById('customBtn'), {},
              (user) => this.onSignIn(user), (error) => this.onSignError(error)
      );
    });
  },
  methods: {
    handleSdkInit({scope, FB }) {
      this.fbScope = scope;
      this.FB = FB;
    },
    onSignIn (user) {
      const profile = user.getBasicProfile();
      let userAuthResponse = user.getAuthResponse();
      // console.log('profile', profile, 'userAuth', userAuthResponse)
      let request = {
        name: profile.getName(),
        email: profile.getEmail(),
        googleId: profile.getId(),
        token: userAuthResponse.id_token
      };
      this.login('google/login', request);
    },
    onSignError(e){
      console.log('signIn err', e);
    },
    signOut() {
      let auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function () {
        console.log('User signed out.');
      });
    },
    redirectToSignup() {
      this.$router.push('/sign-up');
      window.scrollTo(0,0);
    },
    handleSubmit() {
      this.$refs['qForm'].validate().then(success => {
        if (success) this.login();
      })
    },
    login(path, request) {
      this.formLoading = true;
      axios({method: 'POST', url: Constants.api_url + (path||'login'), data: (request||this.form)}).then(response => {
        if (response.data.status) {
          localStorage.setItem('token', response.data.data.token);
          let user = response.data.data;
          localStorage.setItem('user', JSON.stringify(user));
          this.$emit('setUser', user);
          this.$q.notify('Logged in successfully');
          /*TODO: Close socket connection & reconnect will ensure a new connection with new token */
          wsLinkA.subscriptionClient.close();
          console.log('isCompleted', user.is_completed);
          if(user.is_completed) {
            localStorage.setItem('isBusinessDetailCompleted', 'true');
            this.$router.push('/dashboard');
          } else this.$router.push('/profile');
        } else {
          this.$q.notify(response.data.message);
        }
        this.formLoading = false;
      }).catch(e => console.log('login error', e))
    },
    socialLogin(provider) {
      this.social = provider;
      if (provider === 'facebook') {
        FB.login((loginResponse) => {
          if (loginResponse.authResponse) {
            FB.api(`/me?fields=id,name,email`, (apiResponse) => {
              let request = {
                name: apiResponse.name,
                email: apiResponse.email,
                facebookId: apiResponse.id,
                token: loginResponse.authResponse.accessToken
              };
              this.login('facebook/login', request);
            });
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        }, {scope: 'email', return_scopes:true});
      }
      else if (provider === 'google') console.log('NA')
    },
    onSignInSuccess(user) {
      console.log('user', user );
      if (this.social === 'facebook') {
        // let u = user.getBasicProfile();
        // this.socialRequest.type = Constants.auth.FACEBOOK;
        // this.socialRequest.id = u.Eea;
        // this.socialRequest.email = u.U3;
        // this.socialRequest.name = u.ig;
        // this.signIn();
      } else if (this.social === 'google') {
        let u = user.getBasicProfile();
        this.socialRequest.type = Constants.auth.GOOGLE;
        this.socialRequest.id = u.SU ? u.SU : u.Eea;
        this.socialRequest.email = u.zu ? u.zu : u.U3;
        this.socialRequest.name = u.Ad ? u.Ad : u.ig;
        // this.signIn();
      }
    },
    onSignInError() {
      this.loading = false;
      alert('SIGN IN ERROR');
    },
    signIn() {
      if (this.social === 'facebook' || this.social === 'google') {
        axios.post(`${Constants.api_url}login`, this.socialRequest).then(response => {
          if (response.data.status) {
            localStorage.setItem('user', JSON.stringify(response.data.data))
            localStorage.setItem('token', response.data.data.token)
            this.$q.notify('Logged in successfully');
            this.$router.push('/dashboard');
          } else {
            this.$q.notify(response.data.message);
          }
          this.loading = false;
          this.social = 'AJ';
        }).catch(error => {
          this.social = 'AJ';
          this.loading = false;
          console.log(error)
        })
      }
    },
  },
}
</script>

<style scoped>

/deep/.q-btn--outline:before{
  border-color: #274E78;
}
#google-signin-button{
  /*display: none;*/
}

.aj-login-bg {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #003065;
}
.login-img{
  position: fixed;
  right: 0px;
  top: 5%;
}
@media only screen and (max-width: 1024px) {
  .login-hand-container{
    display: none;
  }
  .aj-login-bg {
    min-height: 100vh;
    height: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .aj-container{
    min-width: 1140px;
  }
}
</style>
