import {ref} from "vue";
import {useQuery} from "@vue/apollo-composable";
import {SEARCH_STATES} from "../graphql/queries/territories";

export default function useSearchState() {
    const stateFilter = ref('');
    const stateArr = ref([]);
    let updateFn = () => {};

    const {onResult, loading:stateLoading} = useQuery(SEARCH_STATES, () => ({stateFilter: `%${stateFilter.value}%`}),
        () => ({enabled: stateFilter.value, clientId:'clientB'}));
    onResult(({data}) => {
        stateArr.value = data.states;
        updateFn();
    });

    function searchState(val, update, abort) {
        if (val.length < 1) return abort();
        stateFilter.value = val;
        updateFn = update;
        setTimeout(() => {if(!stateLoading.value) update(() => {})}, 500);      /* Not efficient */
    }

    return {stateArr, searchState, stateLoading}
}