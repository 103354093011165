<template>
  <div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
      <q-card>
        <q-card-section class="aj-container row justify-between items-center">
          <div>
            <div class="text-h5 text-weight-medium">Select Color</div>
          </div>
          <div>
            <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog">
              <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
            </q-btn>
          </div>
        </q-card-section>

        <q-card-section class="q-mt-lg aj-container">

          <div class="row q-col-gutter-lg q-mt-sm justify-center">
            <div class="col-6" style="max-height: 35vh">
              <img v-if="themeObj.code && selectedColor.code"
                   :src="themeImage(themeObj.code, selectedColor.code, 'desktop', 'front')"
                   class="full-height float-right" style="width:100%" alt="businessCard"
                   :style="themeObj.code === 'dc_theme_02'?'':'border: 1px solid lightgrey;'">
            </div>
            <div class="col-6" style="max-height: 35vh">
              <img v-if="themeObj.code && selectedColor.code"
                   :src="themeImage(themeObj.code, selectedColor.code, 'desktop', 'back')"
                   class="full-height float-left" style="width:100%" alt="businessCard"
                   :style="themeObj.code === 'dc_theme_02'?'':'border: 1px solid lightgrey;'">
            </div>
            <div class="custom-chip q-pa-sm row items-center q-mt-lg ">
              <div class="theme-color-indicator q-mr-sm" :style="'background:'+selectedColor.hex_code"></div>
              <div class="text-weight-medium text-body1">{{ selectedColor.name }}</div>
            </div>
          </div>

          <div class="col-12">
            <q-carousel height="220px" v-if="themeObj.color_themes.length" v-model="slide"
                        transition-prev="slide-right" transition-next="slide-left"
                        animated control-color="primary" navigation padding arrows>
              <template v-for="(s, sIndex) in slides">
                <q-carousel-slide :name="sIndex" class="column no-wrap">
                  <div class="row justify-center items-center q-gutter-xs q-col-gutter-lg no-wrap">
                    <div class="col-6 col-sm-6 col-md-2 color-box" v-for="(c, cIndex) in s" @click="onSelectColor(c)">
                      <div class="color-box" :class="selectedColor.id === c.id?'active-box':''"
                           :style="'background:'+c.hex_code" style="width: 100%; height:100px"></div>
                      <p class="text-center q-mb-none text-body2 text-grey-8 q-mt-sm"
                         :class="selectedColor.id === c.id?'text-primary text-center text-body2 q-mb-none q-mt-sm':''">
                        {{ c.name }}</p>
                    </div>
                  </div>
                </q-carousel-slide>
              </template>
            </q-carousel>
          </div>
          <!--                <div class="bg-white text-right q-pb-md aj-container">-->
          <!--                    <q-btn label="Cancel" size="md" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" padding="sm md"-->
          <!--                           unelevated @click="closeDialog"/>-->
          <!--                    <q-btn label="Save Changes" size="md" color="primary" class="text-capitalize" padding="sm md" unelevated-->
          <!--                           @click="handleSubmit" :loading="formLoading"/>-->
          <!--                </div>-->

          <q-footer class="bg-white text-right q-pb-md aj-container">
            <q-btn label="Cancel" size="md" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" padding="sm md"
                   unelevated @click="closeDialog"/>
            <q-btn label="Save Changes" size="md" color="primary" class="text-capitalize" padding="sm md" unelevated
                   @click="handleSubmit" :loading="formLoading"/>
          </q-footer>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref} from 'vue';
import {useQuery, useMutation} from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {CREATE_UPDATE_DC_CMS} from "../../graphql/mutations/dc";
import {GET_THEME_BY_CUSTOMER_ID} from "../../graphql/queries/dc";
import {GET_THEME_BY_ID} from "../../graphql/queries/themes";

let vForm = {themeId: Constants.DefaultThemeId, colorId: '', themeCode:Constants.DefaultThemeCode, colorCode:'', hexCode:''};
export default {
  name: "ThemeColorForm",
  props: ['dialog'],
  emits: ['update:dialog'],
  setup(props) {
    const customerId = ref(JSON.parse(localStorage.user).id);

    const themeObj = reactive({name: '', code: '', color_themes: []}), slides = ref([]);
    const selectedColor = reactive({});

    const form = reactive({...vForm});
    const {onResult} = useQuery(GET_THEME_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
        () => ({enabled: !!customerId.value && props.dialog, fetchPolicy: 'network-only'}));
    onResult(r => {
      if (r.data.dc_cms[0]?.theme) Object.copyExistingKeys(form, r.data.dc_cms[0].theme);
      enableQuery();
    });

    const enabled = ref(false);

    function enableQuery() {
      enabled.value = true
    }

    const {onResult: themeResult} = useQuery(GET_THEME_BY_ID, () => ({id: form.themeId}),
        () => ({enabled: props.dialog && enabled.value, fetchPolicy: 'network-only'}));
    themeResult(r => {
      enabled.value = false;
      Object.copyExistingKeys(themeObj, r.data.dc_card_themes[0]);
      if (form.colorId) Object.assign(selectedColor, themeObj.color_themes.filter(c => c.id === form.colorId)[0]);
      else Object.assign(selectedColor, themeObj.color_themes.filter(c => c.is_default)[0]);
      slides.value = [];
      for (let i = 0; i < themeObj.color_themes.length; i += 6)
        slides.value.push(themeObj.color_themes.slice(i, i + 6));
    });

    const {mutate: mDcCms} = useMutation(CREATE_UPDATE_DC_CMS);
    return {customerId, form, mDcCms, themeObj, slides, selectedColor};
  },
  data() {
    return {
      Constants, moment,
      formLoading: false,
      slide: 0
    }
  },
  methods: {
    onSelectColor(colorObj) {
      Object.assign(this.selectedColor, colorObj);
      this.form.colorId = colorObj.id;
      this.form.colorCode = colorObj.code;
      this.form.hexCode = colorObj.hex_code;
    },
    handleSubmit() {
      this.saveThemeColor();
    },
    async saveThemeColor() {
      this.formLoading = true;
      await mutation({
        mutate: this.mDcCms,
        variables: {
          dc_cms: {
            customer_id: this.customerId, theme: {...this.form},
            created_by: this.customerId, updated_by: this.customerId
          },
          updateColumns: ['theme', 'updated_by'],
        }
      });
      this.formLoading = false;
      this.$q.notify('Theme Color saved successfully');
      this.closeDialog();
    },
    resetForm() {
      Object.copyExistingKeys(this.form, {...vForm});
    },
    closeDialog() {
      this.resetForm();
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>
.color-box {
  cursor: pointer;
}

.text-primary {
  color: #00ABEC !important;
}

.active-box {
  border: 3px solid #00ABEC;
  height: 120px !important;
}
</style>
