/**
 * mutation
 * @composable
 *
 *Used for Promise-based mutation call
 */

export default async function mutation({mutate, variables}) {
    await new Promise(resolve => mutate(variables,  {update: () => {
            resolve();
        }}));
}