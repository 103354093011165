<template>
  <div>

    <q-dialog v-model="dialog" persistent>
      <q-card class="q-pa-lg">
        <q-card-section v-if="loading">
          <div class="row q-col-gutter-sm">
            <div class="col-12 text-center">
              <div class="save-text">Publishing in progress</div>
              <q-spinner color="primary" size="3em" :thickness="2"/>
            </div>
          </div>
        </q-card-section>
        <q-card-section v-else>
          <div class="row q-col-gutter-sm">
            <div class="col-12 text-center">
              <div class="dot">
                <q-icon name="mdi-check" class="check-icon"></q-icon>
              </div>
              <div class="save-text">ByteCard Published Successfully</div>
            </div>
            <div class="col-12">
              <q-input filled dense readonly :model-value="url">
                <template v-slot:append>
                  <q-btn fab-mini icon="mdi-content-copy" class="text-blue" title="Copy URL" @click="copyUrl"></q-btn>
                </template>
              </q-input>
            </div>
            <div class="col-12 desc-text">Your ByteCard is ready to share with your customers</div>
            <div class="col-12 text-center">
              <q-btn label="Close" @click="closeDialog" outline/>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import Constants from "../../constants/Constants";

export default {
  name: "PublishCard",
  props: ['dialog', 'loading'],
  emits: ['update:dialog'],
  data() {
    return {
      subDomain: localStorage.subDomain
    }
  },
  computed: {
    url() {
      return `https://${this.subDomain}.${Constants.publish_app_domain}`
    }
  },
  methods: {
    setSubDomain(domain) {
      this.subDomain = domain;
    },
    copyUrl() {
      navigator.clipboard.writeText(this.url);
      this.$q.notify('URL copied to Clipboard');
    },
    closeDialog() {
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>
.desc-text {
  color: grey;
  margin-bottom: 20px;
}

.dot {
  border-radius: 50%;
  background: #cce3ff;
  height: 120px;
  width: 120px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.save-text {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
}

.check-icon {
  font-size: 50px;
  color: #0e77ff;
}
</style>