<template>
<div>

  <q-dialog v-model="dialog" persistent>
    <q-card style="width: 400px">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Share ByteCard</div><q-space/>
        <q-btn icon="close" round dense text-color="red-6" color="red-1" @click="closeDialog"/>
      </q-card-section>

      <q-card-section>
        <q-list dense padding>
          <q-item clickable v-ripple @click="shareCard('whatsapp')">
            <q-item-section avatar>
              <q-avatar><img src="../../assets/images/whatsapp48x48.png" alt="whatsapp"></q-avatar>
            </q-item-section>
            <q-item-section>Share on WhatsApp</q-item-section>
          </q-item>
          <q-item clickable v-ripple @click="shareCard('twitter')">
            <q-item-section avatar>
              <q-avatar><img src="../../assets/images/twitter48x48.png" alt="twitter"></q-avatar>
            </q-item-section>
            <q-item-section>Share on Twitter</q-item-section>
          </q-item>
          <q-item clickable v-ripple @click="shareCard('facebook')">
            <q-item-section avatar>
              <q-avatar><img src="../../assets/images/facebook48x48.png" alt="facebook"></q-avatar>
            </q-item-section>
            <q-item-section>Share on Facebook</q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>

</div>
</template>

<script>
import Constants from "../../constants/Constants";
export default {
  name: "ShareDialog",
  props: ['dialog'],
  emits: ['update:dialog'],
  data(){
    return{
      user: JSON.parse(localStorage.user)
    }
  },
  methods:{
    shareCard(media) {
      let subDomain = localStorage.subDomain;
      let url = `https://${subDomain}.${Constants.publish_app_domain}`;
      /* frontImage = this.cartData.frontImage ? imgWpx(this.cartData.frontImage) : null,
       backImage = this.cartData.backImage ? imgWpx(this.cartData.backImage) : null; */
      let message = `Please check the ${this.user.name}'s bytecard: ${url}`;
      if (media === 'whatsapp') {
        window.open(`whatsapp://send?text=${message}`)
      } else if (media === 'facebook') {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&title=Please check ${this.user.name}`);
      } else if (media === 'twitter') {
        window.open(`http://twitter.com/share?text=Please check ${this.user.name}&url=${url}`);
      }
    },
    closeDialog(){
      this.$emit('update:dialog', false)
    }
  }
}
</script>

<style scoped>

</style>