<template>
<div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
        <q-card>
            <q-card-section class="row">
                <div class="col-12 col-sm-12 col-md-8 offset-0 offset-sm-0 offset-md-2 q-px-lg">
                    <div class="row justify-between items-center">
                        <div>
                            <div class="text-h5 text-weight-medium">Useful Links</div>
                        </div>
                        <div>
                            <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog">
                                <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                            </q-btn>
                        </div>
                    </div>
                </div>
            </q-card-section>
            <q-card-section class="aj-container" style="margin-bottom: 100px;">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-8 offset-0 offset-sm-0 offset-md-2 q-mt-md">
                        <q-form ref="qForm">
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/globe.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.websiteLink" class="col" label="Website Link"/>
                        </div>
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/playstore.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.androidLink" class="col" label="Link"/>
                        </div>

                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/app-store.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.iosLink" class="col" label="Link"/>
                        </div>
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/instagram.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.instagramLink" class="col" label="Link"/>
                        </div>
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/twitter.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.twitterLink" class="col" label="Link"/>
                        </div>
                        <div class="row items-center q-mb-xs">
                            <img src="../../assets/images/whatsapp.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.whatsappNumber" class="col" label="Number"
                                     mask="##########" :rules="[v => !v || v.length === 10 || 'Invalid WhatsApp Number']"/>
                        </div>
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/facebook.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.facebookLink" class="col" label="Link"/>
                        </div>
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/linkedin.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.linkedinLink" class="col" label="Link"/>
                        </div>
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/youtube.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.youtubeLink" class="col" label="Link"/>
                        </div>
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/pinterest.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.pinterestLink" class="col" label="Link"/>
                        </div>
                        <div class="row items-center q-mb-lg">
                            <img src="../../assets/images/blog.svg" class="aj-linkIcons q-mr-lg" alt="fb">
                            <q-input square outlined v-model="form.blogLink" class="col" label="Link"/>
                        </div>
                        </q-form>
                    </div>
                </div>
            </q-card-section>
            <q-footer class="bg-white row text-right q-pt-lg q-pb-md">
                <div class="col-md-8 col-sm-12 offset-md-2 q-px-xl">
                    <q-btn label="Cancel" size="md" padding="sm md" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize"
                           @click="closeDialog"/>
                    <q-btn label="Save Changes" color="primary" size="md" padding="sm md" class="text-capitalize" unelevated
                           @click="handleSubmit" :loading="formLoading"/>
                </div>
            </q-footer>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref} from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {CREATE_UPDATE_DC_CMS} from "../../graphql/mutations/dc";
import {GET_SOCIAL_MEDIA_BY_CUSTOMER_ID} from "../../graphql/queries/dc";
let vForm = { websiteLink:'', androidLink:'', iosLink:'', instagramLink:'', twitterLink:'', whatsappNumber:'',
              facebookLink:'', linkedinLink:'', youtubeLink:'', pinterestLink:'', blogLink:'' };
export default {
    name: "SocialMediaForm",
    props: ['dialog'],
    emits: ['update:dialog'],
    setup (props) {
        const customerId = ref(JSON.parse(localStorage.user).id);

        const form = reactive({...vForm});
        const {onResult} = useQuery(GET_SOCIAL_MEDIA_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog, fetchPolicy:'network-only'}));
        onResult(r => {
            if(r.data.dc_cms[0]?.social_media) Object.copyExistingKeys(form, r.data.dc_cms[0].social_media);
        });

        const { mutate: mDcCms } = useMutation(CREATE_UPDATE_DC_CMS);
        return { customerId, form, mDcCms};
    },
    data(){
        return{
            Constants, moment,
            formLoading: false,
        }
    },
    methods:{
        handleSubmit(){
            this.$refs['qForm'].validate().then(success => {
                if(success) this.saveSocialMedia();
            })
        },
        async saveSocialMedia(){
            this.formLoading = true;
            await mutation({
                mutate: this.mDcCms,
                variables:{
                    dc_cms: {
                        customer_id: this.customerId, social_media: this.form,
                        created_by: this.customerId, updated_by: this.customerId
                    },
                    updateColumns: ['social_media', 'updated_by'],
                }
            });
            this.formLoading = false;
            this.$q.notify('Useful Links saved successfully');
            this.closeDialog();
        },
        resetForm(){
            Object.copyExistingKeys(this.form, {...vForm});
        },
        closeDialog(){
            this.$refs['qForm'].resetValidation();
            this.resetForm();
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>

</style>