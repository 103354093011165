import {ref} from "vue";
import {useQuery} from "@vue/apollo-composable";
import {SEARCH_CITIES} from "../graphql/queries/territories";

export default function useSearchCity() {
    const cityFilter = ref('');
    const cityArr = ref([]);
    let updateFn = () => {};

    const {onResult, loading:cityLoading} = useQuery(SEARCH_CITIES, () => ({cityFilter: `%${cityFilter.value}%`}),
        () => ({enabled: cityFilter.value, clientId:'clientB'}));
    onResult(({data}) => {
        cityArr.value = data.cities;
        updateFn();
    });

    function searchCity(val, update, abort) {
        if (val.length < 1) return abort();
        cityFilter.value = val;
        updateFn = update;
        setTimeout(() => {if(!cityLoading.value) update(() => {})}, 500);      /* Not efficient */
    }

    return {cityArr, searchCity, cityLoading}
}