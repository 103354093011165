<template>
  <div>
    <q-card style="height: 100%; width: 400px" square class="rounded-0">
      <div>
        <q-btn fab flat icon="close" @click="closeDialog">
          <q-tooltip>Close</q-tooltip>
        </q-btn>

        <template v-if="flag1">
          <div class="q-pa-lg">
            <h6 style="font-weight: 600">Reset Password</h6>
            <p class="text-body2">Enter your Email address</p>
            <div class="q-mt-xl">
              <q-input class="text-body2" outlined square label="Enter Email" v-model="email" ref="email"
                       :rules="[v => !!v || 'Please enter Email', v => Constants.EmailPattern.test(v) || 'Invalid Email']"/>

              <q-btn class="full-width text-capitalize q-mt-sm" @click="requestOtp(true)"
                     style="background-color: #00234A; color:white; font-size: 14px; border-radius: 0px" size="lg"
                     padding="md">
                <q-spinner color="white" size="1em" class="q-mr-md" :thickness="2" v-if="loading"></q-spinner>
                Request OTP
              </q-btn>

            </div>
          </div>
        </template>

        <template v-if="flag2">
          <div class="q-pa-lg">
            <h6 style="font-weight: 600">Verify OTP</h6>
            <p class="text-body2">Enter the verification code we just sent on your email {{ email }}.</p>
            <div class="q-mt-xl">
              <q-input class="mb-3" outlined square label="Enter OTP" mask="######" v-model="otp"></q-input>

              <q-btn class="full-width text-capitalize q-mt-sm q-mb-md" @click="verifyOtp"
                     :disabled="otp.length!==6||verifyOtpDisabled"
                     style="background-color: #00234A; color:white; font-size: 14px; border-radius: 0px" size="lg"
                     padding="md">
                <q-spinner color="white" size="1em" :thickness="2" class="q-mr-sm" v-if="loading2"></q-spinner>
                Verify OTP
              </q-btn>

              <q-btn class="full-width text-capitalize" @click="requestOtp(false)"
                     style="background-color: #EEEEEE; color:black; font-size: 14px; border-radius: 0px" size="lg"
                     padding="md">
                Resend OTP
              </q-btn>
              <!--              -->
              <!--              <q-btn class="pd-btn my-3" @click="verifyOtp" size="12px" :disabled="otp.length!==6||verifyOtpDisabled">-->
              <!--                Verify OTP-->
              <!--              </q-btn>-->


              <!--              <q-btn class="btn-resend ml-2 my-3" @click="requestOtp(false)" size="12px">Resend OTP</q-btn>-->

            </div>
          </div>
        </template>

        <template v-if="flag3">
          <div class="q-pa-lg">
            <h6 style="font-weight: 600">Set Password</h6>
            <p class="text-body2">Enter the Password you want to set </p>
            <div class="q-mt-xl">
              <q-input class="mb-3" outlined square label="New Password" v-model="newPassword"
                       :type="showNewPassword?'text':'password'">
                <template v-slot:append>
                  <q-icon :name="showNewPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
                          @click="showNewPassword = !showNewPassword"/>
                </template>
              </q-input>
              <q-input class="mb-3" outlined square label="Confirm Password" v-model="confirmPassword"
                       :type="showConfirmPassword?'text':'password'">
                <template v-slot:append>
                  <q-icon :name="showConfirmPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
                          @click="showConfirmPassword = !showConfirmPassword"/>
                </template>
              </q-input>

              <q-btn class="full-width text-capitalize q-mt-sm" @click="resetPassword"
                     style="background-color: #00234A; color:white; font-size: 14px; border-radius: 0px" size="lg"
                     padding="md">
                <q-spinner color="white" size="1em" class="q-mr-md" :thickness="2" v-if="loading3"></q-spinner>
                Reset
              </q-btn>

            </div>
          </div>
        </template>

        <template v-if="flag4">
          <div class="q-pa-lg">
            <h6 style="font-weight: 600">Your Password has been reset successfully !</h6>
          </div>
        </template>


      </div>
    </q-card>
  </div>
</template>

<script>
import Constants from '../../constants/Constants'
import {NetworkCommunicator} from '../../plugins/NetworkResourceHandler'
import axios from 'axios'

export default {
  name: 'Forgot',
  data: function () {
    return {
      Constants,
      flag1: true,
      flag2: false,
      flag3: false,
      flag4: false,
      email: '',
      loading: false,
      loading2: false,
      loading3: false,
      otp: '',
      uniqueId: '',
      token: '',
      newPassword: '',
      confirmPassword: '',
      showNewPassword: false,
      showConfirmPassword: false,
      verifyOtpDisabled: false,
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeForgotPasswordDialog', false)
    },
    async requestOtp(validate) {
      if (validate) {
        this.$refs['email'].validate();
        if (this.$refs['email'].hasError) return;
      }

      this.loading = true
      let response = await NetworkCommunicator('POST', `${Constants.api_url}sendForgotPasswordOtp`,
          false, {email: this.email}, {})
      this.loading = false
      if (response.status) {
        this.$q.notify(response.message)
        this.flag1 = false
        this.flag2 = true
        this.uniqueId = response.data.id
        this.verifyOtpDisabled = false
      } else {
        this.$q.notify(response.message)
      }
    },
    async verifyOtp() {
      this.loading2 = true
      let response = await NetworkCommunicator('POST', `${Constants.api_url}VerifyForgotPasswordOtp`,
          false, {otp: this.otp, uniqueId: this.uniqueId}, {})
      this.loading2 = false
      if (response.status) {
        this.$q.notify(response.message)
        this.flag2 = false;
        this.flag3 = true;
        this.token = response.data.token;
      } else {
        if (response.code === 41) {
          this.$q.notify(response.message) //Invalid OTP
        } else if (response.code === 202) {
          this.$q.notify(response.message) //Maximum Try
          this.otp = '';
          this.uniqueId = '';
          this.token = '';
          this.verifyOtpDisabled = true
        } else {
          this.$q.notify(response.message)
        }
      }
    },
    async resetPassword() {
      if (!this.newPassword) return this.$q.notify('Please enter new password');
      if (!this.confirmPassword) return this.$q.notify('Please enter confirm password');
      if (this.newPassword !== this.confirmPassword) return this.$q.notify('Password entered not matched with confirm password');

      this.loading3 = true;
      let response = await axios.post(`${Constants.api_url}forgotPassword`, {password: this.newPassword}, {headers: {token: this.token}});
      this.loading3 = false;
      if (response.data.status) {
        this.$q.notify(response.data.message);
        this.flag3 = false;
        this.flag4 = true
      } else {
        this.$q.notify(response.data.message)
      }
    },
  },
}
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}

.pd-btn {
  background: #105992 !important;
  color: #fff !important;
  font-size: 14px !important;
}

.btn-resend {
  color: #105992 !important;
  background: none !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.btn-resend:hover {
  background: none !important;
}
</style>
