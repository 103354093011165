<template>
<div class="q-pa-md">

  <div class="text-h4 text-center">Coming soon...</div>
  <div class="desc mt-4">
    <b>The ByteBiz</b> product is built keeping in mind the small business, who want to
    increase the productivity of their team. This product will also help you to
    increase the Sales Conversion ratio along with Lead management. Some other
    features that will be provided are Invoice creation with Download and Share
    option, Live location tracking and Call recordings. The business owner will also
    be able to configure their system as per their business requirements
  </div>

</div>
</template>

<script>
export default {
  name: "ByteBiz"
}
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
}
.desc{
  font-size: 18px;
}
</style>