<template>
<div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
        <q-card>
            <q-card-section class="aj-container row justify-between items-center">
                <div class="col-10">
                    <div class="text-h5 text-weight-medium">
                        <template v-if="editTitle">
                            <input v-model="tempWidgetTitle" class="inline" id="inline-input"/>
                            <q-btn flat round color="grey-8" icon="mdi-check" size="sm" @click="onClickTitleSave"
                                   :loading="saveTitleLoading" :disable="!tempWidgetTitle.trim()"/>
                            <q-btn flat round color="grey-8" icon="mdi-close" size="sm"  @click="onClickTitleCancel"/>
                        </template>
                        <template v-else>{{widgetTitle}}
                            <q-btn flat round color="grey-8" icon="mdi-pencil-outline" size="sm"  @click="onClickTitleEdit"/>
                        </template>

                    </div>
                </div>
                <div class="col-2 text-right">
                    <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog(true)">
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </div>
            </q-card-section>
            <q-card-section class="q-mt-lg aj-container">
                <div class="row q-col-gutter-md">
                    <div class="col-12">
                        <q-card bordered flat>
                            <q-card-section class="row q-py-none q-pr-none">
                              <template v-if="!responsive || firstPage">
                                <div class="q-py-md" :class="responsive?'col-12':'col'">
                                    <div class="row items-center justify-between q-pr-md q-pb-md">
                                        <div>
                                            <h6 style="font-weight: 600" class="text-subtitle1 text-grey-9">{{testimonials.length}} Testimonial</h6>
                                            <span class="text-body2 text-grey-8">You can add upto 25 Testimonial</span>
                                        </div>
                                        <div class="row">
                                            <q-input class="q-mr-md mt-10-sm" square outlined v-model="search" label="Search" dense @update:model-value="onSearch">
                                                <template v-slot:prepend><q-icon name="mdi-magnify" size="20px"/></template>
                                            </q-input>

                                            <q-btn class="no-shadow mt-10-sm" icon="add" unelevated size="sm" text-color="primary"
                                                   color="blue-1" label="New" @click="onAddTestimonial" v-if="testimonials.length<maxTestimonials"/>

                                        </div>
                                    </div>
                                    <div style="max-height: 67vh; overflow-y: auto">
                                        <q-list>
                                          <draggable @end="drag=false; updateSequence()" @start="drag=true" v-model="testimonials" item-key="id"
                                                       :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }" v-bind="dragOptions">
                                            <template #item="{element:t}">
                                            <q-item v-if="!t.isHidden" clickable class="q-px-none q-mb-sm list-item" @click="onEditTestimonial(t)"
                                                    :active="activeTestimonialId===t.id" active-class="bg-blue-1 text-primary">
                                                <q-btn round icon="drag_indicator" class="d-icon" text-color="grey"/>
                                                <q-item-section avatar top>
                                                    <q-img v-if="t.image_url" height="52px" width="52px" class="rounded-borders" :src="imgWpx(t.image_url, 200)"/>
                                                    <q-avatar v-else square size="52px" text-color="grey" icon="person" class="rounded-borders"/>
                                                </q-item-section>
                                                <q-item-section top>
                                                    <q-item-label lines="1">
                                                        <p class="text-weight-medium q-mb-xs">{{t.person_name}}</p>
                                                    </q-item-label>
                                                  <q-item-label>
                                                        <p class="text-weight-regular text-caption text-grey-8 q-mb-none desc-text ellipsis">{{t.description}}</p>
                                                  </q-item-label>
                                                </q-item-section>
                                                <q-item-section top side>
                                                    <div class="text-grey-8 q-gutter-xs">
                                                        <q-btn class="gt-xs list-delete-icon" size="12px" flat dense round
                                                               icon="mdi-delete-outline" @click.stop="onDeleteTestimonial(t)"/>
                                                        <q-toggle @click.stop="onChangeTestimonialStatus(t)" v-model="t.is_active"
                                                                  class="toggle-icon"/>
                                                    </div>
                                                </q-item-section>
                                            </q-item>
                                            </template>
                                          </draggable>
                                          <div v-if="noSearchResult">No Testimonials found.</div>
                                        </q-list>
                                    </div>
                                </div>
                                <q-separator vertical></q-separator>
                              </template>
                              <template v-if="!responsive || !firstPage">
                                <div v-if="showTestimonialForm" class="q-py-md q-pl-md" :class="responsive?'col-12':'col'">
                                    <h6 style="font-weight: 600" class="text-subtitle1 text-grey-9 q-mb-lg">{{form.id?'Edit Testimonial':'Add New'}}</h6>
                                    <div class="q-pr-md" style="height: 63vh; overflow-y: auto">
                                        <q-form ref="qForm">
                                        <q-input class="q-mb-md" square outlined v-model="form.person_name" counter maxlength="50" label="Person Name*"
                                                 :rules="[v => !!v || 'Please enter Person Name', v => v.length>=3 || 'Enter minimum 3 characters']" lazy-rules/>
                                        <q-input class="q-mb-md" square outlined v-model="form.company_name" counter maxlength="50"
                                                 label="Company Name" :rules="[v => !v || v.length >= 3 || 'Enter minimum 3 characters']"/>
                                        <q-input class="q-mt-lg" outlined square v-model="form.description" counter label="Description*"
                                                 type="textarea" :rules="[v => !!v || 'Please enter Description', v => v.length>=3 || 'Enter minimum 3 characters']" lazy-rules/>
                                        <h6 class="text-subtitle2 text-weight-bold text-grey-9 q-mt-lg ">Profile Picture</h6>
                                        <span class="text-caption text-grey-8">File type required JPG, PNG (Max 1MB)</span>
                                        <div class="row q-col-gutter-md q-mb-sm q-mt-xs">
                                            <div class="col-6" @click="openUploadFile()" style="position: relative">
                                                <div v-if="form.image_url" class="aj-uploader" style="height: 140px">
                                                    <img :src="imgWpx(form.image_url, 400)" class="img-tag">
                                                    <q-btn @click.stop="removeImage()" icon="cancel" text-color="grey" style="position: absolute;left: 6px" title="Remove Image"/>
                                                </div>
                                                <div v-else class="aj-uploader row justify-center items-center" style="height: 140px">
                                                    <q-btn round icon="mdi-cloud-upload-outline" unelevated text-color="primary"
                                                           color="blue-1"/>
                                                </div>
                                            </div>
                                        </div>
                                        </q-form>
                                    </div>
                                    <div class="text-right q-mt-md q-mr-lg">
                                        <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" unelevated size="md"
                                               padding="sm md" @click="closeDialog(!responsive)"/>
                                        <q-btn label="Save" color="primary" class="text-capitalize" unelevated size="md" padding="sm md"
                                               @click="handleSubmit" :loading="formLoading"/>
                                    </div>
                                </div>
                                <div v-else class="q-py-md q-pl-md text-center" :class="responsive?'col-12':'col'" style="height: 80vh;line-height: 80vh">
                                    <div style="line-height: 2;display: inline-block;">
                                        <q-avatar class="bg-blue-grey-1" size="100px" text-color="primary" icon="local_offer" />
                                        <div v-if="testimonials.length">Please select any testimonial to make changes.</div>
                                        <div v-else>No Testimonials added yet. Click on Add New button to add testimonial.</div>
                                    </div>
                                </div>
                              </template>
                            </q-card-section>
                        </q-card>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>

    <UploadFile v-model:dialog="uploadFileDialog" @fileResponse="onReceiveFileResponse" fileTypes=".jpg, .jpeg, .png" :maxSize="maxFileSize"/>
</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref, toRefs} from 'vue';
import { useSubscription, useMutation } from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import UploadFile from "../Upload/UploadFile";
import {CREATE_UPDATE_DC_TESTIMONIAL} from "../../graphql/mutations/dc";
import {SUBSCRIBE_DC_TESTIMONIALS_BY_CUSTOMER_ID} from "../../graphql/subscriptions/organizations";
import {generate_DELETE_RECORD, generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
import updateRecord from "../../composables/updateRecord";
import deleteRecord from "../../composables/deleteRecord";
import draggable from 'vuedraggable';
import useWidget from "../../composables/widgetTitle";
export default {
    name: "TestimonialForm",
    components: {UploadFile, draggable},
    props: ['dialog'],
    emits: ['update:dialog'],
    setup (props) {
        const { dialog } = toRefs(props);
        const customerId = ref(JSON.parse(localStorage.user).id);
        const form = reactive({person_name:'', company_name:null, description:'', image_url:null});

        const testimonials = ref([]);
        const {onResult } = useSubscription(SUBSCRIBE_DC_TESTIMONIALS_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog}));
        onResult(r => testimonials.value = r.data.testimonials);

        const {widgetTitle, tempWidgetTitle, editTitle, saveTitleLoading,
            onClickTitleEdit, onClickTitleSave, onClickTitleCancel} = useWidget('testimonials', dialog, customerId);
        const { mutate: mTestimonial } = useMutation(CREATE_UPDATE_DC_TESTIMONIAL);
        const { mutate: mUpdateTestimonial } = useMutation(generate_UPDATE_RECORD('dc_customer_testimonials'));
        const { mutate: mDeleteTestimonial } = useMutation(generate_DELETE_RECORD('dc_customer_testimonials'));
        return { customerId, form, mTestimonial, testimonials, mUpdateTestimonial, mDeleteTestimonial, widgetTitle,
                 tempWidgetTitle, editTitle, saveTitleLoading, onClickTitleEdit, onClickTitleSave, onClickTitleCancel};
    },
    data(){
        return{
            Constants, moment,
            formLoading: false,
            uploadFileDialog: false,
            search: '',
            noSearchResult: false,
            activeTestimonialId: '',
            drag: false,
            showTestimonialForm: false,
            maxTestimonials: 25,
            maxFileSize: 1048576,        /* 1MB */
            firstPage: true              // For mobile device
        }
    },
    computed: {
        dragOptions() {
            return { animation: 200, group: "description", disabled: false, ghostClass: "ghost"};
        }
    },
    methods:{
        onSearch(val){
            val = val?.toLowerCase() || '';
            this.testimonials.forEach(t => t.isHidden = t.person_name.toLowerCase().indexOf(val) === -1);
            this.noSearchResult = !this.testimonials.find(t => !t.isHidden);
        },
        async updateSequence(){
            this.testimonials.forEach((t, index) => { t.sequence = index+1; t.customer_id = this.customerId; delete t.__typename; });
            await mutation({
                mutate: this.mTestimonial,
                variables:{
                    testimonial: this.testimonials,
                    updateColumns: ['sequence'],
                }
            });
            this.$q.notify('Testimonials sequence updated successfully');
        },
        onAddTestimonial(){
            this.resetForm();
            this.showTestimonialForm = true;
            this.firstPage = false;
        },
        onEditTestimonial(row){
            this.activeTestimonialId = row.id;
            this.form.id = '';
            Object.copyExistingKeys(this.form, JSON.parse(JSON.stringify(row)));
            this.showTestimonialForm = true;
            this.firstPage = false;
        },
        removeImage(){
            this.form.image_url = null;
        },
        openUploadFile(){
            this.uploadFileDialog = true;
        },
        onReceiveFileResponse(fileResponse){
            this.form.image_url = fileResponse;
        },
        onChangeTestimonialStatus(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to ${row.is_active?'active':'inactive'} testimonial ?`,
            }).onOk(() => this.updateTestimonialStatus(row)).onCancel(() => row.is_active = !row.is_active)
        },
        async updateTestimonialStatus(row){
            await updateRecord(this.mUpdateTestimonial, row.id, {is_active: row.is_active});
            this.$q.notify('Testimonial Status updated successfully');
        },
        onDeleteTestimonial(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to delete testimonial ?`,
            }).onOk(() => this.deleteTestimonial(row))
        },
        async deleteTestimonial(row){
            await deleteRecord(this.mDeleteTestimonial, row.id);
            this.$q.notify('Testimonial deleted successfully');
            let index = this.testimonials.findIndex(t => t.id === row.id);
            if(index > -1) this.testimonials.splice(index, 1);
            this.updateSequence();
        },
        handleSubmit(){
            if(this.testimonials.length >= this.maxTestimonials && !this.form.id)
                return this.$q.notify(`Cannot add more than ${this.maxTestimonials} testimonials`);
            this.$refs['qForm'].validate().then(success => {
                if(success) this.saveTestimonial();
            })
        },
        async saveTestimonial(){
            this.formLoading = true;
            await mutation({
                mutate: this.mTestimonial,
                variables:{
                    testimonial: {
                        ...this.form, customer_id: this.customerId, sequence:this.testimonials.length + 1
                    },
                    updateColumns: [...Object.keys(this.form)],
                }
            });
            this.formLoading = false;
            this.$q.notify('Testimonial saved successfully');
            // this.closeDialog();
            this.resetForm();
        },
        resetForm(){
            this.form.person_name = '';
            this.form.company_name = null;
            this.form.description = '';
            this.form.image_url = null;
            delete this.form.id;
            this.$refs['qForm']?.resetValidation();
            this.activeTestimonialId = '';
            this.editTitle = false;
            this.firstPage = true;
        },
        closeDialog(close){
            this.resetForm();
            this.showTestimonialForm = false;
            if(close) this.$emit('update:dialog', false);
        }
    }
}
</script>

<style lang="scss">
.img-tag{
    width: 100%;
    height: 100%;
    object-fit: contain; /* cover */
}
.list-delete-icon {
    visibility: hidden;
}
.list-item:hover {
    .list-delete-icon {
        visibility: visible;
        color: red;
    }
    .d-icon {
        visibility: visible;
    }
}
@media only screen and (max-width: 600px) {
  .d-icon{
    visibility: visible;
  }
  .toggle-icon{
    margin-top: -8px;
  }
  .mt-10-sm{
    margin-top: 10px;
  }
  #inline-input{
    width: 66%;
  }
}
@media only screen and (min-width: 600px) {
  .d-icon{
    visibility: hidden;
  }
}
.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /*cursor: default;*/
}
.desc-text {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 250px;
}
.rounded-borders{
    border: 1px solid grey;
    border-radius: 5px !important;
}
</style>
