/**
 * checkDomain
 * @composable
 *
 * Used to check domain exists already
 */
import axios from 'axios';
import Constants from "../constants/Constants";


export default function checkDomain(domain) {
    return axios.post(`${Constants.api_url}checkDomain`, {domain}).then(response => {
      return response.data.status;
    }).catch(e => console.log('checkDomain API fail!', e))
}