import gql from "graphql-tag";

export const GET_THEME_BY_ID = gql`
query getThemeById($id: uuid!){
  dc_card_themes(where: { id: {_eq: $id}}){
    id
    name    
    code
    color_themes 
  }
}`

export const GET_THEMES = gql`
query getThemes{
  dc_card_themes(where: { is_enabled: {_eq: true}}, order_by: {sequence: asc}){
    id
    name    
    code
    color_themes
    is_default 
  }
}`