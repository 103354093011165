<template>
<div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
        <q-card>
            <q-card-section class="aj-container row justify-between items-center">
                <div class="col-10">
                    <div class="text-h5 text-weight-medium">
                        <template v-if="editTitle">
                            <input v-model="tempWidgetTitle" class="inline" id="inline-input"/>
                            <q-btn flat round color="grey-8" icon="mdi-check" size="sm" @click="onClickTitleSave"
                                   :loading="saveTitleLoading" :disable="!tempWidgetTitle.trim()"/>
                            <q-btn flat round color="grey-8" icon="mdi-close" size="sm"  @click="onClickTitleCancel"/>
                        </template>
                        <template v-else>{{widgetTitle}}
                            <q-btn flat round color="grey-8" icon="mdi-pencil-outline" size="sm"  @click="onClickTitleEdit"/>
                        </template>

                    </div>
                </div>
                <div class="col-2 text-right">
                    <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog(true)">
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </div>
            </q-card-section>
            <q-card-section class="q-mt-lg aj-container">
                <div class="row q-col-gutter-md">
                    <div class="col-12">
                        <q-card bordered flat>
                            <q-card-section class="row q-py-none q-pr-none">
                              <template v-if="!responsive || firstPage">
                                <div class="q-py-md" :class="responsive?'col-12':'col'">
                                    <div class="row items-center justify-between q-pr-md q-pb-md">
                                        <div>
                                            <h6 style="font-weight: 600" class="text-subtitle1 text-grey-9">{{certificates.length}} Certificates</h6>
                                        </div>
                                        <div class="row">
                                            <q-input class="q-mr-md mt-10-sm" square outlined v-model="search" label="Search" dense @update:model-value="onSearch">
                                                <template v-slot:prepend><q-icon name="mdi-magnify" size="20px"/></template>
                                            </q-input>

                                            <q-btn class="no-shadow mt-10-sm" icon="add" unelevated size="sm" text-color="primary"
                                                   color="blue-1" label="New" @click="onAddCertificate" v-if="certificates.length<maxCertificates"/>

                                        </div>
                                    </div>
                                    <div style="max-height: 67vh; overflow-y: auto">
                                        <q-list>
                                          <draggable @end="drag=false; updateSequence()" @start="drag=true" v-model="certificates" item-key="id"
                                                       :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }" v-bind="dragOptions">
                                            <template #item="{element:c}">
                                                <q-item v-if="!c.isHidden" clickable class="q-px-none q-mb-sm list-item" @click="onEditCertificate(c)"
                                                        :active="activeCertificateId===c.id" active-class="bg-blue-1 text-primary">
                                                <q-btn round icon="drag_indicator" class="drag-icon" text-color="grey"/>
                                                <q-item-section avatar>
                                                    <q-img height="52px" width="52px" class="rounded-borders" :src="imgWpx(c.imageUrl, 200)"/>
                                                </q-item-section>
                                                <q-item-section class="text-body1 text-weight-medium">{{c.name}}</q-item-section>
                                                <q-item-section side>
                                                    <div class="text-grey-8 q-gutter-xs">
                                                        <q-btn class="gt-xs list-delete-icon" size="12px" flat dense round
                                                               icon="mdi-delete-outline" @click.stop="onDeleteCertificate(c)"/>
                                                        <q-toggle @click.stop="onChangeCertificateStatus(c)" v-model="c.is_active"/>
                                                    </div>
                                                </q-item-section>
                                            </q-item>
                                            </template>
                                          </draggable>
                                          <div v-if="noSearchResult">No Certificates found.</div>
                                        </q-list>
                                    </div>
                                </div>
                                <q-separator vertical></q-separator>
                              </template>
                              <template v-if="!responsive || !firstPage">
                                <div v-if="showCertificateForm" class="q-py-md q-pl-md" :class="responsive?'col-12':'col'">
                                    <h6 style="font-weight: 600" class="text-subtitle1 text-grey-9 q-mb-lg">{{form.id?'Edit Certificate':'Add New'}}</h6>
                                    <div class="q-pr-md" style="height: 63vh; overflow-y: auto">
                                        <q-form ref="qForm">
                                        <q-input class="q-mb-md" square outlined v-model="form.name" label="Title*" maxlength="50" counter
                                                 :rules="[v => !!v || 'Please enter Title', v => v.length>=2 || 'Enter minimum 2 characters']" lazy-rules/>
                                        <h6 class="text-subtitle2 text-weight-bold text-grey-9 q-mt-lg ">Image*</h6>
                                        <span class="text-caption text-grey-8">File type required JPG, PNG (Max 1MB)</span>
                                        <div class="row q-col-gutter-md q-mb-sm q-mt-xs">
                                            <div class="col-12" @click="openUploadFile()" style="position: relative">
                                                <div v-if="form.imageUrl" class="aj-uploader" style="height: 200px">
                                                    <img :src="imgWpx(form.imageUrl, 400)" class="img-tag">
                                                    <q-btn @click.stop="removeImage()" icon="cancel" text-color="grey" style="position: absolute;left: 6px" title="Remove Image"/>
                                                </div>
                                                <div v-else class="aj-uploader row justify-center items-center" style="height: 200px">
                                                    <q-btn round icon="mdi-cloud-upload-outline" unelevated text-color="primary"
                                                           color="blue-1"/>
                                                </div>
                                            </div>
                                        </div>
                                        </q-form>
                                    </div>
                                    <div class="text-right q-mt-md q-mr-lg">
                                        <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" unelevated size="md"
                                               padding="sm md" @click="closeDialog(!responsive)"/>
                                        <q-btn label="Save" color="primary" class="text-capitalize" unelevated size="md" padding="sm md"
                                               @click="handleSubmit" :loading="formLoading"/>
                                    </div>
                                </div>
                                <div v-else class="q-py-md q-pl-md text-center" :class="responsive?'col-12':'col'" style="height: 80vh;line-height: 80vh">
                                    <div style="line-height: 2;display: inline-block;">
                                        <q-avatar class="bg-blue-grey-1" size="100px" text-color="primary" icon="local_offer" />
                                        <div v-if="certificates.length">Please select any certificate to make changes.</div>
                                        <div v-else>No Certificates added yet. Click on Add New button to add certificate.</div>
                                    </div>
                                </div>
                              </template>
                            </q-card-section>
                        </q-card>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>

    <UploadFile v-model:dialog="uploadFileDialog" @fileResponse="onReceiveFileResponse" fileTypes=".jpg, .jpeg, .png" :maxSize="maxFileSize"/>
</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref, toRefs} from 'vue';
import { useSubscription, useMutation } from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import UploadFile from "../Upload/UploadFile";
import {CREATE_UPDATE_DC_CERTIFICATE} from "../../graphql/mutations/dc";
import {SUBSCRIBE_DC_CERTIFICATES_BY_CUSTOMER_ID} from "../../graphql/subscriptions/organizations";
import {generate_DELETE_RECORD, generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
import updateRecord from "../../composables/updateRecord";
import deleteRecord from "../../composables/deleteRecord";
import draggable from 'vuedraggable';
import useWidget from "../../composables/widgetTitle";
export default {
    name: "CertificateForm",
    components: {UploadFile, draggable},
    props: ['dialog'],
    emits: ['update:dialog'],
    setup (props) {
        const { dialog } = toRefs(props);
        const customerId = ref(JSON.parse(localStorage.user).id);
        const form = reactive({name:'', imageUrl:''});

        const certificates = ref([]);
        const {onResult } = useSubscription(SUBSCRIBE_DC_CERTIFICATES_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog}));
        onResult(r => certificates.value = r.data.certificates);

        const {widgetTitle, tempWidgetTitle, editTitle, saveTitleLoading,
            onClickTitleEdit, onClickTitleSave, onClickTitleCancel} = useWidget('certificates', dialog, customerId);
        const { mutate: mCertificate } = useMutation(CREATE_UPDATE_DC_CERTIFICATE);
        const { mutate: mUpdateCertificate } = useMutation(generate_UPDATE_RECORD('dc_customer_certificates'));
        const { mutate: mDeleteCertificate } = useMutation(generate_DELETE_RECORD('dc_customer_certificates'));
        return { customerId, form, mCertificate, certificates, mUpdateCertificate, mDeleteCertificate, widgetTitle,
                 tempWidgetTitle, editTitle, saveTitleLoading, onClickTitleEdit, onClickTitleSave, onClickTitleCancel};
    },
    data(){
        return{
            Constants, moment,
            formLoading: false,
            uploadFileDialog: false,
            search: '',
            noSearchResult: false,
            activeCertificateId: '',
            drag: false,
            showCertificateForm: false,
            maxCertificates: 25,
            maxFileSize: 1048576,        /* 1MB */
            firstPage: true
        }
    },
    computed: {
        dragOptions() {
            return { animation: 200, group: "description", disabled: false, ghostClass: "ghost"};
        }
    },
    methods:{
        onSearch(val){
            val = val?.toLowerCase() || '';
            this.certificates.forEach(c => c.isHidden = c.name.toLowerCase().indexOf(val) === -1);
            this.noSearchResult = !this.certificates.find(c => !c.isHidden);
        },
        async updateSequence(){
            this.certificates.forEach((c, index) => { c.sequence = index+1; c.customer_id = this.customerId; delete c.__typename; });
            await mutation({
                mutate: this.mCertificate,
                variables:{
                    certificate: this.certificates,
                    updateColumns: ['sequence'],
                }
            });
            this.$q.notify('Certificates sequence updated successfully');
        },
        onAddCertificate(){
            this.resetForm();
            this.showCertificateForm = true;
            this.firstPage = false;
        },
        onEditCertificate(row){
            this.activeCertificateId = row.id;
            this.form.id = '';
            Object.copyExistingKeys(this.form, JSON.parse(JSON.stringify(row)));
            this.showCertificateForm = true;
            this.firstPage = false;
        },
        removeImage(){
            this.form.imageUrl = '';
        },
        openUploadFile(){
            this.uploadFileDialog = true;
        },
        onReceiveFileResponse(fileResponse){
            this.form.imageUrl = fileResponse;
        },
        onChangeCertificateStatus(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to ${row.is_active?'active':'inactive'} certificate ?`,
            }).onOk(() => this.updateCertificateStatus(row)).onCancel(() => row.is_active = !row.is_active)
        },
        async updateCertificateStatus(row){
            await updateRecord(this.mUpdateCertificate, row.id, {is_active: row.is_active});
            this.$q.notify('Certificate Status updated successfully');
        },
        onDeleteCertificate(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to delete certificate ?`,
            }).onOk(() => this.deleteCertificate(row))
        },
        async deleteCertificate(row){
            await deleteRecord(this.mDeleteCertificate, row.id);
            this.$q.notify('Certificate deleted successfully');
            let index = this.certificates.findIndex(c => c.id === row.id);
            if(index > -1) this.certificates.splice(index, 1);
            this.updateSequence();
        },
        handleSubmit(){
            if(this.certificates.length >= this.maxCertificates && !this.form.id)
                return this.$q.notify(`Cannot add more than ${this.maxCertificates} certificates`);
            this.$refs['qForm'].validate().then(success => {
                if(success && !this.form.imageUrl) this.$q.notify(`Please upload image`);
                else if(success && this.form.imageUrl) this.saveCertificate();
            })
        },
        async saveCertificate(){
            this.formLoading = true;
            await mutation({
                mutate: this.mCertificate,
                variables:{
                    certificate: {
                        ...this.form, customer_id: this.customerId, sequence:this.certificates.length + 1
                    },
                    updateColumns: [...Object.keys(this.form)],
                }
            });
            this.formLoading = false;
            this.$q.notify('Certificate saved successfully');
            // this.closeDialog();
            this.resetForm();
        },
        resetForm(){
            this.form.name = '';
            this.form.imageUrl = '';
            delete this.form.id;
            this.$refs['qForm']?.resetValidation();
            this.activeCertificateId = '';
            this.editTitle = false;
            this.firstPage = true;
        },
        closeDialog(close){
            this.resetForm();
            this.showCertificateForm = false;
            if(close) this.$emit('update:dialog', false);
        }
    }
}
</script>

<style lang="scss">
.img-tag{
    width: 100%;
    height: 100%;
    object-fit: contain; /* cover */
}
.list-delete-icon {
    visibility: hidden;
}
.list-item:hover {
    .list-delete-icon {
        visibility: visible;
        color: red;
    }
    .drag-icon {
        visibility: visible;
    }
}
.drag-icon {
    visibility: hidden;
}
.rounded-borders{
    border: 1px solid grey;
    border-radius: 5px !important;
}
@media only screen and (max-width: 600px) {
  #inline-input{
    width: 66%;
  }
}
</style>