import gql from "graphql-tag";

export const SUBSCRIBE_ORDERS = gql`
subscription orders($search: String!, $customerId: uuid!, $startDate:timestamptz!, $endDate:timestamptz!){
  orders: dc_customer_order(where:{_and: [{customer_id:{_eq: $customerId}}, {created_at: {_gte: $startDate}}, {created_at: {_lte: $endDate}}, 
                    {_or: [{name: {_ilike: $search}}, {mobile: {_ilike: $search}}, {email: {_ilike: $search}}, {gst: {_ilike: $search}} ]}]},
                    order_by: {created_at: desc}) {
    id
    name
    mobile
    email
    gst
    address
    subject
    remark
    Product {id name}   
    quantity
    created_at
  }
}`

export const SUBSCRIBE_ORDERS_COUNT = gql`
subscription ordersCount($customerId: uuid!){
  dc_customer_order_aggregate(where:{_and: [{customer_id:{_eq: $customerId}}]}) {
    aggregate{ count }
  }
}`