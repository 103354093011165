// Mixins
import Constants from "../../constants/Constants";
import axios from "axios";

/**
 * inlineEdit
 * @mixin
 *
 * Used to add update Widget title & status functionality to components
 */

export const inlineEdit = {
    methods:{
        updateWidgetStatus(columnName, isActiveValue){
            let request = {columnName, isActiveValue, customerId: JSON.parse(localStorage.user).id};
            return axios.post(`${Constants.api_url}widgetsInlineEdit`, request).then(response => {
                return response.data
            }).catch(function (err) {
                console.log('inlineEdit err', err)
            })
        }
    }

};