<template>
<div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
        <q-card>
            <q-card-section class="row">
                <div class="col-12 col-sm-12 col-md-6 offset-0 offset-sm-0 offset-md-3">
                    <div class="row justify-between items-center">
                        <div>
                            <div class="text-h5 text-weight-medium">SEO Details</div>
                        </div>
                        <div>
                            <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog">
                                <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                            </q-btn>
                        </div>
                    </div>
                </div>
            </q-card-section>
            <q-card-section class="q-mt-lg">
                <q-form ref="qForm">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 offset-0 offset-sm-0 offset-md-3 q-mt-xl">
                        <q-input class="q-mb-md" maxlength="50" v-model="form.title" label="Company Name | City Name*" square outlined counter
                                 :rules="[v => !!(v&&v.trim()) || 'Please enter Company Name | City Name', v => v.length >= 3 || 'Enter minimum 3 characters']"/>
                                          <!-- v => /^[a-zA-Z0-9 ]{1,}$/g.test(v) || 'Please enter valid String' -->
                        <q-input v-model="form.description" label="Description*" type="textarea" outlined counter
                                 :rules="[v => !!v || 'Please enter Description', v => v.length >= 3 || 'Enter minimum 3 characters']"/>
                    <div class="bg-white text-right q-pt-lg q-pb-md">
                        <q-btn label="Cancel" size="md" padding="sm md" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize"
                               unelevated @click="closeDialog"/>
                        <q-btn label="Save Changes" color="primary" size="md" padding="sm md" class="text-capitalize" unelevated
                               @click="handleSubmit" :loading="formLoading"/>
                    </div>
                    <q-separator class="q-my-xl"></q-separator>
                      <p style="font-size: 16px; font-weight: 500;">Following meta tags will be generated for SEO</p>
                    <div class="">
                        <h5 class="text-subtitle1 text-weight-regular text-indigo-6 q-mb-none">
                          &lt;title&gt;<span class="black-text">{{form.title}}</span>&lt;/title&gt; <br>
                          &lt;meta name="description" content="<span class="black-text">{{form.description&&form.description.substr(0, 30)}}
                          {{form.description.length>30?'...':''}}</span>"&gt; <br>
                          &lt;meta name="og:title" content="<span class="black-text">{{form.title}}</span>"&gt; <br>
                          &lt;meta name="og:description" content="<span class="black-text">{{form.description&&form.description.substr(0, 30)}}
                              {{form.description.length>30?'...':''}}</span>"&gt; <br>
                          &lt;meta name="og:image" content="<span class="black-text">Your Logo</span>"&gt; <br>
                          &lt;meta name="og:url" content="<span class="black-text">Your ByteCard URL</span>"&gt; <br>
                          &lt;meta name="og:type" content="<span class="black-text">website</span>"&gt; <br>
                          &lt;meta name="og:site_name" content="<span class="black-text">ByteCard</span>"&gt; <br>
                        </h5>
<!--                        <p class="q-mb-none text-caption text-green-8">https://{{ form.title }}.myshopify.com/products/</p>-->
                        <!--<p class="text-grey-7 " style="overflow-wrap: break-word">{{ form.description }}</p> -->

                    </div>
                </div>
                </div>
                </q-form>
            </q-card-section>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref} from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {CREATE_UPDATE_DC_CMS} from "../../graphql/mutations/dc";
import {GET_SEO_DETAILS_BY_CUSTOMER_ID} from "../../graphql/queries/dc";
export default {
    name: "SeoDetailForm",
    props: ['dialog'],
    emits: ['update:dialog'],
    setup (props) {
        const customerId = ref(JSON.parse(localStorage.user).id);

        const form = reactive({title:'', description:''});
        const {onResult} = useQuery(GET_SEO_DETAILS_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog, fetchPolicy:'network-only'}));
        onResult(r => {
            if(r.data.dc_cms[0]?.seo_details) Object.copyExistingKeys(form, r.data.dc_cms[0].seo_details);
        });

        const { mutate: mDcCms } = useMutation(CREATE_UPDATE_DC_CMS);
        return { customerId, form, mDcCms};
    },
    data(){
        return{
            Constants,
            moment,
            formLoading: false,
        }
    },
    methods:{
        handleSubmit(){
            this.$refs['qForm'].validate().then(success => {
                if(success) this.saveSeoDetail();
            })
        },
        async saveSeoDetail(){
            this.formLoading = true;
            await mutation({
                mutate: this.mDcCms,
                variables:{
                    dc_cms: {
                        customer_id: this.customerId, seo_details: this.form,
                        created_by: this.customerId, updated_by: this.customerId
                    },
                    updateColumns: ['seo_details', 'updated_by'],
                }
            });
            this.formLoading = false;
            this.$q.notify('SEO Detail saved successfully');
            this.closeDialog();
        },
        resetForm(){
            this.form.title = '';
            this.form.description = '';
        },
        closeDialog(){
            this.$refs['qForm'].resetValidation();
            this.resetForm();
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>
.black-text{
  color: grey;
}
</style>
