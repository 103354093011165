<template>
  <div class="aj-container">
    <div class="row justify-between items-center q-mt-lg">
      <h1 class="text-h5 q-my-none" style="font-weight: 600">ByteCard Dashboard</h1>
    </div>
    <p>Access all your Inquiries, Orders and Reports of ByteCard from here</p>
    <div class="row q-col-gutter-lg">
      <div class="col-xs-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <q-card-section>
            <div class="row justify-between">
              <div>
                <h1 class="text-h6 text-weight-bold" style="font-weight: 600">{{count.inquiries}} inquiries
                  <span class="date-interval-text">(Last 6 months)</span></h1>
                <p class="text-body2 text-weight-medium q-mb-none text-grey-7">Latest Inquires from your customers</p>
                <q-btn outline class="text-capitalize q-mt-lg text-grey-8" @click.native="$router.push('/inquiries')" label="Show Details"/>
              </div>
              <div class="col-2 text-right">
                <div class="aj-custom-path">
                  <h4 class="aj-dashboard-path-count text-h5 text-primary text-center">{{count.todayInquiries}}<br><span class="text-body2">New</span></h4>
                </div>
              </div>
            </div>
            <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -12px -11px 0 0">
              <q-tooltip class="bg-blue text-body2" max-width="50%">
                On your ByteCard there are 2 inquiry forms. One is on the product and the
                second is general. The customers who fill this inquiry form will be listed in this widget. If
                the Inquiry form is filled from the product section you will also get the name of Product
                so that you can know for which product he has done inquiry
              </q-tooltip>
            </q-icon>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-xs-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <q-card-section>
            <div class="row justify-between">
              <div>
                <h1 class="text-h6 text-weight-bold" style="font-weight: 600">{{count.orders}} Orders
                  <span class="date-interval-text">(Last 6 months)</span></h1>
                <p class="text-body2 text-weight-medium q-mb-none text-grey-7">All Orders list here</p>
                <q-btn outline class="text-capitalize q-mt-lg text-grey-8" @click.native="$router.push('/orders')" label="Show Details"/>
              </div>
              <div class="col-2 text-right">
                <div class="aj-custom-path">
                  <h4 class="aj-dashboard-path-count text-h5 text-primary">{{count.todayOrders}}<br><span class="text-body2">New</span></h4>
                </div>
              </div>
            </div>
            <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -12px -11px 0 0">
              <q-tooltip class="bg-blue text-body2" max-width="50%">
                On your ByteCard there is a Buy Now button on Product. From where customer
                can fill out the form. The customers who fill this Buy Now form will be listed in this
                Orders widget
              </q-tooltip>
            </q-icon>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from "vue";
import {useSubscription} from "@vue/apollo-composable";
import {SUBSCRIBE_ORGANIZATION_ORDERS_AND_INQUIRES_COUNT} from "../../graphql/subscriptions/organizations";
import moment from 'moment';
export default {
  name: "Dashboard",
  setup () {
    const customerId = ref(JSON.parse(localStorage.user).id);
    const count = reactive({inquiries:0, orders:0, todayInquiries:0, todayOrders:0});
    const {onResult} = useSubscription(SUBSCRIBE_ORGANIZATION_ORDERS_AND_INQUIRES_COUNT, () => (
            { id:customerId.value, createdAt: moment().format('YYYY-MM-DDT00:00:00+05:30'),
              startDate: moment().subtract(180, 'days').format('YYYY-MM-DDT00:00:00+05:30'),
              endDate: moment().format('YYYY-MM-DDT23:59:59+05:30') }));
    onResult(r => {
      count.inquiries = r.data.organizations[0].Inquiries_aggregate.aggregate.count;
      count.orders = r.data.organizations[0].Orders_aggregate.aggregate.count;
      count.todayInquiries = r.data.organizations[0].todayInquiriesCount.aggregate.count;
      count.todayOrders = r.data.organizations[0].todayOrdersCount.aggregate.count;
    });

    return {count};
  },
}
</script>

<style scoped>
.info-icon{
  float: right;
  visibility: hidden;
  cursor: pointer;
}
.my-card:hover .info-icon{
  visibility: visible;
}
.aj-custom-path {
  background: #E0F4FC;
  width: 80px;
  height: 80px;
  border-radius: 12px;
  position: relative;
  text-align: center;
  transform: rotate(20deg);
}
.aj-dashboard-path-count{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotate(-20deg) translate(-10%,-50%);
}
.aj-custom-path:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  border-radius: 12px;
  background: #E0F4FC;
  transform: rotate(135deg);
}
.date-interval-text{
  color: grey;
  font-size: 14px;
  font-weight: 400;
}
@media only screen and (max-width: 640px)  {
  .aj-custom-path{
    background: none;
}  }
@media only screen and (max-width: 640px)  {
  .aj-custom-path:before{
    background: none;
  }  }
@media only screen and (max-width: 320px)  {
  .text-body2 text-weight-medium q-mb-none text-grey-7{

  }  }
/*.aj-custom-path{*/
/*  background: grey;*/
/*  height: 120px;*/
/*  width: 120px;*/
/*  border-radius: 50px;*/
/*  clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);*/
/*}*/
</style>
