import gql from 'graphql-tag'

export const SEARCH_CITIES = gql`
query territories($cityFilter: String!){
 cities:indianCities(where: {city: {_ilike: $cityFilter}}){      
      id        
      city
      state
      country
      dial_code
    }
  }`

export const SEARCH_STATES = gql`
query territories($stateFilter: String!){
 states:indianStates(where: {state: {_ilike: $stateFilter}}){      
      id        
      state
      country
      dial_code
    }
  }`

export const SEARCH_COUNTRY = gql`  
query countries($countryFilter: String!){
 countries(where: {name: {_ilike: $countryFilter}}){      
      id        
      name
      dial_code
    }
  }`


