<template>
  <div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
      <q-card>
        <q-card-section class="aj-container row justify-between items-center">
          <div class="col-10">
            <div class="text-h5 text-weight-medium">
              <template v-if="editTitle">
                <input v-model="tempWidgetTitle" class="inline" id="inline-input"/>
                <q-btn flat round color="grey-8" icon="mdi-check" size="sm" @click="onClickTitleSave"
                       :loading="saveTitleLoading" :disable="!tempWidgetTitle.trim()"/>
                <q-btn flat round color="grey-8" icon="mdi-close" size="sm" @click="onClickTitleCancel"/>
              </template>
              <template v-else>{{ widgetTitle }}
                <q-btn flat round color="grey-8" icon="mdi-pencil-outline" size="sm" @click="onClickTitleEdit"/>
              </template>

            </div>
          </div>
          <div class="col-2 text-right">
            <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog(true)">
              <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
            </q-btn>
          </div>
        </q-card-section>
        <q-card-section class="q-mt-lg aj-container">
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-card bordered flat>
                <q-card-section class="row q-py-none q-pr-none">
                  <template v-if="!responsive || firstPage">
                  <div class="q-py-md" :class="responsive?'col-12':'col'">
                    <div class="row items-center justify-between q-pr-md q-pb-md">
                      <div>
                        <h6 style="font-weight: 600" class="text-subtitle1 text-grey-9">{{ products.length }}
                          Product</h6>
                        <span class="text-body2 text-grey-8">You can add upto {{ maxProducts }} Products</span>
                      </div>
                      <div class="row">
                        <q-input class="q-mr-md mt-10-sm" square outlined label="Search" v-model="search" dense
                                 @update:model-value="onSearch">
                          <template v-slot:prepend>
                            <q-icon name="mdi-magnify" size="20px"/>
                          </template>
                        </q-input>

                        <q-btn class="no-shadow mt-10-sm" icon="add" unelevated size="sm" text-color="primary"
                               color="blue-1" label="New" @click="onAddProduct" v-if="products.length<maxProducts"/>

                      </div>
                    </div>
                    <div style="max-height: 67vh; overflow-y: auto">
                      <q-list>
                        <draggable @end="drag=false; updateSequence()" @start="drag=true" v-model="products"
                                   item-key="id"
                                   :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
                                   v-bind="dragOptions">
                          <template #item="{element:p}">

                            <q-item v-if="!p.isHidden" clickable class="q-px-none q-mb-sm list-item"
                                    @click="onEditProduct(p)"
                                    :active="activeProductId===p.id" active-class="bg-blue-1 text-primary">
                              <q-btn round icon="drag_indicator" class="d-icon" text-color="grey"/>
                              <q-item-section avatar top>
                                <q-img v-if="p.images.length" height="52px" width="52px" class="rounded-borders"
                                       :src="getStarredImage(p.images)"/>
                                <q-avatar v-else square size="52px" text-color="grey" icon="image"
                                          class="rounded-borders"/>
                              </q-item-section>
                              <q-item-section top>
                                <q-item-label lines="1">
                                  <p class="text-weight-medium q-mb-xs">{{ p.name }}</p>
                                </q-item-label>
                                <q-item-label>
                                  <p class="text-weight-regular text-caption text-grey-8 q-mb-none desc-text ellipsis">
                                    {{ p.description }}</p>
                                </q-item-label>
                              </q-item-section>
                              <q-item-section top side>
                                <div class="text-grey-8 q-gutter-xs">
                                  <q-btn @click.stop="onDeleteProduct(p)" class="gt-xs list-delete-icon" size="12px"
                                         flat dense round icon="mdi-delete-outline"/>
                                  <q-toggle @click.stop="onChangeProductStatus(p)" v-model="p.is_active" class="toggle-icon"/>
                                </div>
                              </q-item-section>
                            </q-item>
                          </template>
                        </draggable>
                        <div v-if="noSearchResult">No Products found.</div>
                      </q-list>
                    </div>
                  </div>
                  <q-separator vertical/>
                  </template>
                  <template v-if="!responsive || !firstPage">
                  <div v-if="showProductForm" class="q-py-md q-pl-md" :class="responsive?'col-12':'col'">
                    <h6 style="font-weight: 600" class="text-subtitle1 text-grey-9 q-mb-lg">
                      {{ form.id ? 'Edit Product' : 'Add New' }}</h6>
                    <div class="q-pr-md" style="height: 63vh; overflow-y: auto">
                      <q-form ref="qForm">
                        <q-input class="q-mb-md" square outlined v-model="form.name" label="Title*" maxlength="50"
                                 counter
                                 :rules="[v => !!v || 'Please enter Title']" lazy-rules/>
                        <q-input class="q-mb-md" square outlined v-model="form.price" label="Price" type="number"
                                 :rules="[v => !v || v>=0 || 'Invalid Price']" lazy-rules=""/>
                        <q-input class="q-mt-md" outlined square v-model="form.description" counter label="Description"
                                 type="textarea" :rules="[v => !v || v.length >= 3 || 'Enter minimum 3 characters']"/>
                        <h6 class="text-subtitle2 text-weight-bold text-grey-9 q-mt-lg ">Images</h6>
                        <span class="text-caption text-grey-8">File type required JPG, PNG (Max 1MB)</span>
                        <div class="row q-col-gutter-md q-mb-sm q-mt-xs">
                          <div class="col-6" v-for="(img, index) in form.images" @click="openUploadFile(index)"
                               style="position: relative">
                            <div v-if="img.path" class="aj-uploader">
                              <img :src="imgWpx(img.path, 400)" class="img-tag">
                              <q-btn @click.stop="removeImage(index)" icon="cancel" text-color="grey"
                                     style="position: absolute;left: 6px" title="Remove Image"/>
                              <q-btn @click.stop="setImageAsFavourite(index)" icon="stars"
                                     :text-color="img.is_starred?'orange':'grey'" style="position: absolute;right: -8px"
                                     title="Set Image as Favourite"/>
                            </div>
                            <div v-else class="aj-uploader row justify-center items-center">
                              <q-btn round icon="mdi-cloud-upload-outline" unelevated text-color="primary"
                                     color="blue-1"/>
                            </div>
                          </div>
                        </div>
                      </q-form>
                    </div>
                    <div class="text-right q-mt-md q-mr-lg">
                      <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" unelevated size="md"
                             padding="sm md" @click="closeDialog(!responsive)"/>
                      <q-btn label="Save" color="primary" class="text-capitalize" unelevated size="md" padding="sm md"
                             @click="handleSubmit" :loading="formLoading"/>
                    </div>
                  </div>
                  <div v-else class="q-py-md q-pl-md text-center" :class="responsive?'col-12':'col'" style="height: 80vh;line-height: 80vh">
                    <div style="line-height: 2;display: inline-block;">
                      <q-avatar class="bg-blue-grey-1" size="100px" text-color="primary" icon="local_offer"/>
                      <div v-if="products.length">Please select any product to make changes.</div>
                      <div v-else>No Products added yet. Click on Add New button to add product.</div>
                    </div>
                  </div>
                  </template>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <UploadFile v-model:dialog="uploadFileDialog" @fileResponse="onReceiveFileResponse" fileTypes=".jpg, .jpeg, .png"
                :maxSize="maxFileSize"/>
  </div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref} from 'vue';
import {useSubscription, useMutation} from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import UploadFile from "../Upload/UploadFile";
import {CREATE_UPDATE_DC_PRODUCT} from "../../graphql/mutations/dc";
import {SUBSCRIBE_DC_PRODUCTS_BY_CUSTOMER_ID} from "../../graphql/subscriptions/organizations";
import {generate_DELETE_RECORD, generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
import updateRecord from "../../composables/updateRecord";
import deleteRecord from "../../composables/deleteRecord";
import useWidget from '../../composables/widgetTitle';
import {toRefs} from 'vue';
import draggable from 'vuedraggable';

export default {
  name: "ProductForm",
  components: {UploadFile, draggable},
  props: ['dialog'],
  emits: ['update:dialog'],
  setup(props) {
    const {dialog} = toRefs(props);
    const customerId = ref(JSON.parse(localStorage.user).id);

    const form = reactive({
      name: '',
      description: null,
      price: 0,
      images: [{path: ''}, {path: ''}, {path: ''}, {path: ''}]
    });
    const products = ref([]);
    const {onResult} = useSubscription(SUBSCRIBE_DC_PRODUCTS_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
        () => ({enabled: !!customerId.value && props.dialog}));
    onResult(r => products.value = r.data.products);

    const {
      widgetTitle, tempWidgetTitle, editTitle, saveTitleLoading,
      onClickTitleEdit, onClickTitleSave, onClickTitleCancel
    } = useWidget('products', dialog, customerId);
    const {mutate: mProduct} = useMutation(CREATE_UPDATE_DC_PRODUCT);
    const {mutate: mUpdateProduct} = useMutation(generate_UPDATE_RECORD('dc_customer_products'));
    const {mutate: mDeleteProduct} = useMutation(generate_DELETE_RECORD('dc_customer_products'));
    return {
      customerId,
      form,
      mProduct,
      products,
      mUpdateProduct,
      mDeleteProduct,
      widgetTitle,
      tempWidgetTitle,
      editTitle,
      saveTitleLoading,
      onClickTitleEdit,
      onClickTitleSave,
      onClickTitleCancel
    };
  },
  data() {
    return {
      Constants, moment,
      formLoading: false,
      imageIndex: 0,                /* Image index while upload */
      uploadFileDialog: false,
      search: '',
      noSearchResult: false,
      activeProductId: '',
      drag: false,
      showProductForm: false,
      maxProducts: 25,
      maxFileSize: 1048576,        /* 1MB */
      firstPage: true
    }
  },
  computed: {
    dragOptions() {
      return {animation: 200, group: "description", disabled: false, ghostClass: "ghost"};
    }
  },
  methods: {
    onSearch(val) {
      val = val?.toLowerCase() || '';
      this.products.forEach(p => p.isHidden = p.name.toLowerCase().indexOf(val) === -1);
      this.noSearchResult = !this.products.find(p => !p.isHidden);
    },
    async updateSequence() {
      this.products.forEach((p, index) => {
        p.sequence = index + 1;
        p.customer_id = this.customerId;
        delete p.__typename;
      });
      await mutation({
        mutate: this.mProduct,
        variables: {
          product: this.products,
          updateColumns: ['sequence'],
        }
      });
      this.$q.notify('Products sequence updated successfully');
    },
    onAddProduct() {
      this.resetForm();
      this.showProductForm = true;
      this.firstPage = false;
    },
    onEditProduct(row) {
      this.activeProductId = row.id;
      this.form.id = '';
      Object.copyExistingKeys(this.form, JSON.parse(JSON.stringify(row)));
      for (let i = this.form.images.length; i < 4; i++) {
        this.form.images[i] = {path: ''}
      }
      this.showProductForm = true;
      this.firstPage = false;
    },
    getStarredImage(images) {
      let starredImage = images.find(i => i.is_starred);
      if (starredImage) return this.imgWpx(starredImage.path, 200);
      else return this.imgWpx(images[0].path, 200);        /* not required in future */
    },
    removeImage(index) {
      this.form.images.splice(index, 1);
      this.form.images.push({path: ''});

      if (!this.form.images.find(i => i.is_starred) && this.form.images.find(i => i.path))
        this.form.images.filter(i => i.path)[0].is_starred = true;
    },
    setImageAsFavourite(index) {
      this.form.images.forEach((i, idx) => {
        i.is_starred = idx === index
      });
    },
    openUploadFile(index) {
      this.imageIndex = index;
      this.uploadFileDialog = true;
    },
    onReceiveFileResponse(fileResponse) {
      this.form.images[this.imageIndex].path = fileResponse;
      if (this.form.images.filter(i => i.path).length === 1)
        this.form.images[this.imageIndex].is_starred = true;
    },
    onChangeProductStatus(row) {
      this.$q.dialog({
        title: 'Confirm', cancel: true, persistent: true,
        message: `Are you sure you want to ${row.is_active ? 'active' : 'inactive'} product ?`,
      }).onOk(() => this.updateProductStatus(row)).onCancel(() => row.is_active = !row.is_active)
    },
    async updateProductStatus(row) {
      await updateRecord(this.mUpdateProduct, row.id, {is_active: row.is_active});
      this.$q.notify('Product Status updated successfully');
    },
    onDeleteProduct(row) {
      this.$q.dialog({
        title: 'Confirm', cancel: true, persistent: true,
        message: `Are you sure you want to delete product ?`,
      }).onOk(() => this.deleteProduct(row))
    },
    async deleteProduct(row) {
      await deleteRecord(this.mDeleteProduct, row.id);
      this.$q.notify('Product deleted successfully');
      let index = this.products.findIndex(p => p.id === row.id);
      if (index > -1) this.products.splice(index, 1);
      this.updateSequence();
    },
    handleSubmit() {
      if (this.products.length >= this.maxProducts && !this.form.id)
        return this.$q.notify(`Cannot add more than ${this.maxProducts} products`);
      this.$refs['qForm'].validate().then(success => {
        if (success) this.saveProduct();
      })
    },
    async saveProduct() {
      this.formLoading = true;
      await mutation({
        mutate: this.mProduct,
        variables: {
          product: {
            ...this.form, price:this.form.price||0, customer_id: this.customerId, sequence: this.products.length + 1,
            images: this.form.images.filter(i => i.path).map((i, index) => ({
              ...i,
              sequence: index + 1,
              is_active: true,
              is_starred: i.is_starred || false
            }))
          },
          updateColumns: [...Object.keys(this.form)],
        }
      });
      this.formLoading = false;
      this.$q.notify('Product saved successfully');
      // this.closeDialog();
      this.resetForm();
    },
    resetForm() {
      this.form.name = '';
      this.form.description = null;
      this.form.price = 0;
      this.form.images = [{path: ''}, {path: ''}, {path: ''}, {path: ''}];
      delete this.form.id;
      this.$refs['qForm']?.resetValidation();
      this.activeProductId = '';
      this.editTitle = false;
      this.firstPage = true;
    },
    closeDialog(close) {
      this.resetForm();
      this.showProductForm = false;
      if(close) this.$emit('update:dialog', false);
    }
  }
}
</script>

<style lang="scss">
.img-tag {
  width: 100%;
  height: 100%;
  object-fit: contain; /* cover */
}

.list-delete-icon {
  visibility: hidden;
}

.list-item:hover {
  .list-delete-icon {
    visibility: visible;
    color: red;
  }

  .d-icon {
    visibility: visible;
  }
}

@media only screen and (max-width: 600px) {
  .d-icon{
    visibility: visible;
  }
  .toggle-icon{
    margin-top: -8px;
  }
  .mt-10-sm{
    margin-top: 10px;
  }
  #inline-input{
    width: 66%;
  }
}
@media only screen and (min-width: 600px) {
  .d-icon{
    visibility: hidden;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*cursor: default;*/
}

.desc-text {
  display: inline-block;
  vertical-align: middle;
  width: 250px;
  max-width: 250px;
}

.rounded-borders {
  border: 1px solid grey;
  border-radius: 5px !important;
}
</style>
