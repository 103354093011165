<template>
  <div class="aj-login-bg">
    <div class="aj-container">
      <div class="row">
        <div class="col-sm-12 col-md-6" style="z-index: 999">
          <div class="p-4 q-mt-lg">
            <div class="row items-center">
              <img src="../assets/images/ByteCardLogo.png" alt="ByteCardLogo" style="width: 140px">
             <!-- <img src="../assets/images/dummyLogo.png" alt="dummyLogo">
              <h6 class="text-subtitle1 text-weight-medium text-white ml-2">ByteCard</h6> -->
            </div>

<!--            <p class="text-caption mb-0 text-white">Come with us and let's have your own brand on Digital Platform</p>-->
           <!-- <div style="margin-bottom: 50px;margin-top: 40px;"> -->
            <div class="mb-5 mt-5">
              <h3 class="text-h5 text-white" style="font-weight: 600">Sign Up for New Account</h3>
              <p class="text-caption mb-0 text-white">Get yourself registered to experience your website
                with ByteCard - The Digital Business Card</p>
            </div>
            <q-form ref="qForm">
              <div class="row q-col-gutter-sm">
                <div class="col-12">
                  <q-input label="Email*" v-model="form.email" lazy-rules filled dark
                             :rules="[v => !!v || 'Please enter Email', v => Constants.EmailPattern.test(v) || 'Invalid Email']"/>
                </div>
                <div class="col-xs-12 col-md-6">
                  <q-input label="Password*" v-model="form.password" lazy-rules filled dark
                           :type="showPassword?'text':'password'"
                           :rules="[v => !!v || 'Please enter Password', v => v.length >= 8 || 'Password must be at least 8 characters']">
                    <template v-slot:append>
                      <q-icon :name="showPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
                              @click="showPassword = !showPassword"/>
                    </template>
                  </q-input>
                </div>
                <div class="col-xs-12 col-md-6">
                  <q-input label="Re-type Password*" v-model="form.password_confirmation" lazy-rules filled dark
                           :type="showConfirmPassword?'text':'password'"
                           :rules="[v => !!v || 'Please enter Password', v => v === form.password || 'Confirm Password should be same as Password']">
                    <template v-slot:append>
                      <q-icon :name="showConfirmPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
                              @click="showConfirmPassword = !showConfirmPassword"/>
                    </template>
                  </q-input>
                </div>
                <div class="col-12">
                  <q-input label="Referral code (Optional)" v-model="form.promo_code" filled dark @keyup.enter="handleSubmit"/>
                </div>
              </div>
              <div class="text-center q-mt-md">
                <q-btn label="Sign Up" class="full-width text-capitalize q-mb-md" color="green" size="lg" padding="md" style="color: white !important; font-size: 14px" @click="handleSubmit" :loading="formLoading"/>
                <div class="row justify-center q-px-none items-center">
                  <hr class="q-my-md col-5" style="border: 0.5px solid #274D78">
                  <span class="col-1 text-white">Or</span>
                  <hr class="q-my-md col-5" style="border: 0.5px solid #274D78">
                </div>
                <div class="row q-col-gutter-md mt-2">
                 <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6"> -->
                  <div class="col-12">
                  <q-btn id="customBtn" label="Sign Up with Google"  icon="mdi-google" class="w-100 q-mr-lg text-capitalize" text-color="white"
                         @click="socialLogin('google')"  outline color="red" size="lg" padding="md" style="font-size: 14px;"/>
                  </div>
                 <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                  <q-btn label="Sign Up with facebook" icon="mdi-facebook" class="w-100 text-capitalize" text-color="white"
                         @click="socialLogin('facebook')" outline color="red" size="lg" padding="md" style="font-size: 14px;"/>
                  </div> -->
                </div>
                <q-btn class="full-width text-capitalize q-mt-md q-mb-xl" style="background-color: #00234A; color:white; font-size: 14px" size="lg" padding="md" @click="redirectToLogin">
                  Already have an Account? Login</q-btn>
              </div>
              <p class="text-caption text-white" style="text-align: center">© 2022 ByteBiz Pvt Ltd | All Rights Reserved</p>
            </q-form>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 login-hand-container">
          <img src="../assets/images/loginHand.png" alt="loginHand" class="login-img">
        </div>
       <!-- <div class="aj-footer">
          <p class="text-caption mb-0 text-white"> © 2022 ByteBIZ PVT LTD | ALl Right Reserved  </p>
        </div> -->
      </div>
    </div>

    <q-dialog persistent v-model="successPopup">
      <q-card>
        <q-card-section class="success-header">
          <q-icon name="check_circle_outline" size="md"></q-icon>
          Sign Up Successfully
        </q-card-section>
        <q-separator/>
        <q-card-section class="text-center">
          <div class="success-content">
            Congratulations, Your Account has been created successfully. Please click on continue to login
          </div>
          <q-btn label="Continue" autofocus @click="loginAndRedirectToProfile" class="continue-btn"/>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import axios from 'axios';
import Constants from "../constants/Constants";
import {reactive} from 'vue';
import {wsLinkA} from "../vue-apollo";
export default {
  name: "SignUp",
  setup() {
    const form = reactive({email: '', password: '', password_confirmation: '',
                                 promo_code: Constants.DefaultPromoCode, product_id: Constants.ByteCardProductId});
    return {form};
  },
  data() {
    return {
      Constants,
      successMessage: '',
      successPopup: false,
      showPassword: false,
      showConfirmPassword: false,
      formLoading: false,
      loginLoading: false,
    }
  },
  created() {
    if (this.$route.params.promoCode) this.form.promo_code = this.$route.params.promoCode;
  },
  methods: {
    handleSubmit() {
      this.$refs['qForm'].validate().then(success => {
        if (success) this.register();
      })
    },
    async register() {
      this.formLoading = true;
      this.form.promo_code = this.form.promo_code || Constants.DefaultPromoCode;
      let response = await axios({method: 'POST', url: Constants.api_url + 'register', data: this.form});
      if (response.data.error && response.data.error.length) {
        this.$q.notify(response.data.error[0]);
      } else if (response.data.status) {
        this.$q.notify('Sign up successfully');
        this.successMessage = `Registered Successfully with ${this.form.email}`;
        this.successPopup = true;
        // this.resetForm();
      } else {
        this.$q.notify(response.data.message);
      }
      this.formLoading = false;
    },
    redirectToLogin() {
      this.$router.push('/login');
      window.scrollTo(0,0);
    },
    loginAndRedirectToProfile(){
      this.loginLoading = true;
      let request = {email: this.form.email, password: this.form.password, product_id:Constants.ByteCardProductId};
      axios({method: 'POST', url: Constants.api_url + 'login', data: request}).then(response => {
        if(response.data.status) {
          localStorage.setItem('token', response.data.data.token);
          let user = response.data.data;
          localStorage.setItem('user', JSON.stringify(user));
          this.$emit('setUser', user);
          this.$q.notify('Logged in successfully');
          /*TODO: Close socket connection & reconnect will ensure a new connection with new token */
          wsLinkA.subscriptionClient.close();
          this.$router.push('/profile');
        } else {
          this.$q.notify(response.data.message);
        }
        this.loginLoading = false;
      }).catch(e => console.log('login error', e))
    },
    resetForm() {
      this.form.email = '';
      this.form.password = '';
      this.form.password_confirmation = '';
      this.form.promo_code = '';
      this.$refs['qForm'].resetValidation();
    }
  }
}
</script>

<style scoped>

.success-header {
  background: #8bc34a;
  color: #fff;
  font-size: 20px;
}

.success-content {
  font-size: 20px;
  margin: 16px 0 20px 0;
  color: #757575;
  letter-spacing: 1px;
}

/deep/ .q-btn--outline:before {
  border-color: #274E78;
}

.aj-login-bg {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #003065;
}
.aj-footer {
  display: flex;
  justify-content: left;
  height: auto;
  width: 100%;
  left: 0;
  bottom: 0;
}
.login-img {
  position: fixed;
  right: 0px;
  top: 5%;
}

@media only screen and (max-width: 1024px) {
  .login-hand-container{
    display: none;
  }
  .aj-login-bg {
    min-height: 100vh;
    height: 100%;
  }
}
.aj-login-bg {
  min-height: 100vh;
  height: 100%;
}
@media only screen and (min-width: 1024px) {
  .aj-container{
    min-width: 1140px;
  }
}
@media only screen and (min-height: 807px) {
  .aj-login-bg{
    height: 105vh;
  }
  .aj-footer{
    display: flex;
    justify-content: left;
    flex-direction: column-reverse;
    position: fixed;
  }
}
@media only screen and (min-height: 807px) {
  .aj-login-bg{
    height: 105vh;
  }
}

</style>
