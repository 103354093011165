import axios from 'axios'

export function setHeader () {
  axios.defaults.headers.common = {
    'Accept': 'application/json',
    'token': localStorage.token,
  }
}

setHeader();

export function NetworkCommunicator (method, url, loading, postData) {
  if (method === 'GET') {
    return axios.get(url).then(response => {
      return response.data
    }).catch(function (err) {
      console.log('err', err)
    })
  } else if (method === 'POST') {
    return axios.post(url, postData).then(response => {
      return response.data
    }).catch(function (err) {
      console.log('err', err)
    })
  } else if (method === 'PUT') {
    return axios.put(url, postData).then(response => {
      return response.data
    }).catch(function (err) {
      console.log('err', err)
    })
  }
}

export default { NetworkCommunicator, setHeader }

