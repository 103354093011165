import gql from "graphql-tag";

export const GET_SEO_DETAILS_BY_CUSTOMER_ID = gql`
query getSeoDetailsByCustomerId($customerId: uuid!){
  dc_cms(where: { customer_id: {_eq: $customerId}}){
    id
    seo_details    
  }
}`

export const GET_ABOUT_US_BY_CUSTOMER_ID = gql`
query getAboutUsByCustomerId($customerId: uuid!){
  dc_cms(where: { customer_id: {_eq: $customerId}}){
    id
    about_us    
  }
}`

export const GET_PAYMENT_DETAILS_BY_CUSTOMER_ID = gql`
query getPaymentDetailsByCustomerId($customerId: uuid!){
  dc_cms(where: { customer_id: {_eq: $customerId}}){
    id
    payment_details    
  }
}`

export const GET_SOCIAL_MEDIA_BY_CUSTOMER_ID = gql`
query getSocialMediaByCustomerId($customerId: uuid!){
  dc_cms(where: { customer_id: {_eq: $customerId}}){
    id
    social_media    
  }
}`

export const GET_THEME_BY_CUSTOMER_ID = gql`
query getThemeByCustomerId($customerId: uuid!){
  dc_cms(where: { customer_id: {_eq: $customerId}}){
    id
    theme    
  }
}`

export const GET_WA_MESSAGE_BY_CUSTOMER_ID = gql`
query getDefaultWaMessageByCustomerId($customerId: uuid!){
  dc_cms(where: { customer_id: {_eq: $customerId}}){
    id
    wa_message    
  }
}`