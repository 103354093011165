<template>
  <div class="aj-container">
    <div class="row justify-between items-center q-mt-lg q-mb-lg">
      <h1 class="text-h5 q-my-none" style="font-weight: 600">Dashboard</h1>
    </div>
    <div class="row q-col-gutter-lg">
      <div class="col-12">
        <q-card flat bordered class="my-card no-border-radius">
          <q-card-section>
            <div class="row justify-between no-wrap">
              <div class="row items-center">
                <div>
                  <img src="../assets/images/businessCard.png" style="width: 80px" alt="businessDetails">
                </div>
                <div class="q-ml-md">
                  <h1 class="text-h6 text-weight-bold" style="font-weight: 600">Business Details</h1>
                  <p class="text-body2 text-weight-medium q-mb-none text-grey-7">Last Updated On
                    {{customer.updated_at?moment(customer.updated_at).format('DD MMM YYYY hh:mm A'):''}}</p>
                </div>
              </div>
              <div class="col-2 text-right">
                <q-btn flat color="primary" class="text-capitalize" size="md" @click="formDialog=true" label="Edit" />
                <q-linear-progress stripe class="q-mt-md" size="5px" :value="0.4"/>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <business-detail-form v-model:dialog="formDialog"/>
  </div>
</template>

<script>
import Constants from "../constants/Constants";
import moment from "moment";
import BusinessDetailForm from "../components/BusinessDetail/BusinessDetailForm";
import {ref, reactive} from "vue";
import {useSubscription} from "@vue/apollo-composable";
import {SUBSCRIBE_ORGANIZATION_BY_ID} from "../graphql/subscriptions/organizations";

export default {
  name: "BusinessDetail",
  components: {BusinessDetailForm},
  setup() {
    const customerId = ref(JSON.parse(localStorage.user).id);
    const formDialog = ref(false);

    const customer = reactive({business_detail: {}});
    const {onResult} = useSubscription(SUBSCRIBE_ORGANIZATION_BY_ID, () => ({id: customerId.value}));
    onResult(r => {
      Object.assign(customer, r.data.organizations[0]);
      checkBusinessDetail(customer)
    });
    const checkBusinessDetail = (c) => {
      if (c.name && c.business_detail?.personName && c.email && c.mobile && c.whatsapp &&
          c.address?.addressLine1 && c.address?.city && c.address?.state && c.address?.pincode) {
        localStorage.setItem('isBusinessDetailCompleted', 'true');
        formDialog.value = false;
      } else {
        localStorage.setItem('isBusinessDetailCompleted', '');
        formDialog.value = true;
      }
    };
    return {customer, formDialog};
  },
  data() {
    return {
      Constants, moment
    }
  },
  methods: {}
}
</script>

<style scoped>
.label {
  color: #5f6368;
  margin: 0 0 6px 0;
}

.value {
  color: #000;
}
</style>
