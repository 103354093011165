
/**
 * updateMultiple
 * @composable
 *
 * Used to update tables by id
 */

export default async function updateRecord({mutate, ...rest}) {

    await new Promise(resolve => mutate({...rest},  {update: () => {
            resolve();
        }}));
}