<template>
    <div>

        <q-dialog v-model="dialog" persistent>
            <q-card style="width: 420px;">
              <q-card-section>
                <div class="text-h6">Choose your Domain Name</div>
              </q-card-section>

                <q-separator />
                <q-card-section class="my-2">
                    <q-form ref="qForm">
                        <div class="row q-col-gutter-sm">
                          <div class="col-12 mb-4">This domain name will be used to generate your Unique ByteCard URL that you can share with others</div>
                            <div class="col-12">
                              <div style="background-color: #F4F4F4; border-radius: 50px; align-items: center" class="row">
                                <q-icon name="mdi-lock" size="14px" class="mr-2 ml-4"></q-icon>
                                <p class="mr-3 mb-0">https://</p>
                                <q-input class="q-pb-none q-mb-none" square  label="Sub-domain" v-model="form.website" autofocus
                                         @blur="onBlurDomain" ref="sub-domain"
                                         :rules="[v => !v || v.length >= 2 || 'Enter minimum 2 characters',
                                                  v => !v || /^[a-zA-Z0-9\-]+$/g.test(v) || 'Invalid Sub-domain Name',
                                                  v => isUniqueDomain || 'That domain is taken. Try another.']" maxlength="20">
                                </q-input>
                                <p class="ml-3 mb-0">{{ '.' + Constants.publish_app_domain }}</p>
                              </div>
                            </div>
                        </div>
                    </q-form>
                </q-card-section>
              <q-separator/>
              <q-card-actions align="right" class="text-primary q-py-md q-mr-sm">
                    <q-btn flat label="Skip for Now"  @click="closeDialog" />
                    <q-btn color="primary" label="Save" :disabled="!form.website" @click="handleSubmit" :loading="formLoading"/>
                </q-card-actions>
            </q-card>
        </q-dialog>

    </div>
</template>

<script>
import {useMutation} from "@vue/apollo-composable";
import {generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
import updateRecord from "../../composables/updateRecord";
import Constants from "../../constants/Constants";
import checkDomain from "../../composables/checkDomain";
let form = {website:null};
export default {
    name: "SubdomainForm",
    props: ['dialog', 'loading'],
    emits: ['update:dialog'],
    setup(){
        const { mutate: mUpdateCustomer } = useMutation(generate_UPDATE_RECORD('organizations'));
        return {mUpdateCustomer};
    },
    data(){
        return{
            Constants,
            form: {...form},
            formLoading: false,
            isUniqueDomain: true
        }
    },
    methods:{
        async onBlurDomain(){
            this.isUniqueDomain = true;
            this.$refs['sub-domain'].validate();
            if(!this.$refs['sub-domain'].hasError && this.form.website){
                let flag = await checkDomain(this.form.website);
                this.isUniqueDomain = flag;
                this.$refs['sub-domain'].validate();
            }
        },
        handleSubmit(){
            this.$refs['qForm'].validate().then(async success => {
                if(success) {
                    await this.onBlurDomain();
                    if (this.isUniqueDomain) this.saveDomain();
                }
            })
        },
        async saveDomain(){
            this.formLoading = true;
            let customerId = JSON.parse(localStorage.user).id;
            this.form.website = this.form.website.toLowerCase();
            await updateRecord(this.mUpdateCustomer, customerId, this.form);
            this.$q.notify('Sub-domain updated successfully');
            localStorage.setItem('subDomain', this.form.website);
            this.formLoading = false;
            this.closeDialog();
        },
        closeDialog(){
            this.form = {...form};
            this.$emit('update:dialog', false);
            this.$router.push('/cardBuilder');
        }
    }
}
</script>

<style scoped>
.side-text{
    font-size: 20px;
    color: black;
}
</style>