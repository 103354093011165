<template>
  <div>

    <q-dialog v-model="dialog" persistent transition-show="slide-up" transition-hide="slide-down" :maximized="responsive">
      <q-card class="q-pa-md">
        <div class="text-center q-my-md">
          <h4 class="text-h6">Upload File</h4>
          <p class="text-body2 text-grey-8">Upload your file here to share</p>
          <!--                        <q-btn @click="closeDialog" class="float-right" flat icon="close" round size="small"/>-->
        </div>
        <div class="q-pa-md q-mt-xl">
          <div>
            <div class="mb-3 text-center">
              <input id="file" @change="onSelectFile()" type="file" style="display: none" :accept="fileTypes"/>
              <div @click="openFile" class="q-mx-auto image-box">
                <div v-if="fileDataURI && file.type==='application/pdf'">
                  <div style="width: 250px; height: 250px;" class="mx-auto mb-4">
                    <img src="../../assets/pdf.png" alt="" class="w-100">
                  </div>
                  <q-btn @click.stop="" class="pd-btn mx-auto w-50 d-block" :href="fileDataURI" target="_blank">
                    PREVIEW
                  </q-btn>
                </div>
                <div v-else-if="fileDataURI" class="q-mx-auto q-mb-4 with-image-box">
                  <!-- <img :src="fileDataURI"  class="w-100"> -->
                  <cropper ref="cropper" @click.stop="" :src="fileDataURI" :auto-zoom="true"
                           image-restriction="stencil" style="width: 100%; height: 100%"/>
                </div>
                <i v-else class="material-icons icon-cloud">cloud_upload</i>
              </div>
              <div class="row q-gutter-md q-mt-lg ">
                <q-btn @click="closeDialog" label="Cancel" class="col bg-grey-3 text-grey-8 text-capitalize" size="md"/>
                <q-btn @click="crop" :disable="!file" color="primary" class="text-capitalize col" size="md" label="Upload"
                       :loading="fileLoading"/>
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import Constants from '../../constants/Constants';
import {NetworkCommunicator} from '../../plugins/NetworkResourceHandler';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: "UploadFile",
  props: ['dialog', 'fileTypes', 'maxSize'],
  emits: ['update:dialog'],
  components: {Cropper},
  data: function () {
    return {
      file: '',
      fileDataURI: '',
      fileLoading: false,
      img: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
    }
  },
  methods: {
    openFile() {
      document.getElementById('file').click();
    },
    onSelectFile() {
      this.file = document.getElementById('file').files[0];
      this.fileDataURI = window.URL.createObjectURL(this.file);
    },
    crop() {
      const {canvas} = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        let cropFile = new File([blob], this.file.name);
        if (this.maxSize && cropFile.size > this.maxSize)
          return this.$q.notify(`File is too big! Please upload file with size less than ${this.maxSize / 1048576}MB`);
        this.uploadFile(cropFile);
      }, this.file.type);
    },
    uploadFile(cropFile) {
      this.fileLoading = true;
      let formData = new FormData();
      formData.append('image', cropFile);

      NetworkCommunicator('POST', Constants.api_url + 'upload', false, formData).then(res => {
        if (res.status) {
          this.$emit('fileResponse', res.data);
          this.closeDialog();
          this.$q.notify('File uploaded successfully');
        } else this.$q.notify(res.message);
        this.fileLoading = false;
      }).catch(function (err) {
        console.log('Upload File Error', err);
        this.$q.notify('File Upload failed');
        this.fileLoading = false;
      })
    },
    reset() {
      this.file = '';
      this.fileDataURI = '';
    },
    closeDialog() {
      this.reset();
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>
.image-box {
  height: 250px;
  width: 400px;
  border-radius: 10px;
  border: 1px dashed #e0e0e0;
  cursor: pointer;
  text-align: center;
  line-height: 250px;
}
.with-image-box{
  height: 250px;
  width: 350px;
}
@media only screen and (max-width: 600px) {
  .image-box{
    width: 100%;
  }
  .with-image-box{
    width: 100%;
  }
}
.icon-cloud {
  font-size: 50px;
  color: grey;
}

.w-100 {
  width: 100% !important;
  height: 100% !important;
}

/deep/ .vue-handler-wrapper__draggable {
  cursor: unset !important;
  opacity: unset !important;
}

/deep/ .vue-simple-handler {
  cursor: unset !important;
  opacity: unset !important;
}
</style>
