import { useQuery } from '@vue/apollo-composable';
import { ref, onMounted } from 'vue'
import {GET_WIDGET_TITLE_BY_CUSTOMER_ID} from "../graphql/queries/widget";
import useQuasar from 'quasar/src/composables/use-quasar.js';
import axios from "axios";
import Constants from "../constants/Constants";

export default function useWidget(column, dialog, customerId) {
    const widgetTitle = ref('');
    const tempWidgetTitle = ref('');
    const editTitle = ref(false);
    const saveTitleLoading = ref(false);
    const $q = useQuasar();
    const getWidgetTitle = () => {
        const {onResult} = useQuery(GET_WIDGET_TITLE_BY_CUSTOMER_ID(column), () => ({customerId: customerId.value}),
            () => ({enabled: dialog.value && customerId.value, fetchPolicy:'network-only'}));
        onResult(r => {
            widgetTitle.value = r.data.dc_customer_widgets[0][column].widget_title;
            widgetTitle.value = widgetTitle.value || Constants.mapColToWidget[column];
        });
    };

    onMounted(getWidgetTitle);

    function onClickTitleEdit(){
        tempWidgetTitle.value = widgetTitle.value;
        editTitle.value = true;
    }
    function onClickTitleCancel(){
        editTitle.value = false;
    }
    function onClickTitleSave(){
        widgetTitle.value = tempWidgetTitle.value;
        /* Also save in db */
        saveTitleLoading.value = true;
        updateWidgetTitle(column, widgetTitle.value).then(response => {
            if(response.status) $q.notify(`${Constants.mapColToWidget[column]} title saved successfully`);
            else $q.notify(response.message);
            saveTitleLoading.value = false;
            editTitle.value = false;
        });
    }
    function updateWidgetTitle(columnName, widgetTitle){
        let request = {columnName, widgetTitle, customerId: JSON.parse(localStorage.user).id};
        return axios.post(`${Constants.api_url}widgetsInlineEdit`, request).then(response => {
            return response.data
        }).catch(function (err) {
            console.log('inlineEdit err', err)
        })
    }

    return {widgetTitle, tempWidgetTitle, editTitle, saveTitleLoading, onClickTitleEdit, onClickTitleCancel, onClickTitleSave}
}