/**
 * deleteRecord
 * @composable
 *
 * Used to delete record by id
 */

export default async function deleteRecord(mutate, id) {
    await new Promise(resolve => mutate({id},  {update: () => {
            resolve();
        }}));
}