import gql from "graphql-tag";

export function GET_WIDGET_TITLE_BY_CUSTOMER_ID(column) {
 return gql`
  query get_${column}_widget($customerId: uuid!){
    dc_customer_widgets(where: { customer_id: {_eq: $customerId}}){
      id
      ${column}    
    }
  }`
}
