<template>
<div>

  <q-dialog persistent v-model="dialog">
    <q-card>
      <div class="row q-pa-md">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <h4 class="mt-0" style="font-size: 26px; font-weight: 500;">Update Password</h4>
            <span class="d-block">
                  <q-btn @click="closeDialog" class="q-ml-auto" text-color="red-6" color="red-1" round
                         icon="close" size="small"/>
            </span>
          </div>
        </div>
        <q-form class="q-pa-md m-2" ref="form">
          <div class="row q-col-gutter-sm">
            <div class="col-12">
              <q-input :rules="[v => v && v.length>0 || 'Please enter Password',
                       v => v.length >= 8 || 'Password must be at least 8 characters']"
                       class="searchBox p-2 my-3" filled label="Password*"
                       :type="showPassword?'text':'password'" v-model="form.password">
                <template v-slot:append>
                  <q-icon :name="showPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
                          @click="showPassword = !showPassword"/>
                </template>
              </q-input>
            </div>
            <div class="col-12">
              <q-input :rules="[v => v && v.length>0 || 'Please enter Password Confirmation',
                                v => v === form.password || 'Passwords must match']"
                       class="searchBox p-2 my-3" filled label="Password Confirmation*"
                       :type="showPasswordConfirmation?'text':'password'" v-model="form.passwordConfirmation">
                <template v-slot:append>
                  <q-icon :name="showPasswordConfirmation ? 'visibility' : 'visibility_off'" class="cursor-pointer"
                          @click="showPasswordConfirmation = !showPasswordConfirmation"/>
                </template>
              </q-input>
            </div>
          </div>
        </q-form>
        <q-card-actions>
          <q-btn @click="handleSubmit" class="pd-btn" color="primary" :loading="formLoading">Submit</q-btn>
          <q-btn @click="closeDialog">Cancel</q-btn>
        </q-card-actions>
      </div>
    </q-card>
  </q-dialog>


</div>
</template>

<script>
import axios from "axios";
import Constants from "../../constants/Constants";
let form = {password:'', passwordConfirmation:''}
export default {
  name: "ChangePassword",
  props: ['dialog'],
  emits: ['update:dialog'],
  data(){
    return{
      form: {...form},
      formLoading: false,
      showPassword: false,
      showPasswordConfirmation: false
    }
  },
  methods:{
    handleSubmit(){
      this.$refs['form'].validate().then(success => {
        if(success) this.updatePassword();
      })
    },
    async updatePassword() {
      this.formLoading = true;
      let customerId = JSON.parse(localStorage.user).id;
      let response = await axios.post(`${Constants.api_url}changePassword`,
          {password: this.form.password, customerId}
      );
      this.formLoading = false;
      if (response.data.status) {
        this.$q.notify(response.data.message);
        this.closeDialog();
      } else {
        this.$q.notify(response.data.message)
      }
    },
    closeDialog(){
      this.$refs['form'].resetValidation();
      this.form = {...form};
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>

</style>