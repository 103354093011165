<template>
<div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
        <q-card>
            <q-card-section class="aj-container row justify-between items-center">
                <div>
                    <div class="text-h5 text-weight-medium">Select Theme</div>
                </div>
                <div>
                    <q-btn round text-color="red-6" color="red-1"
                           icon="close" @click="closeDialog">
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </div>
            </q-card-section>

            <q-card-section class="q-mt-lg aj-container">

                <div class="row q-col-gutter-lg q-mt-sm justify-center">
                  <template v-if="form.themeCode === 'dc_theme_02'">
                    <div class="col-xs-6 col-sm-4" style="max-height: 35vh">
                        <img v-if="form.themeCode && form.colorCode" width="360"
                             :src="themeImage(form.themeCode, form.colorCode, 'desktop', 'front')"
                             class="full-height float-right" alt="businessCard" style="width: 100%">
                      <div class="card-text">ByteCard Front</div>
                    </div>
                    <div class="col-xs-6 col-sm-4" style="max-height: 35vh">
                        <img v-if="form.themeCode && form.colorCode" width="360"
                             :src="themeImage(form.themeCode, form.colorCode, 'desktop', 'back01')"
                             class="full-height float-left" alt="businessCard" style="width: 100%">
                      <div class="card-text">ByteCard Back - If Services are added</div>
                    </div>
                    <div class="col-xs-12 col-sm-4" style="max-height: 35vh">
                        <img v-if="form.themeCode && form.colorCode" width="360"
                             :src="themeImage(form.themeCode, form.colorCode, 'desktop', 'back')"
                             class="full-height float-left" alt="businessCard" style="width: 100%">
                      <div class="card-text">ByteCard Back - If Services are not added</div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-6" style="max-height: 35vh">
                        <img v-if="form.themeCode && form.colorCode"
                             :src="themeImage(form.themeCode, form.colorCode, 'desktop', 'front')"
                             class="full-height float-right" style="border: 1px solid lightgrey; width:100%" alt="businessCard">
                    </div>
                    <div class="col-6" style="max-height: 35vh">
                        <img v-if="form.themeCode && form.colorCode"
                             :src="themeImage(form.themeCode, form.colorCode, 'desktop', 'back')"
                             class="full-height float-left" style="border: 1px solid lightgrey; width:100%" alt="businessCard">
                    </div>
                  </template>
                    <div class="custom-chip q-pa-sm row items-center q-mt-xl">
                       <!-- <div class="theme-color-indicator q-mr-sm" :style="'background:'+selectedColor.hex_code"></div> -->
                        <div class="text-weight-medium text-body1">{{ form.themeCode }}</div>
                    </div>
                </div>

                <div class="col-12">
                    <q-carousel height="220px" v-model="slide" transition-prev="slide-right" navigation padding
                                transition-next="slide-left" animated control-color="primary" arrows>
                        <template v-for="(s, sIndex) in slides">
                            <q-carousel-slide :name="sIndex" class="column no-wrap">
                                <div class="row justify-center items-center q-gutter-xs q-col-gutter-lg no-wrap">
                                    <div class="col-6 col-sm-6 col-md-2 color-box" v-for="(t, tIndex) in s" @click="onSelectTheme(t)">
                                        <div class="color-box" :class="form.themeId === t.id?'active-box':''" style="width: 100%; height:100px">
                                            <img :src="themeImage(t.code, t.defaultColor.code, 'desktop', 'front')"
                                                 class="full-height float-right" style="width: 100%" alt="businessCard">
                                        </div>
                                        <p class="text-center q-mb-none text-body2 text-grey-8 q-mt-sm"
                                           :class="form.themeId === t.id?'text-primary text-center text-body2 q-mb-none q-mt-sm':''">
                                            {{ t.code }}</p>
                                    </div>
                                </div>
                            </q-carousel-slide>
                        </template>
                    </q-carousel>
                </div>

                <q-footer class="bg-white text-right q-pb-md aj-container">
                    <q-btn label="Cancel" size="md" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" padding="sm md"
                           unelevated @click="closeDialog"/>
                    <q-btn label="Save Changes" size="md" color="primary" class="text-capitalize" padding="sm md" unelevated
                           @click="handleSubmit" :loading="formLoading"/>
                </q-footer>
            </q-card-section>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from "moment";
import {reactive, ref} from 'vue';
import {useQuery, useMutation} from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {CREATE_UPDATE_DC_CMS} from "../../graphql/mutations/dc";
import {GET_THEME_BY_CUSTOMER_ID} from "../../graphql/queries/dc";
import {GET_THEMES} from "../../graphql/queries/themes";

let vForm = {themeId: '', colorId: '', themeCode:'', colorCode:''};
export default {
    name: "ThemeForm",
    props: ['dialog'],
    emits: ['update:dialog'],
    setup(props) {
        const customerId = ref(JSON.parse(localStorage.user).id);

        const slides = ref([]);

        const form = reactive({...vForm});
        const {onResult} = useQuery(GET_THEME_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog, fetchPolicy: 'network-only'}));
        onResult(r => {
            if (r.data.dc_cms[0]?.theme) Object.copyExistingKeys(form, r.data.dc_cms[0].theme);
            enableQuery();
        });

        const enabled = ref(false);
        function enableQuery() { enabled.value = true }

        const {onResult: themeResult} = useQuery(GET_THEMES, undefined,
            () => ({enabled: props.dialog && enabled.value, fetchPolicy: 'network-only'}));
        themeResult(r => {
            enabled.value = false;
            slides.value = [];
            let themes = JSON.parse(JSON.stringify(r.data.dc_card_themes));
            themes.forEach(t => { t['defaultColor'] = t.color_themes.find(c => c.is_default) });
            for (let i = 0; i < themes.length; i += 6)
                slides.value.push(themes.slice(i, i + 6));
            console.log('slides', slides);
            if(!form.themeId){                                                    /* Assign default values */
                let defaultTheme = themes.find(t => t.is_default);
                let defaultColor = defaultTheme.color_themes.find(c => c.is_default);
                Object.assign(form, {themeId:defaultTheme.id, themeCode:defaultTheme.code, colorId:defaultColor.id, colorCode:defaultColor.code});
            }else{
                let defaultColor = themes.find(t => t.id === form.themeId).defaultColor;
                form.colorId = defaultColor.id;
                form.colorCode = defaultColor.code;
            }
        });

        const {mutate: mDcCms} = useMutation(CREATE_UPDATE_DC_CMS);
        return {customerId, form, mDcCms, slides};
    },
    data() {
        return {
            Constants, moment,
            formLoading: false,
            slide: 0
        }
    },
    methods: {
        onSelectTheme(theme) {
            let defaultColor = theme.color_themes.find(c => c.is_default);
            Object.assign(this.form, {themeId:theme.id, themeCode:theme.code, colorId:defaultColor.id, colorCode:defaultColor.code});
        },
        handleSubmit() {
            this.saveTheme();
        },
        async saveTheme() {
            this.formLoading = true;
            await mutation({
                mutate: this.mDcCms,
                variables: {
                    dc_cms: {
                        customer_id: this.customerId, theme: {...this.form},
                        created_by: this.customerId, updated_by: this.customerId
                    },
                    updateColumns: ['theme', 'updated_by'],
                }
            });
            this.formLoading = false;
            this.$q.notify('Theme saved successfully');
            this.closeDialog();
        },
        resetForm() {
            Object.copyExistingKeys(this.form, {...vForm});
        },
        closeDialog() {
            this.resetForm();
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>
.color-box {
    cursor: pointer;
}

.text-primary {
    color: #00ABEC !important;
}

.active-box {
    border: 3px solid #00ABEC;
    height: 120px !important;
}
.card-text{
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
</style>
