// Mixins
/**
 * country-code for mobile
 * @mixin
 *
 * Used to add search country-code functionality to components
 */
import {Countries} from "../../constants/Countries";
export const countryCode = {
    data(){
        return {
            Countries
        }
    },
    methods:{
        filterCountryCode(val, update) {
            if (!val) return update(() => {this.Countries = Countries});

            update(() => {
                const needle = val.toLowerCase();
                this.Countries = Countries.filter(v => v.name.toLowerCase().indexOf(needle) > -1)
            })
        }
    }

};