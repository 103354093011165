<template>
<div>

  <q-dialog persistent v-model="dialog">
    <q-card>
      <q-form ref="qForm">
       <div class="row q-pa-md">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <h4 class="mt-0" style="font-size: 26px; font-weight: 500;">Set WhatsApp Message Text</h4>
            <span class="d-block">
                  <q-btn @click="closeDialog" class="q-ml-auto" text-color="red-6" color="red-1"
                         icon="close" round size="small"/>
            </span>
          </div>
        </div>

        <div class="col-12">
          <q-input v-model="form.wa_message" class="searchBox p-2 my-3" label="Default WhatsApp Message*"
                   :rules="[v => !!v || 'Please enter WhatsApp Message']" filled counter type="textarea"
                    maxlength="700"/>
        </div>
       </div>
      </q-form>
      <q-card-actions align="right" class="mr-2">
        <q-btn @click="closeDialog" size="md">Cancel</q-btn>
        <q-btn @click="handleSubmit" size="md" class="pd-btn" color="primary" :loading="formLoading">Submit</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

</div>
</template>

<script>
import {reactive, ref} from "vue";
import {useMutation, useQuery} from "@vue/apollo-composable";
import {GET_WA_MESSAGE_BY_CUSTOMER_ID} from "../../graphql/queries/dc";
import {CREATE_UPDATE_DC_CMS} from "../../graphql/mutations/dc";
import mutation from "../../composables/mutation";

let vForm = {wa_message:''};
export default {
  name: "WaMessageForm",
  props: ['dialog'],
  emits: ['update:dialog'],
  setup (props) {
    const customerId = ref(JSON.parse(localStorage.user).id);

    const form = reactive({...vForm});
    const {onResult} = useQuery(GET_WA_MESSAGE_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
        () => ({enabled: !!customerId.value && props.dialog, fetchPolicy:'network-only'}));
    onResult(r => {
      if(r.data.dc_cms[0]?.wa_message) form.wa_message = r.data.dc_cms[0].wa_message;
    });

    const { mutate: mDcCms } = useMutation(CREATE_UPDATE_DC_CMS);
    return { customerId, form, mDcCms};
  },
  data(){
    return{
      formLoading: false
    }
  },
  methods:{
    handleSubmit(){
      this.$refs['qForm'].validate().then(success => {
        if(success) this.saveWaMessage();
      })
    },
    async saveWaMessage(){
      this.formLoading = true;
      await mutation({
        mutate: this.mDcCms,
        variables:{
          dc_cms: {
            customer_id: this.customerId, wa_message: this.form.wa_message,
            created_by: this.customerId, updated_by: this.customerId
          },
          updateColumns: ['wa_message', 'updated_by'],
        }
      });
      this.formLoading = false;
      this.$q.notify('Default Whatsapp Message saved successfully');
      this.closeDialog();
    },
    resetForm(){
      this.form.wa_message = '';
    },
    closeDialog(){
      this.$refs['qForm'].resetValidation();
      this.resetForm();
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>

</style>