<template>
  <div>
    <q-breadcrumbs gutter="xs">
      <q-breadcrumbs-el label="Dashboard" to="/cardBuilderDashboard"/>
      <q-breadcrumbs-el label="Orders" />
    </q-breadcrumbs>
   <!-- <div class="row justify-between q-mt-sm">
      <div class="row q-mt-md items-center">
        <q-btn icon="mdi-arrow-left" to="/cardBuilderDashboard" outline text-color="grey-8" color="grey-1" round></q-btn>
        <h4 class="text-h6 q-my-none q-ml-md" style="font-weight: 600">{{orders.length}} Orders</h4>
      </div>
      <div class="row">
        <q-input v-model="dateFormatted" outlined disabled>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                <q-date v-model="date" range @update:model-value="onChangeDate">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input outlined class="q-ml-md" style="width: 300px" label="Search" debounce="500" v-model="search"/>
      </div>
    </div> -->
    <div class="row q-mt-md">
      <div class="col-sm-4 col-xs-12 text-h6 q-mb-xs-md" style="font-weight: 600">
        <q-btn icon="mdi-arrow-left" to="/cardBuilderDashboard" outline text-color="grey-8" color="grey-1" round/>
        <span class="q-ml-md">{{orders.length}} Orders</span>
      </div><q-space/>
      <div class="col-sm-auto col-xs-12">
        <q-input v-model="dateFormatted" outlined disabled class="col-auto date-field">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                <q-date v-model="date" range @update:model-value="onChangeDate">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-sm-auto col-xs-12 q-ml-sm-md">
        <q-input outlined class="col-auto search-field" label="Search" debounce="500" v-model="search"/>
      </div>
    </div>

    <q-table class="q-mt-lg" :rows="orders" :columns="columns" row-key="id" flat :filter="search"
             :rows-per-page-options="[25, 50, 100, 0]">
     <!-- <template v-slot:body-cell-subject="props"><q-td :props="props">
        <div class="subject-text">{{props.row.subject || '-'}}</div>
      </q-td></template> -->
      <template v-slot:body-cell-address="props"><q-td :props="props">
        <div class="remark-text">
          {{props.row.full_address || '-'}}
          <q-tooltip>{{props.row.full_address || '-'}}</q-tooltip>
        </div>
      </q-td></template>
      <template v-slot:body-cell-remark="props"><q-td :props="props">
        <div class="remark-text">
          {{props.row.remark || '-'}}
          <q-tooltip>{{props.row.remark || '-'}}</q-tooltip>
        </div>
      </q-td></template>
    </q-table>
  </div>
</template>

<script>
import Constants, {getAddressString} from "../../constants/Constants";
import moment from 'moment';
import { ref } from "vue";
import {useSubscription} from "@vue/apollo-composable";
import {SUBSCRIBE_ORDERS} from "../../graphql/subscriptions/orders";
import {NetworkCommunicator} from "../../plugins/NetworkResourceHandler";
export default {
  name: "Orders",
  setup () {
    const customerId = ref(JSON.parse(localStorage.user).id);
   /* const orders = ref([]);
    const search = ref('%%');
    const date = ref({ from: moment().format('YYYY/MM/DD'), to: moment().format('YYYY/MM/DD') });
    const {onResult } = useSubscription(SUBSCRIBE_ORDERS, () => ({
      search: search.value, customerId:customerId.value,
      startDate: moment(date.value?.from).startOf('day'),
      endDate: moment(date.value?.to).endOf('day')
    }));
    onResult(r => orders.value = r.data.orders); */

    return {customerId};
  },
  data(){
    return{
      Constants, moment,
      orders: [],
      search: '',
      date: {from: moment().subtract(180, 'days').format('YYYY/MM/DD'), to:moment().format('YYYY/MM/DD')},
      columns: [
        { name: 'name', label: 'Name', field:'name', align:'left', sortable: true },
        { name: 'mobile', label: 'Mobile', field:'mobile', align:'left', sortable: true },
        { name: 'email', label: 'Email', field:'email', align:'left', sortable: true },
        { name: 'gst', label: 'GST', field: r => r.gst||'-', align:'left', sortable: true },
        // { name: 'address', label: 'Address', field: r => getAddressString(r.address), align:'left', sortable: true },
        { name: 'address', label: 'Address', field: r => r.full_address || '-', align:'left', sortable: true },
       /* { name: 'subject', label: 'Subject', field:'subject', align:'left', sortable: true, classes:'subject-col' }, */
        { name: 'remark', label: 'Remark', field:'remark', align:'left', sortable: true, classes:'remark-col' },
        { name: 'product', label: 'Product', field: r => r.product?.name||'-', align:'left', sortable: true },
        { name: 'quantity', label: 'Qty', field:'quantity', align:'left', sortable: true },
        { name: 'created_at', label: 'Order Date', field: r => moment(r.created_at).format('DD MMM YYYY hh:mm A'), sortable: true },
      ],
    }
  },
  computed:{
    dateFormatted:{
      get() {
        if(typeof this.date === 'object') return moment(this.date?.from).format('DD/MM/YYYY') + ' - ' + moment(this.date?.to).format('DD/MM/YYYY');
        if(typeof this.date === 'string') return moment(this.date).format('DD/MM/YYYY') + ' - ' + moment(this.date).format('DD/MM/YYYY');
      },
      set(val) {}
    }
  },
  methods:{
    onChangeDate(){
      if(!this.date) return;
      if(typeof this.date === 'object') this.getOrders(this.date.from, this.date.to);
      else if(typeof this.date === 'string') this.getOrders(this.date, this.date);
    },
    getOrders(from, to){
      let request = {
        'customerId': this.customerId,
        'startDate': moment(from).format('YYYY-MM-DD'),
        'endDate': moment(to).format('YYYY-MM-DD')
      };
      NetworkCommunicator('POST', `${Constants.api_url}getOrderData`, false, request).then(response => {
        this.orders = [];
        if(response.status) {
          this.orders = response.data;
        } else this.$q.notify(response.message);
      }).catch(function (err) {
        console.log('getOrders Error', err);
      })
    }
  },
  created() {
    this.getOrders(this.date.from, this.date.to)
  }
}
</script>
<style>
.subject-text{
  white-space: normal;
  max-width: 200px !important;
  width: max-content;
}
.remark-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px !important;
  width: max-content;
}
</style>
<style lang="sass">
.q-table__bottom--nodata
  margin: auto
.my-sticky-header-table
  /* height or max-height is important */
  height: calc(100vh - 220px)

  .q-table__top,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: #F6F6F6

  thead tr th
    position: sticky
    z-index: 1
    font-size: 14px
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
