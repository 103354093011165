<template>
  <div>

    <q-dialog v-model="dialog" persistent>
      <q-card style="max-width: 700px">
        <q-card-section>
          <div class="text-h5">Introduction to ByteCard Builder</div>
          <p>We will guide you how your ByteCard will be build</p>
        </q-card-section>
        <q-separator/>
        <q-card-section class="q-pa-lg">
          <q-timeline color="secondary">
            <q-timeline-entry>
              <div class="f-18">Select your <b>Theme and Color</b>, this will be used for your Bytecard Design.</div>
            </q-timeline-entry>
            <q-timeline-entry>
              <div class="f-18">Add the <b>Business data</b> in each of the widget. Click on
                <q-icon name="mdi-information-outline"></q-icon> of widget to know more.</div>
            </q-timeline-entry>
            <q-timeline-entry>
              <div class="f-18">You can also <b>Check Preview</b> to know how your customer can see your ByteCard.</div>
            </q-timeline-entry>
            <q-timeline-entry>
              <div class="f-18">Data added? Checked preview? Click on <b>'Make it Live'</b> to generate your ByteCard URL.</div>
            </q-timeline-entry>
          </q-timeline>
        </q-card-section>
        <q-separator/>
        <q-card-actions align="right" class="text-primary q-py-md q-mr-sm">
          <q-btn text-color="blue-6" color="blue-1" label="Go" @click="closeDialog"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
export default {
  name: "BytecardIntroduction",
  props: ['dialog'],
  emits: ['update:dialog'],
  methods: {
    closeDialog() {
      this.$emit('update:dialog', false);
      this.$emit('openDomainDialog', true);
    }
  }
}
</script>

<style scoped>
.f-18{
  font-size: 18px;
  word-spacing: 2px;
}
</style>