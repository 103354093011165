<template>
  <div>

    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
      <q-card>
        <q-card-section class="aj-container row justify-between items-center">
          <div>
            <div class="text-h5 text-weight-medium">Company Logo, Tagline & Services</div>
          </div>
          <div>
            <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog" v-if="showCancelButton">
              <q-tooltip content-class="text-primary">Close</q-tooltip>
            </q-btn>
          </div>

        </q-card-section>
        <q-card-section class="q-mt-lg aj-container">
          <q-form ref="qForm">
            <div class="row q-col-gutter-md" style="margin-bottom: 100px;">


              <div class="col-12 col-sm-12 col-md-3">
                <h3 class="text-subtitle1 text-weight-medium">Company Logo</h3>
              </div>
              <div class="col-12 col-sm-12 col-md-9 q-mb-xl">
                <div class="row q-col-gutter-md">
                  <div class="col-12">
                    <div class="cursor-pointer d-flex" @click="uploadFileDialog=true">
                      <div v-if="form.logo" style="position: relative">
                        <img :src="imgWpx(form.logo, 400)" class="aj-avatar-upload" @error="form.logo = ''">
                        <q-btn round @click.stop="removeImage()" icon="cancel" text-color="red" size="md"
                               style="position: absolute; left: 35px; top: -10px" title="Remove Image"/>
                      </div>
                      <div v-else class="aj-avatar-upload bg-grey-3 row justify-center bg-blue-1 items-center">
                        <span class="mdi mdi-cloud-upload-outline text-h6 text-primary"></span>
                        <!--                                <q-btn round icon="mdi-cloud-upload-outline" unelevated text-color="primary" color="blue-1"/>-->
                      </div>
                      <div class="q-ml-md" style="margin-top: 22px">
                       <!-- <h6 class="text-body2 text-weight-medium q-mt-sm">Company Logo</h6> -->
                        <span class="text-caption text-grey-8">Image size should be 512px x 512px, File type: PNG or JPG (File size: Max 1MB)</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3">
                <h3 class="text-subtitle1 text-weight-medium">Company Tagline</h3>
              </div>
              <div class="col-12 col-sm-12 col-md-9 q-mb-xl">
                <div class="row q-col-gutter-md">
                  <div class="col-12">
                    <q-input class="q-pb-none" square label="Company Tagline" v-model="form.slogan"
                             outlined maxlength="70"/>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3">
                <h3 class="text-subtitle1 text-weight-medium">
                  <template v-if="editTitle">
                    <input v-model="tempServiceTitle" class="inline" id="inline-input" maxlength="25"/>
                    <q-btn flat round color="grey-8" icon="mdi-check" size="sm" @click="onClickTitleSave"
                           :disable="!tempServiceTitle.trim()"/>
                    <q-btn flat round color="grey-8" icon="mdi-close" size="sm"  @click="onClickTitleCancel"/>
                  </template>
                  <template v-else>{{form.service_title}}
                    <q-btn flat round color="grey-8" icon="mdi-pencil-outline" size="sm"  @click="onClickTitleEdit"/>
                  </template>
                </h3>
                <img src="../../assets/images/business-services.jpg" alt="business-services.jpg" style="width: 80%; border: 1px solid lightgrey; border-radius: 13px" class="q-mt-md">
                <p class="text-caption text-gray">Example Image</p>
              </div>
              <div class="col-12 col-sm-12 col-md-9  q-mb-xl">
                <p class="text-caption text-gray">If you will add your service, we will show in your ByteCard (Backside) Image else ByteCard (Backside) will be blank with logo. For example, you can check Example Image</p>
                <div class="col-6">
                  <template v-for="(service, idx) in form.services">
                    <div class="row q-col-gutter-xs q-mb-lg">
                      <q-input class="q-pb-none" square label="Services" v-model="form.services[idx]" outlined
                               :class="idx!==0? 'col-11' :'col-12'" maxlength="30"/>
                      <div class="col-1 text-right">
                        <q-btn color="negative" round dense flat icon="close" class="mt-3"
                               @click="removeService(idx)" v-if="idx!==0"/>
                      </div>
                    </div>
                    <q-btn text-color="primary" color="blue-1" @click="addService"
                           v-if="form.services.length === idx+1 && form.services.length<14" icon="mdi-plus" label="Add New"/>
                  </template>
                </div>
              </div>






              <!--                    <div class="col-12  row justify-center">-->
              <!--                        <div class="q-mb-lg cursor-pointer" @click="uploadFileDialog=true">-->
              <!--                            <div v-if="form.logo" style="position: relative" class="row justify-center">-->
              <!--                                <img :src="imgWpx(form.logo, 400)" class="aj-avatar-upload" @error="form.logo = ''">-->
              <!--                                <q-btn round @click.stop="removeImage()" icon="cancel" text-color="red" size="md" style="position: absolute; right: 155px" title="Remove Image"/>-->
              <!--                            </div>-->
              <!--                            <div v-else class="aj-avatar-upload bg-grey-3 row justify-center bg-blue-1 items-center" style="margin: 0 auto">-->
              <!--                                <span class="mdi mdi-cloud-upload-outline text-h3 text-primary"></span>-->
              <!--&lt;!&ndash;                                <q-btn round icon="mdi-cloud-upload-outline" unelevated text-color="primary" color="blue-1"/>&ndash;&gt;-->
              <!--                            </div>-->
              <!--                            <div class="text-center">-->
              <!--                              <h6 class="text-body2 text-grey-8 q-mt-sm">Company Logo<br><span class="text-caption">Image size should be 512px x 512px, File type: PNG or JPG (File size: Max 1MB)</span></h6>-->
              <!--                            </div>-->
              <!--                        </div>-->
              <!--                    </div>-->


            </div>
          </q-form>
        </q-card-section>
        <q-footer class="bg-white" style="box-shadow: 0px -2px 10px 0px rgba(0,0,0,0.13)">
          <div class="aj-container text-right q-py-sm">
            <q-btn label="Cancel" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize" size="md" @click="closeDialog"
                   v-if="showCancelButton"/>
            <q-btn label="Save Changes" color="primary" class="text-capitalize" size="md" unelevated @click="handleSubmit"
                   :loading="formLoading"/>
          </div>
        </q-footer>
      </q-card>
    </q-dialog>

    <UploadFile v-model:dialog="uploadFileDialog" @fileResponse="form.logo=$event" fileTypes=".jpg, .jpeg, .png"/>
  </div>
</template>

<script>
import moment from 'moment';
import Constants from "../../constants/Constants";
import {reactive, ref} from 'vue';
import {useQuery, useMutation} from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {GET_ORGANIZATION_BACK_DETAILS_BY_ID} from "../../graphql/queries/organizations";
import {CREATE_UPDATE_CUSTOMER} from "../../graphql/mutations/organizations";
import UploadFile from "../Upload/UploadFile";

export default {
  name: "BizDetailBackForm",
  components: {UploadFile},
  props: ['dialog'],
  emits: ['update:dialog'],
  setup(props) {
    const customerId = ref(JSON.parse(localStorage.user).id);

    const form = reactive({id: '', logo: '', slogan:null, services: [''], service_title: 'Our Services', email:''});
    const {onResult} = useQuery(GET_ORGANIZATION_BACK_DETAILS_BY_ID, () => ({id: customerId.value}),
        () => ({enabled: !!customerId.value && props.dialog, fetchPolicy: 'network-only'}));
    onResult(r => {
      Object.copyExistingKeys(form, JSON.parse(JSON.stringify(r.data.organizations[0])));
      form.services = form.services&&form.services.length?form.services:[''];
    });

    const {mutate: mSaveBusinessDetail} = useMutation(CREATE_UPDATE_CUSTOMER);

    return {form, mSaveBusinessDetail};
  },
  data() {
    return {
      Constants, moment,
      formLoading: false,
      showCancelButton: false,
      uploadFileDialog: false,
      tempServiceTitle: '',
      editTitle: false
    }
  },
  watch: {
    'dialog': function () {
      this.showCancelButton = !!localStorage.isBusinessDetailCompleted
    }
  },
  methods: {
    addService(){
      this.form.services.push('');
    },
    removeService(index){
      this.form.services.splice(index, 1);
    },
    onClickTitleEdit(){
      this.tempServiceTitle = this.form.service_title;
      this.editTitle = true;
    },
    onClickTitleCancel(){
      this.editTitle = false;
    },
    onClickTitleSave() {
      this.form.service_title = this.tempServiceTitle;
      this.editTitle = false;
    },
    removeImage() {
      this.$q.dialog({
        title: 'Confirm', cancel: true, persistent: true,
        message: `Are you sure you want to remove Company Logo ?`,
      }).onOk(() => this.form.logo = '')
    },
    handleSubmit() {
      this.$refs['qForm'].validate().then(success => {
        if (success) {
          this.saveBusinessDetail();
        }
      })
    },
    async saveBusinessDetail() {
      let services = this.form.services.filter(s => !!s);
      this.formLoading = true;
      await mutation({
        mutate: this.mSaveBusinessDetail,
        variables: {
          customer: {...this.form, services},
          updateColumns: [...Object.keys(this.form)],
        }
      });
      this.formLoading = false;
      this.$q.notify('Company Logo, Slogan & Services saved successfully');
      this.closeDialog();
    },
    resetForm() {
      this.form.slogan = null;
      this.form.services = [''];
      this.form.service_title = 'Our Services';
      this.editTitle = false;
      this.form.logo = '';
      this.form.email = '';
    },
    closeDialog() {
      this.$refs['qForm'].resetValidation();
      this.resetForm();
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>
.pb-20 {
  padding-bottom: 20px;
}

/deep/ .q-field__native {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.side-text {
  font-size: 20px;
  color: black;
}
</style>
