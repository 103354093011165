<template>
  <div>
    <q-dialog v-model="dialog" persistent maximized transition-show="slide-up" transition-hide="slide-down">
      <q-card>
        <q-card-section class="aj-container row justify-between items-center">
          <div class="col-10">
            <div class="text-h5 text-weight-medium">
              <template v-if="editTitle">
                <input v-model="tempWidgetTitle" class="inline" id="inline-input"/>
                <q-btn flat round color="grey-8" icon="mdi-check" size="sm" @click="onClickTitleSave"
                       :loading="saveTitleLoading" :disable="!tempWidgetTitle.trim()"/>
                <q-btn flat round color="grey-8" icon="mdi-close" size="sm" @click="onClickTitleCancel"/>
              </template>
              <template v-else>{{ widgetTitle }}
                <q-btn flat round color="grey-8" icon="mdi-pencil-outline" size="sm" @click="onClickTitleEdit"/>
              </template>

            </div>
          </div>
          <div class="col-2 text-right">
            <q-btn round text-color="red-6" color="red-1" icon="close" @click="closeDialog">
              <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
            </q-btn>
          </div>
        </q-card-section>

        <q-card-section class="aj-container q-pt-none">
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-carousel v-if="branches.length" v-model="slide" transition-prev="slide-right"
                          transition-next="slide-left"
                          animated control-color="primary" navigation padding arrows>
                <template v-for="(s, sIndex) in slides">
                  <q-carousel-slide :name="sIndex" class="column no-wrap">
                    <div class="row fit justify-start items-center q-gutter-xs q-col-gutter-lg no-wrap">
                      <div class="col-sm-12 col-md-6" v-for="(b, bIndex) in s" style="height: 100%">
                        <q-card class="my-card cursor-pointer list-item" style="min-height: 250px" square flat bordered
                                @click="onEditBranch(b)">
                          <q-card-section class="q-pb-none">
                            <div class="text-h6 q-mt-sm q-mb-xs">{{ b.name }}</div>
                            <q-btn @click.stop="setBranchAsDefault(b)" icon="stars"
                                   :text-color="b.is_default?'orange':'grey'"
                                   style="position: absolute;right: 8px;top: 22px" title="Set Branch as Default"/>
                            <div class="text-body1 text-grey-8" style="word-break: break-all">
                              {{ getAddressString(b.address) }}
                            </div>
                            <div class="row q-mt-lg">
                              <span class="mdi text-body1 mdi-phone-outline"></span>
                              <p class="q-ml-md text-body1 text-grey-8 q-mb-sm text-weight-medium">
                                <template v-if="b.phones.length">
                                  {{
                                    typeof b.phones[0] === 'string' ? b.phones[0] : (b.phones[0].country_code + ' ' + b.phones[0].phone)
                                  }}
                                </template>
                                <template v-else>-</template>
                              </p>
                            </div>
                            <div class="row q-mb-xl">
                              <span class="mdi text-body1 mdi-at"></span>
                              <p class="q-ml-md text-body1 text-grey-8 q-mb-sm text-weight-medium">
                                {{ b.emails.length ? b.emails[0] : '-' }}</p>
                            </div>
                          </q-card-section>
                          <q-card-actions class="absolute-bottom">
                            <q-toggle @click.stop="onChangeBranchStatus(b)" v-model="b.is_active" v-if="!b.is_default"/>
                            <q-space/>
                            <q-btn color="red" round flat dense class="list-delete-icon" icon="mdi-delete-outline"
                                   @click.stop="onDeleteBranch(b)" v-if="!b.is_default"/>
                            <q-btn round dense icon="mdi-pencil" @click.stop="onEditBranch(b)" title="Edit branch" :class="b.is_default?'mb-2':''"/>
                          </q-card-actions>
                        </q-card>
                      </div>
                    </div>
                  </q-carousel-slide>
                </template>
              </q-carousel>
              <div class="text-center q-mt-md">
                <q-btn v-if="branches.length" label="Add New" icon="mdi-plus" size="md" color="primary"
                       class="text-capitalize" padding="sm md"
                       unelevated @click="onAddBranch"/>
              </div>
            </div>
            <div class="col-12">
              <q-card v-show="showBranchForm" square flat bordered>
                <q-card-section class="aj-container row justify-between items-center q-px-lg">
                  <div>
                    <div class="text-h6 text-weight-medium">{{ form.id ? 'Edit' : 'Add' }} Branch</div>
                  </div>
                  <div>
                    <q-btn round text-color="red-6" color="red-1" icon="close" @click="showBranchForm=false">
                      <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                  </div>
                </q-card-section>
                <q-form ref="qForm">
                  <div class="row q-col-gutter-lg q-pa-lg">
                   <!-- <div class="col-auto">
                      <q-input square outlined v-model="form.lat" dense label="Latitude" type="number" style="display: inline-flex" hide-bottom-space hide-hint>
                        <template v-slot:before><div class="side-text">https://www.google.com/maps?q=</div></template>
                        <template v-slot:after>/<q-input class="col-xs-12" square outlined v-model="form.long" dense label="Longitude" type="number" hide-bottom-space hide-hint/></template>
                      </q-input>
                      <q-btn label="Get Location" color="primary" class="ml-4" style="margin-top: -8px" :disable="!form.lat || !form.long" @click="generateMap(form.lat, form.long)"></q-btn>
                    </div> -->
                    <div class="col-auto" style="font-size: 20px;">https://www.google.com/maps?q=</div>
                    <div class="col-auto"><q-input square outlined v-model="form.lat" dense label="Latitude" type="number" hide-bottom-space hide-hint/></div>
                    <div class="col-auto"><q-input square outlined v-model="form.long" dense label="Longitude" type="number" hide-bottom-space hide-hint/></div>
                    <div class="col-auto"><q-btn label="Get Location" color="primary" style="margin-top: 4px" :disable="!form.lat || !form.long" @click="generateMap(form.lat, form.long)"/></div>
                    <div class="col-12 google-url-desc">Enter your google map url with latitude & longitude as https://www.google.com/maps?q=<b>latitude</b>/<b>longitude</b></div>
                    <div class="col-12">
                      <div class="aj-map-preview" id="google_Map" style="height: 350px">
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.8939505045514!2d72.8257048508616!3d21.196371385838603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04e608210ca99%3A0x8beece190d205931!2sSurti%20Locho!5e0!3m2!1sen!2sin!4v1623734130173!5m2!1sen!2sin"
                                 width="100%" style="border:0;" allowfullscreen="" loading="lazy"/> -->
                      </div>
                    </div>
                    <div class="col-12">
                      <q-input class="q-pb-none" square outlined v-model="form.name" label="Branch Name*" maxlength="50"
                               :rules="[v => !!v || 'Please enter Branch Name']" lazy-rules/>
                    </div>
                    <div class="col-6">
                      <q-input class="q-pb-none" square outlined v-model="form.address.addressLine1"
                               label="Address Line 1*"
                               :rules="[v => !!v || 'Please enter Address Line 1']" lazy-rules/>
                    </div>
                    <div class="col-6">
                      <q-input class="q-pb-none" square outlined v-model="form.address.addressLine2"
                               label="Address Line 2"/>
                    </div>
                    <div class="col-12">
                      <q-input class="q-pb-none" square outlined v-model="form.address.area" label="Area"/>
                    </div>

                    <div class="col-6">
                      <q-select class="q-pb-none" square label="City/Dist" v-model="form.address.city"
                                outlined debounce="400" use-input clearable :options="cityArr" option-label="city"
                                @filter="searchCity" :loading="cityLoading" @update:model-value="autoFillStateAndCountry">
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">No results</q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>
                    <div class="col-6">
                      <q-input class="q-pb-none" square outlined v-model="form.address.pincode" label="Pincode"/>
                    </div>

                    <div class="col-6">
                      <q-select class="q-pb-none" square label="State" v-model="form.address.state"
                                outlined
                                debounce="500" use-input clearable :options="stateArr" option-label="state"
                                @filter="searchState"
                                :loading="stateLoading" @update:model-value="autoFillCountry">
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">No results</q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>
                    <div class="col-6">
                      <q-select class="q-pb-none" square label="Country" v-model="form.address.country"
                                outlined debounce="500" clearable :options="countryArr" option-label="name"
                                emit-value :loading="countryLoading" @update:model-value="autoFillDialCode">
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">No results</q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>

                    <div class="col-6">
                      <template v-for="(phn, idx) in form.phones">
                        <div class="row q-col-gutter-xs">
                          <q-select class="q-mb-xs col-2" square outlined :options="Countries"
                                    v-model="form.phones[idx]['country_code']"
                                    option-value="dialCode" emit-value hide-hint hide-bottom-space
                                    :rules="[v => !!v || 'Please enter Country Code']">
                            <template v-slot:option="scope">
                              <q-item v-bind="scope.itemProps">
                                <q-item-section>
                                  <q-item-label>{{ scope.opt.name }} ({{ scope.opt.dialCode }})</q-item-label>
                                </q-item-section>
                              </q-item>
                            </template>
                          </q-select>
                          <q-input class="q-mb-md" :class="idx!==0? 'col-9' :'col-10'" square outlined
                                   v-model="form.phones[idx]['phone']"
                                   label="Phone*" hide-bottom-space hide-hint
                                   :rules="[v => !!v || 'Phone required',v => v.length === 10 || 'Invalid Phone']"
                                   mask="##########"/>
                          <div class="col-1">
                            <q-btn color="negative" round dense flat class="mt-3" icon="close"
                                   @click="removePhone(idx)" v-if="idx!==0"/>
                          </div>
                        </div>
                        <q-btn text-color="primary" color="blue-1" @click="addNewPhone" v-if="form.phones.length === idx+1" icon="mdi-plus" label="Add New" />
                      </template>
                    </div>

                    <div class="col-6">
                      <template v-for="(eml, idx) in form.emails">
                        <div class="row q-col-gutter-xs">
                          <q-input square outlined v-model="form.emails[idx]" label="Email*" hide-bottom-space
                                   hide-hint
                                   :rules="[v => !!v || 'Email required', v => Constants.EmailPattern.test(v) || 'Invalid Email']"
                                   class="q-mb-md" :class="idx!==0? 'col-11' :'col-12'"/>
                          <div class="col-1">
                            <q-btn color="negative" round dense flat icon="close" class="mt-3"
                                   @click="removeEmail(idx)" v-if="idx!==0"/>
                          </div>
                        </div>
                        <q-btn text-color="primary" color="blue-1" @click="addNewEmail" v-if="form.emails.length === idx+1" icon="mdi-plus" label="Add New" />
                      </template>
                    </div>


                    <div class="col-12">
                      <h6 class="text-subtitle1 " style="font-weight: 600">Working Hours</h6>
                      <span class="text-body2 text-grey-8">Provide your working hours details</span>
                      <form class="q-mt-md" autocorrect="off" autocapitalize="off" autocomplete="off"
                            spellcheck="false">
                        <q-editor v-model="form.working_hours" :toolbar="Constants.editorOptions($q)"
                                  :fonts="Constants.fonts" min-height="450px" dense/>
                      </form>
                    </div>
                    <div class="col-12">
                      <div class="bg-white text-right q-pb-md aj-container">
                        <q-btn label="Cancel" size="md" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize"
                               padding="sm md" unelevated @click="closeDialog"/>
                        <q-btn label="Save Changes" size="md" color="primary" class="text-capitalize" padding="sm md"
                               unelevated @click="handleSubmit" :loading="formLoading"/>
                      </div>
                    </div>
                  </div>
                </q-form>
              </q-card>


              <q-card v-show="!showBranchForm" square flat bordered>
                <q-card-section class="text-center" style="height: 50vh;line-height: calc(50vh + 64px)">
                  <div style="line-height: 2;display: inline-block;">
                    <q-avatar class="bg-blue-grey-1 q-mb-md" size="100px" text-color="primary" icon="local_offer"/>
                    <div v-if="branches.length">Please select any branch to make changes.</div>
                    <div v-else>
                      <p>No Branch added yet. Click on Add New button to add branch.</p>
                      <q-btn label="Add New" icon="mdi-plus" size="md" color="primary" class="text-capitalize"
                             padding="sm md"
                             unelevated @click="onAddBranch"/>
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import Constants from "../../constants/Constants";
import {getAddressString} from "../../constants/Constants";
import moment from "moment";
import {reactive, ref, toRefs} from 'vue';
import {useSubscription, useMutation} from '@vue/apollo-composable';
import mutation from "../../composables/mutation";
import {CREATE_UPDATE_DC_BRANCH} from "../../graphql/mutations/dc";
import {SUBSCRIBE_DC_BRANCHES_BY_CUSTOMER_ID} from "../../graphql/subscriptions/organizations";
import {generate_DELETE_RECORD, generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
import updateRecord from "../../composables/updateRecord";
import deleteRecord from "../../composables/deleteRecord";
import useWidget from "../../composables/widgetTitle";
import useSearchCity from "../../composables/searchCity";
import useSearchState from "../../composables/searchState";
import useSearchCountry from "../../composables/searchCountry";
import {countryCode} from '../../mixins/countryCode';

let vForm = {
  name: '',
  iframe_code: null,
  lat: null,
  long: null,
  working_hours: Constants.DefaultWorkingHours,
  working_hours_title: 'Working Hours'
};
let address = {addressLine1: '', addressLine2: '', area: '', city: '', state: '', country: '', pincode: ''};
export default {
  name: "BranchForm",
  mixins: [countryCode],
  props: ['dialog'],
  emits: ['update:dialog'],
  setup(props) {
    const {dialog} = toRefs(props);
    const customerId = ref(JSON.parse(localStorage.user).id);
    const form = reactive({...vForm, phones: [], emails: [], address: {...address}});

    const branches = ref([]), slides = ref([]), slide = ref(0);
    const {onResult} = useSubscription(SUBSCRIBE_DC_BRANCHES_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
        () => ({enabled: !!customerId.value && props.dialog}));
    onResult(r => {
      branches.value = r.data.branches;
      let responsive = window.innerWidth < 991;
      slides.value = [];
      for (let i = 0; i < branches.value.length; i += (responsive?1:2))
        slides.value.push(branches.value.slice(i, i + (responsive?1:2)));
      if (slide.value > (slides.value.length - 1)) slide.value = 0;
    });

    const {
      widgetTitle, tempWidgetTitle, editTitle, saveTitleLoading,
      onClickTitleEdit, onClickTitleSave, onClickTitleCancel
    } = useWidget('branches', dialog, customerId);
    const {cityArr, searchCity, cityLoading} = useSearchCity();
    const {stateArr, searchState, stateLoading} = useSearchState();
    const {countryArr, searchCountry, countryLoading} = useSearchCountry();
    const {mutate: mBranch} = useMutation(CREATE_UPDATE_DC_BRANCH);
    const {mutate: mUpdateBranch} = useMutation(generate_UPDATE_RECORD('dc_customer_branches'));
    const {mutate: mDeleteBranch} = useMutation(generate_DELETE_RECORD('dc_customer_branches'));
    return {
      customerId, form, mBranch, branches, slides, slide, mUpdateBranch, mDeleteBranch, widgetTitle,
      tempWidgetTitle, editTitle, saveTitleLoading, onClickTitleEdit, onClickTitleSave, onClickTitleCancel,
      cityArr, searchCity, cityLoading, stateArr, searchState, stateLoading, countryArr, searchCountry, countryLoading
    };
  },
  data() {
    return {
      Constants, moment,
      formLoading: false,
      search: '',
      activeBranchId: '',
      showBranchForm: false,
      maxBranches: 25,
      country_code: '+91',
      phone: '',
      email: '',
      getAddressString,
      map: null
    }
  },
  methods: {
    /* ------ Google Map methods ------- */
    generateMap(lat = 21.1702, long = 72.8311) {
      let element = document.getElementById('google_Map');
      const options = {
        zoom: 17,
        center: new google.maps.LatLng(lat, long),
        mapTypeId: 'roadmap'
      };
      this.map = new google.maps.Map(element, options);

            let marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, long),
                map: this.map,
                draggable: true,
                icon: {
                    path: 'M6.23030235,0 L6.2020371,0 C2.78217446,0 0,2.80896977 0,6.26160896 C0,8.52480491 1.02052424,11.511677 3.0332145,15.1394015 C4.52541508,17.8288776 6.03853566,19.888723 6.05369104,19.9091942 C6.09580998,19.9663352 6.16219612,20 6.23253382,20 C6.23453284,20 6.23657835,20 6.23857738,19.9999061 C6.31114654,19.9978871 6.37813704,19.9603722 6.41839642,19.8992873 C6.43331936,19.8764684 7.93565452,17.585148 9.41772052,14.7826107 C11.4181377,10.9999906 12.4324324,8.13303472 12.4324324,6.26160896 C12.432293,2.80892282 9.65007202,0 6.23030235,0 Z M9.09308856,6.41462659 C9.09308856,8.01682771 7.80251032,9.32022424 6.21612324,9.32022424 C4.62978265,9.32022424 3.33920441,8.01678076 3.33920441,6.41462659 C3.33920441,4.81247242 4.62978265,3.50902893 6.21612324,3.50902893 C7.80251032,3.50902893 9.09308856,4.81247242 9.09308856,6.41462659 Z',
                    fillColor: 'red',
                    fillOpacity: 1.0,
                    strokeColor: '#000000',
                    strokeWeight: 1,
                    scale: 2,
                    anchor: new google.maps.Point(12, 24),
                },
            });
            google.maps.event.addListener(marker, 'dragend', (event) => {
                this.form.lat = event.latLng.lat();
                this.form.long = event.latLng.lng();
            })
        },
        /* -------x------------x---------- */
        autoFillStateAndCountry(val){
            if (val) {
                this.form.address.state = val.state;
                this.form.address.country = val.country;
                this.form.address.city = val.city;
                this.form.phones.forEach(p => p.country_code = val.dial_code);
            }
        },
        autoFillCountry(val){
            if (val) {
                this.form.address.state = val.state;
                this.form.address.country = val.country;
                this.form.phones.forEach(p => p.country_code = val.dial_code);
            }
        },
        autoFillDialCode(val) {
          if (val) {
            this.form.address.country = val.name;
            this.form.phones.forEach(p => p.country_code = val.dial_code);
          }
        },
        addNewEmail(){
          this.form.emails.push('')
        },
        addNewPhone(){
          this.form.phones.push({phone:'', country_code:'+91'})
        },
        onAddPhone(){
            this.form.phones.push({phone:this.phone, country_code:this.country_code});
            this.phone = '';
        },
        removePhone(index){
            this.form.phones.splice(index, 1);
        },
        onAddEmail(){
            this.form.emails.push(this.email);
            this.email = '';
        },
        removeEmail(index){
            this.form.emails.splice(index, 1);
        },
        onSearch(val){
            val = val?.toLowerCase() || '';
            this.branches.forEach(b => b.isHidden = b.name.toLowerCase().indexOf(val) === -1);
        },
        onAddBranch(){
            this.resetForm();
            this.showBranchForm = true;
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(p => {
                    console.log('lat', p.coords.latitude, 'long', p.coords.longitude);
                    this.form.lat = p.coords.latitude;
                    this.form.long = p.coords.longitude;
                    this.generateMap(p.coords.latitude, p.coords.longitude);
                }, e => { this.generateMap(); });
            }else this.generateMap();
        },
        onEditBranch(row){
            this.activeBranchId = row.id;
            this.form.id = '';
            Object.copyExistingKeys(this.form, JSON.parse(JSON.stringify(row)));
            this.showBranchForm = true;
            this.generateMap(row.lat||undefined, row.long||undefined);
        },
        onChangeBranchStatus(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to ${row.is_active?'active':'inactive'} branch ?`,
            }).onOk(() => this.updateBranchStatus(row)).onCancel(() => row.is_active = !row.is_active)
        },
        async updateBranchStatus(row){
            await updateRecord(this.mUpdateBranch, row.id, {is_active: row.is_active});
            this.$q.notify('Branch Status updated successfully');
        },
        onDeleteBranch(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to delete branch ?`,
            }).onOk(() => this.deleteBranch(row))
        },
        async deleteBranch(row){
            await deleteRecord(this.mDeleteBranch, row.id);
            this.$q.notify('Branch deleted successfully');
            let index = this.branches.findIndex(b => b.id === row.id);
            if(index > -1) this.branches.splice(index, 1);
            this.updateSequence();
        },
        setBranchAsDefault(branch){
            if(branch.is_default) return;
            this.branches.forEach((b) => {b.is_default = b.id===branch.id});
            this.branches.sort((x, y) => (x.is_default === true ? -1 : y.is_default === true ? 1 : 0));
            this.updateSequence();
        },
        async updateSequence(){
            this.branches.forEach((b, index) => { b.sequence = index+1; b.customer_id = this.customerId; delete b.__typename; });
            await mutation({
                mutate: this.mBranch,
                variables:{
                    branch: this.branches,
                    updateColumns: ['sequence', 'is_default'],
                }
            });
            this.$q.notify('Branches updated successfully');
        },
        handleSubmit(){
            if(this.branches.length >= this.maxBranches && !this.form.id)
                return this.$q.notify(`Cannot add more than ${this.maxBranches} videos`);
            this.$refs['qForm'].validate().then(success => {
                if(success && this.form.phones.length && this.form.emails.length) this.saveBranch();
                else if(success && !this.form.phones.length) this.$q.notify('Please enter at least one Phone Number');
                else if(success && !this.form.emails.length) this.$q.notify('Please enter at least one Email ID');
            })
        },
        async saveBranch(){
            this.formLoading = true;
            if(!this.branches.length) this.form.is_default = true;
            await mutation({
                mutate: this.mBranch,
                variables:{
                    branch: {
                        ...this.form, customer_id: this.customerId, sequence:this.branches.length + 1
                    },
                    updateColumns: [...Object.keys(this.form)],
                }
            });
            this.formLoading = false;
            this.$q.notify('Branch saved successfully');
            this.resetForm();
        },
        resetForm(){
            Object.copyExistingKeys(this.form, {...vForm, phones:[{phone:'', country_code:'+91'}], emails:[''], address:{...address}});
            // this.countryCode = '+91';
            // this.phone = '';
            // this.email = '';
            delete this.form.id;
            delete this.form.is_default;
            this.$refs['qForm']?.resetValidation();
            this.activeBranchId = '';
            this.editTitle = false;
            this.showBranchForm = false;
        },
        closeDialog(){
            this.resetForm();
            this.showBranchForm = false;
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>
.email-field{
    display: inline-block;
    width: 80%;
}
.remove-btn{
    display: inline-block;
    width: 20%;
}
.add-new{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}
.side-text{
  font-size: 20px;
  color: black;
}
.google-url-desc{
  color: gray;
  font-size: 16px;
  padding: 4px 0px 0px 24px;
}
@media only screen and (max-width: 600px) {
  .my-card{
    margin-left: -34px;
  }
  #inline-input{
    width: 66%;
  }
}
</style>
