<template>
  <div class="aj-container">

    <div class="row justify-between items-center q-mt-lg q-mb-xs">
      <div>
        <h1 class="text-h5 q-my-none" style="font-weight: 600">ByteCard Builder</h1>
        <p class="text-body2 text-weight-medium text-grey-7" style="margin-bottom: 5px;">Last Updated On
          {{ overview.updated_at ? moment(overview.updated_at).format('DD MMM YYYY') : '' }}</p>
        <div class="alertText" >
          <span>Alert : Click <b>Make it Live</b> to apply your changes live on ByteCard URL. (Make it Live button at Top Right Side)</span>
        </div>

      </div>
      <div>
        <q-btn flat color="primary" class="text-capitalize q-mr-sm no-border-radius" label="Preview" size="md" padding="sm md"
               text-color="grey-8" type="a" @click="redirectToPreview" title="Check the preview of your ByteCard"/>
        <q-btn class="q-ml-sm q-mr-none no-box-shadow no-border text-capitalize no-border-radius"
               color="primary" padding="sm md" size="md" @click="onPublishCard" label="Make it live"/>
      </div>
    </div>

    <div class="row q-col-gutter-lg" v-if="Object.keys(overview).length">

      <div class="col-12">
        <div class="live-box q-pa-sm  row justify-content-between">
          <template v-if="subDomain">
          <div>
            <a :href="url" class="text-body1 text-primary" target="_blank">{{ url }}</a>
            <p class="q-mb-none q-mt-xs text-caption">Your ByteCard is ready to share with your customers</p>
          </div>
          <div>
            <q-btn fab-mini icon="mdi-share-variant-outline" class="text-blue" title="Share ByteCard" @click="shareDialog=true"></q-btn>
            <q-btn fab-mini icon="mdi-content-copy" class="text-blue" title="Copy URL" @click="copyUrl"></q-btn>
          </div>
          </template>
          <template v-else>
            <div>
              <p class="q-mb-none q-mt-xs text-body1" style="font-weight: 500; color: red">Your Sub-domain Name is Not Setup Yet</p>
              <p class="q-mb-none q-mt-xs text-caption">This will be used to generate your unique URL, Which you can share with your customers.</p>
            </div>
            <div style="display: flex;align-items: center;padding: 0 10px 0 0;">
              <a class="text-primary cursor-pointer" style="font-weight: 500;font-size: 16px; text-decoration: underline" @click="openDomainPopup">Setup your Sub-domain</a>
<!--              <q-btn fab-mini icon="mdi-share-variant-outline" class="text-blue" title="Setup Sub-domain" @click="requiredDomainDialog=true"></q-btn>-->
            </div>
          </template>
        </div>
      </div>

      <div class="col-12" v-if="overview.is_update">
        <div class="alert-box q-pa-md">
          <h5 class="text-h6" style="font-weight: 600">Make your changes Live </h5>
          <p class="q-mb-none text-body2">Hey! We found some changes which will be not visible until it's live. Please '<a
              style="text-decoration: underline; color: #0e77ff;cursor: pointer" @click="onPublishCard">Make it Live</a>'
            to visible on
            your card.</p>
        </div>
      </div>


      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card relative-position no-border-radius">
          <q-card-section>
            <div class="row justify-between no-wrap">
              
              <div class="col-4">
                <h1 class="text-h6 text-weight-bold" style="font-weight: 600">Theme</h1>
                <div class="row q-mt-sm items-center">
                  <p class="text-body2 q-mb-none text-grey-8 text-weight-medium text-capitalize">
                    Theme-1
                  </p>
                </div>

              </div>

              <div class="col-8">
              <div class="row">
                <div class="col-6 q-pr-xs">
                  <img class="imageContainor" style="width:100%"
                       v-if="overview.themes&&overview.themes.widget_data.length&&overview.themes.widget_data[0].theme"
                       :src="themeImage(overview.themes.widget_data[0].theme.themeCode, overview.themes.widget_data[0].theme.colorCode, 'desktop', 'front')"/>
                  <img class="imageContainor" v-else
                       :src="themeImage(Constants.DefaultThemeCode, Constants.DefaultColorCode, 'desktop', 'front')"/>
                </div>
                <div class="col-6 q-pl-xs">
                  <img class="imageContainor" style="width:100%"
                       v-if="overview.themes&&overview.themes.widget_data.length&&overview.themes.widget_data[0].theme"
                       :src="themeImage(overview.themes.widget_data[0].theme.themeCode, overview.themes.widget_data[0].theme.colorCode, 'desktop', 'back')"/>
                  <img class="imageContainor" v-else
                       :src="themeImage(Constants.DefaultThemeCode, Constants.DefaultColorCode, 'desktop', 'back')"/>
                </div>
              </div>
              </div> 

            </div>
            <q-btn outline class="text-capitalize q-mt-xl text-grey-8"
                   @click="themeDialog = true"
                   label="Select your Theme"/>
            <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin-top: 55px;">
              <q-tooltip class="bg-blue text-body2" max-width="50%">
                You can select your Card theme from here. This theme will be used to create your ByteCard design.
                Each theme includes different sub colors which you can select from Color Widget
              </q-tooltip>
            </q-icon>
          </q-card-section>
        </q-card>
      </div>

      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card relative-position no-border-radius">
          <q-card-section>
            <div class="row justify-between no-wrap">
              <div>
                <h1 class="text-h6 text-weight-bold" style="font-weight: 600">Color</h1>
                <div class="row q-mt-sm items-center">
                  <div class="indicator"
                       :style="`background-color:${overview.themes&&overview.themes.widget_data.length&&overview.themes.widget_data[0].theme&&overview.themes.widget_data[0].theme.hexCode || Constants.DefaultHexCode}`"></div>
                  <p class="q-ml-sm text-body2 q-mb-none text-grey-8 text-weight-medium text-capitalize">
                    {{
                      overview.themes && overview.themes.widget_data.length && overview.themes.widget_data[0].theme && overview.themes.widget_data[0].theme.colorCode || Constants.DefaultColorCode
                    }}
                  </p>
                </div>
                <q-btn outline class="text-capitalize q-mt-xl text-grey-8"
                       @click="themeColorDialog = true"
                       label="Select your Theme Color"/>
              </div>
              <div>
                <q-avatar text-color="white"
                          :style="`background-color:${overview.themes&&overview.themes.widget_data.length&&overview.themes.widget_data[0].theme&&overview.themes.widget_data[0].theme.hexCode || Constants.DefaultHexCode}`"/>
              </div>

            </div>
            <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin-top: -22px;">
              <q-tooltip class="bg-blue text-body2" max-width="50%">
                We provide different colors based on your selected Theme. You can select your
                preferred Card theme color
              </q-tooltip>
            </q-icon>
          </q-card-section>
        </q-card>
      </div>


      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <h1 class="text-h6 text-weight-bold" style="font-weight: 600">Business Details</h1>
              <p class="text-body2 text-weight-medium text-grey-7" style="word-break: break-all; font-size: 14px">
                This will be used for <b>Front view</b> of Digital Card</p>
            </q-card-section>
            <!-- <div>
               <q-toggle v-model="overview.customers.is_active" color="blue" class="q-mt-md" v-if="overview.customers"
                         @click="onUpdateStatus('customers', overview.customers.is_active)">
                 <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                   <strong>Show on card</strong>
                 </q-tooltip>
               </q-toggle>
             </div> -->
          </div>
          <div class="row justify-between">
            <div>
              <div class="row">
                <span class="mdi mdi-domain q-ml-md"></span>
                <p class="q-ml-md text-body2 text-grey-8 q-mb-sm text-weight-medium" v-if="overview.customers">
                  {{ overview.customers.widget_data.name || '-' }}
                </p>
              </div>
              <div class="row">
                <span class="mdi mdi-phone-outline q-ml-md"></span>
                <p class="q-ml-md text-body2 text-grey-8 q-mb-sm text-weight-medium" v-if="overview.customers">
                  {{
                    (overview.customers.widget_data.mobile_country_code || '') + ' ' + (overview.customers.widget_data.mobile || '-')
                  }}
                </p>
              </div>
              <div class="row">
                <span class="mdi mdi-at q-ml-md"></span>
                <p class="q-ml-md text-body2 text-grey-8 q-mb-sm text-weight-medium">
                  {{ overview.customers && overview.customers.widget_data.email || '-' }}</p>
              </div>
              <!-- <div class="row">
                 <span class="mdi mdi-earth q-ml-md"></span>
                 <p class="q-ml-md text-body2 text-grey-8 q-mb-sm text-weight-medium">{{overview.customers&&overview.customers.widget_data.website || '-'}}</p>
               </div> -->
            </div>
          </div>
         <!-- <q-card-section>
            <p class="q-mb-sm text-grey-8">Profile Progress</p>
            <q-linear-progress stripe size="5px" :value="(1*10)/totalBusinessDetailFields"/>
          </q-card-section> -->
          <q-card-actions style="margin-top: 30px">
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" @click="bizFormDialog = true"
                   label="Manage your Business Details"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin:-40px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              This includes your business details that will be used on ByteCard View.
              Some of the business details are fetched from the details you entered for your business Profile.
              You can also edit these details if you want, which will be also updated in your profile
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <h1 class="text-h6 text-weight-bold" style="font-weight: 600">Company Logo, Tagline & Services</h1>
              <p class="text-weight-medium text-grey-7" style="word-break: break-all; font-size: 14px">
                This will be used for <b>Back view</b> of Digital Card</p>
            </q-card-section>
            <!-- <div>
               <q-toggle v-model="overview.customers.is_active" color="blue" class="q-mt-md" v-if="overview.customers"
                         @click="onUpdateStatus('customers', overview.customers.is_active)">
                 <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                   <strong>Show on card</strong>
                 </q-tooltip>
               </q-toggle>
             </div> -->
          </div>
          <div class="row justify-between">
           <!-- <div>
              <div class="row">
                <span class="mdi mdi-phone-outline q-ml-md"></span>
                <p class="q-ml-md text-body2 text-grey-8 q-mb-sm text-weight-medium" v-if="overview.customers">
                  {{
                    (overview.customers.widget_data.mobile_country_code || '') + ' ' + overview.customers.widget_data.mobile
                  }}
                </p>
              </div>
              <div class="row">
                <span class="mdi mdi-at q-ml-md"></span>
                <p class="q-ml-md text-body2 text-grey-8 q-mb-sm text-weight-medium">
                  {{ overview.customers && overview.customers.widget_data.email || '-' }}</p>
              </div>
            </div> -->
            <div class="aj-card-illustration q-ml-md avatar">
              <img style="border: 1px solid lightgrey" v-if="overview.customers&&overview.customers.widget_data.logo"
                   @error="overview.customers.widget_data.logo = null"
                   :src="imgWpx(overview.customers.widget_data.logo, 400)" class="round-image"
                   @click="$emit('openGallery', {arr: [{path: overview.customers.widget_data.logo}]})">
              <img v-else src="../../assets/images/businessDetails.png" style="width: 80%" alt="business-card">
            </div>
          </div>
         <!-- <q-card-section>
            <p class="q-mb-sm text-grey-8">Profile Progress</p>
            <q-linear-progress stripe size="5px" :value="(1*10)/totalBusinessDetailFields"/>
          </q-card-section> -->
          <q-card-actions style="margin-top: 28px">
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" @click="bizBackFormDialog = true"
                   label="Manage your Company Logo, Tagline & Services"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin:-40px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              This includes your business details that will be used on ByteCard View.
              Some of the business details are fetched from the details you entered for your business Profile.
              You can also edit these details if you want, which will be also updated in your profile
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card full-height relative-position no-border-radius">
          <q-card-section>
            <h1 class="text-h6 text-weight-bold" style="font-weight: 600">SEO Details</h1>
            <p class="text-body2 text-weight-medium text-grey-7 "> <!--SEO Title Here -->
              {{ overview.seo_details && overview.seo_details.widget_data.title || '-' }}</p>
          </q-card-section>
          <div class="row justify-between no-wrap">
            <div class="q-mx-md block-ellipsis" style="word-break: break-all; line-height:20px">
              {{ overview.seo_details && overview.seo_details.widget_data.description || '-' }}
            </div>
            <div>
              <div class="aj-card-illustration q-mr-md">
                <img class="image" src="../../assets/images/SEO.png" alt="business-card">
              </div>
            </div>
          </div>
          <q-card-actions class="q-mt-lg">
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8"
                   @click="seoFormDialog = true"
                   label="Manage your ByteCard Appearance on Google"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin:-40px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              For Search Engine Optimization, we required some details for your ByteCard
              website to increase your customer reach.
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <h1 class="text-h6 text-weight-bold" style="font-weight: 600">
                {{ overview.about_us && overview.about_us.widget_title || 'About Us' }}</h1>
            </q-card-section>
            <div>
              <q-toggle v-model="overview.about_us.is_active" color="blue" class="q-mt-md" v-if="overview.about_us"
                        @click="onUpdateStatus('about_us', overview.about_us.is_active)">
                <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                  <strong>Show on card</strong>
                </q-tooltip>
              </q-toggle>
            </div>
          </div>

          <q-card-section>
            <p style="overflow-wrap: break-word; overflow-wrap: anywhere; -webkit-line-clamp: 6; height:85px"
                 class="text-body2 text-weight-medium text-grey-7 block-ellipsis"
                 v-html="overview.about_us&&overview.about_us.widget_data.description || '-'"></p>
          </q-card-section>
          <q-card-actions>
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" @click="aboutUsFormDialog = true"
                   label="Write About Your Company"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -40px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              Everyone wants to know who you are and what your company does. Customers
              always reach to whom they trust more. Tell your customers about your business via ByteCard
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      
      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <div style="font-weight: 600" class="text-h6 text-weight-bold">
                {{ overview.products && overview.products.count || 0 }}
                {{ overview.products && overview.products.widget_title || 'Products' }}
              </div>
            </q-card-section>

            <div>
              <q-toggle v-model="overview.products.is_active" color="blue" class="q-mt-md" v-if="overview.products"
                        @click="onUpdateStatus('products', overview.products.is_active)">
                <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                  <strong>Show on card</strong>
                </q-tooltip>
              </q-toggle>
            </div>
          </div>

          <q-card-section class="q-pt-none row q-col-gutter-sm">
            <template v-for="(p, pIndex) in (overview.products&&overview.products.widget_data||[])">
              <template v-for="(img, imgIndex) in p.images">
                <div class="col-3 avatar" v-if="img.is_starred" :key="pIndex+'-'+imgIndex"
                     @click="$emit('openGallery', {arr:[img]})">
                  <q-img class="widget-image" :src="imgWpx(img.path, 200)"/>
                </div>
              </template>
            </template>
          </q-card-section>

          <q-card-actions>
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" @click="productFormDialog = true"
                   label="Manage Products"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -36px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              You must be serving something to your customer! Let's tell them what you serve.
              Please add your Products/Services and check preview to see how it shows to your customer. You
              can also get the orders and inquiries for your products/services which you can check on ByteCard
              Dashboard" Give link on Bytecard Dashboard
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <div style="font-weight: 600" class="text-h6 text-weight-bold">
                {{ overview.videos && overview.videos.count || 0 }} Videos
              </div>
            </q-card-section>

            <div>
              <q-toggle v-model="overview.videos.is_active" color="blue" class="q-mt-md" v-if="overview.videos"
                        @click="onUpdateStatus('videos', overview.videos.is_active)">
                <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                  <strong>Show on card</strong>
                </q-tooltip>
              </q-toggle>
            </div>
          </div>

          <q-card-section class="q-pt-none row q-col-gutter-sm">
            <div class="col-3 avatar" v-for="(v, vIndex) in (overview.videos&&overview.videos.widget_data||[])"
                 @click="$emit('openGallery', {arr: [{path: getYoutubeThumbnail(v.videoUrl)}], skip:true})">
              <q-img class="widget-image" :src="getYoutubeThumbnail(v.videoUrl)"/>
            </div>
          </q-card-section>

          <q-card-actions>
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" @click="videoDialog = true"
                   label="Manage Videos"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -36px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              Not everyone loves to read and trust but some people only trust when they see. It's
              Easy! Just add your youtube links and let your customer enjoy your videos
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <div style="font-weight: 600" class="text-h6 text-weight-bold">
                {{ overview.certificates && overview.certificates.count || 0 }}
                {{ overview.certificates && overview.certificates.widget_title || 'Certificates' }}
              </div>
            </q-card-section>

            <div>
              <q-toggle v-model="overview.certificates.is_active" color="blue" class="q-mt-md"
                        v-if="overview.certificates"
                        @click="onUpdateStatus('certificates', overview.certificates.is_active)">
                <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                  <strong>Show on card</strong>
                </q-tooltip>
              </q-toggle>
            </div>
          </div>

          <q-card-section class="q-pt-none row q-col-gutter-sm">
            <div class="col-3 avatar"
                 v-for="(c, cIndex) in (overview.certificates&&overview.certificates.widget_data||[])"
                 @click="$emit('openGallery', {arr: [{path: c.certificateUrl}]})">
              <q-img class="widget-image" :src="imgWpx(c.certificateUrl, 200)"/>
            </div>

          </q-card-section>

          <q-card-actions>
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" @click="certificatesDialog = true"
                   label="Manage Certificates"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -36px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              It is very crucial to show your customer what you achieve. You can add Images of
              different Certificates and Awards you have won. Let’s do it!
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <div style="font-weight: 600" class="text-h6 text-weight-bold">
                {{ overview.qrcodes && overview.qrcodes.count || 0 }}
                {{ overview.qrcodes && overview.qrcodes.widget_title || 'Payment' }}
              </div>
              <div style="font-weight: 600" class="text-h6 text-weight-bold">
                {{ overview.qrcodes && overview.qrcodes.widget_title || 'Details & QR' }}
              </div>
            </q-card-section>
            <div>
              <q-toggle v-model="overview.qrcodes.is_active" color="blue" class="q-mt-md" v-if="overview.qrcodes"
                        @click="onUpdateStatus('qrcodes', overview.qrcodes.is_active)">
                <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                  <strong>Show on card</strong>
                </q-tooltip>
              </q-toggle>
            </div>
          </div>
          <q-card-section class="q-pt-none row q-col-gutter-sm">
            <div class="avatar" v-for="(q, qIndex) in (overview.qrcodes&&overview.qrcodes.widget_data||[])"
                 @click="$emit('openGallery', {arr: [{path: q.image_url}]})">
              <q-img class="widget-image" style="width: 82px" :src="imgWpx(q.image_url, 200)"/>
            </div>
          </q-card-section>
          <q-card-actions>
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" @click="paymentDetailsDialog = true"
                   label="Manage Payment Details"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -36px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              Let’s move one step forward with digitized business. Get your
              payments online. Share your Bank details and Payment Gateway QR codes with customers.
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <div style="font-weight: 600" class="text-h6 text-weight-bold">
                {{ overview.branches && overview.branches.widget_title || 'Contact Us' }}
              </div>
              <p>{{ overview.branches && overview.branches.count || 0 }} Address</p>
            </q-card-section>

            <div>
              <q-toggle v-model="overview.branches.is_active" color="blue" class="q-mt-md" v-if="overview.branches"
                        @click="onUpdateStatus('branches', overview.branches.is_active)">
                <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                  <strong>Show on card</strong>
                </q-tooltip>
              </q-toggle>
            </div>
          </div>

          <q-card-section class="q-pt-none">
            <div class="row q-col-gutter-md">
              <div class="col-12 col-sm-12 col-md-6" v-for="(b, bIndex) in (overview.branches&&overview.branches.widget_data||[])">
                <q-card flat bordered class="my-card">
                  <q-card-section>
                    <div class="text-subtitle1 text-weight-medium">{{ b.name }}</div>
                    <p class="text-body2 text-grey-8" style="word-break: break-all">
                      {{ getAddressString(b.address) }}
                    </p>
                  </q-card-section>
                </q-card>
              </div>
            </div>
            <p class="text-right text-primary q-mt-sm text-weight-medium"
               v-if="overview.branches&&overview.branches.count>2">
              +{{ overview.branches && overview.branches.count - 2 }} more</p>
          </q-card-section>
          <q-card-actions>
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" label="Manage your Branches"
                   @click="contactUsdialog = true"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -36px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              Tell your customers where you are located and let them find you on Map. One
              branch will be auto created from the address you entered in your business profile, which will be
              considered as your default address. You can also change your default address if you want.
              Default address cannot be deleted.
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <div style="font-weight: 600" class="text-h6 text-weight-bold">Social Media</div>
              <p>
                {{
                  overview.social_media ? getSocialMedia(overview.social_media.widget_data, Object.keys(mapKeyToImage)).length : 'No'
                }}
                Social accounts</p>
            </q-card-section>

            <div>
              <q-toggle v-model="overview.social_media.is_active" color="blue" class="q-mt-md"
                        v-if="overview.social_media"
                        @click="onUpdateStatus('social_media', overview.social_media.is_active)">
                <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                  <strong>Show on card</strong>
                </q-tooltip>
              </q-toggle>
            </div>
          </div>

          <q-card-section class="q-pt-none row">
            <div class="row q-col-gutter-md" v-if="overview.social_media">
              <div class="aj-imageContainer-3"
                   v-for="m in getSocialMedia(overview.social_media.widget_data, Object.keys(mapKeyToImage))">
                <img class="image" :src="require('../../assets/images/'+mapKeyToImage[m]+'.svg')" alt="" srcset=""/>
              </div>
            </div>
          </q-card-section>
          <q-card-actions>
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" @click="linksDialog = true"
                   label="Add your Social Media URLs"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -36px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              Give a chance to your customer to visit your social media pages.. We have
              provided you with multiple options where you must have your business accounts. Add your
              social media links here from where your customer can visit
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
      <div class="col-12">
        <q-card flat bordered class="my-card no-border-radius">
          <div class="row justify-between">
            <q-card-section>
              <div style="font-weight: 600" class="text-h6 text-weight-bold">
                {{ overview.testimonials && overview.testimonials.widget_title || 'Testimonial' }}
              </div>
              <p>{{ overview.testimonials && overview.testimonials.count || 0 }} Testimonial</p>
            </q-card-section>

            <div>
              <q-toggle v-model="overview.testimonials.is_active" color="blue" class="q-mt-md"
                        v-if="overview.testimonials"
                        @click="onUpdateStatus('testimonials', overview.testimonials.is_active)">
                <q-tooltip anchor="top middle" :delay="1000" self="bottom middle" :offset="[10, 10]">
                  <strong>Show on card</strong>
                </q-tooltip>
              </q-toggle>
            </div>
          </div>

          <q-card-section class="q-pt-none">
            <div class="row q-col-gutter-md">
              <div class="col-12 col-sm-12 col-md-6" v-for="(t, tIndex) in (overview.testimonials&&overview.testimonials.widget_data||[])">
                <q-card flat bordered class="my-card" style="height: 100%">
                  <q-card-section>
                    <div class="text-subtitle1 text-weight-medium q-mb-sm">
                      <q-avatar size="" class="q-mr-sm avatar">
                        <img v-if="t.image_url" :src="imgWpx(t.image_url, 200)"
                             @click="$emit('openGallery', {arr: [{path: t.image_url}]})"/>
                        <q-icon name="mdi-account"></q-icon>
                      </q-avatar>
                      <div class="testimonial-person-name" :title="t.person_name">{{ t.person_name }}</div>
                    </div>
                    <p class="text-body2 text-grey-8 q-mb-none testimonial-description" :title="t.description">
                      {{ t.description }}</p>
                  </q-card-section>
                </q-card>
              </div>
            </div>
            <p class="text-right text-primary q-mt-sm text-weight-medium"
               v-if="overview.testimonials&&overview.testimonials.count>2">
              +{{ overview.testimonials && overview.testimonials.count - 2 }} more</p>
          </q-card-section>
          <q-card-actions>
            <q-btn outline class="text-capitalize q-mb-sm q-ml-sm text-grey-8" label="Manage Customer Testimonials"
                   @click="testimonialDialog = true"/>
          </q-card-actions>
          <q-icon name="mdi-information-outline" class="info-icon" size="sm" style="margin: -36px 14px 0 0;">
            <q-tooltip class="bg-blue text-body2" max-width="50%">
              Word of Mouth Marketing is what most of the customers believe in. Let your
              customer know what others say about you. Add testimonials of your customers or vendors and
              let others see how good you are.
            </q-tooltip>
          </q-icon>
        </q-card>
      </div>
    </div>
    <div v-else style="text-align: center; margin-top: 36vh">
      <q-spinner color="primary" size="5em" :thickness="2"/>
    </div>
    <SeoDetailForm v-model:dialog="seoFormDialog"/>
    <AboutUsForm v-model:dialog="aboutUsFormDialog"/>
    <BizDetailForm v-model:dialog="bizFormDialog"/>
    <BizDetailBackForm v-model:dialog="bizBackFormDialog"/>
    <ProductForm v-model:dialog="productFormDialog"/>
    <VideoForm v-model:dialog="videoDialog"/>
    <CertificateForm v-model:dialog="certificatesDialog"/>
    <TestimonialForm v-model:dialog="testimonialDialog"/>
    <PaymentDetailForm v-model:dialog="paymentDetailsDialog"/>
    <SocialMediaForm v-model:dialog="linksDialog"/>
    <BranchForm v-model:dialog="contactUsdialog"/>
    <ThemeColorForm v-model:dialog="themeColorDialog"/>
    <ThemeForm v-model:dialog="themeDialog"/>
    <PublishCard ref="publish-card" v-model:dialog="publishCardDialog" :loading="publishCardLoading"
                 v-if="publishCardDialog"/>
    <RequiredDomainForm v-model:dialog="requiredDomainDialog" @saveDomain="publishCard"/>
   <!-- <CanvasCard ref="canvas-card" style="display: none"/> -->
    <ShareDialog v-model:dialog="shareDialog"/>
  </div>
</template>
<script>
import SeoDetailForm from "../../components/CardBuilder/SeoDetailForm";
import AboutUsForm from "../../components/CardBuilder/AboutUsForm";
import BizDetailForm from "../../components/CardBuilder/BizDetailForm";
import ProductForm from "../../components/CardBuilder/ProductForm";
import VideoForm from "../../components/CardBuilder/VideoForm";
import CertificateForm from "../../components/CardBuilder/CertificateForm";
import TestimonialForm from "../../components/CardBuilder/TestimonialForm";
import PaymentDetailForm from "../../components/CardBuilder/PaymentDetailForm";
import SocialMediaForm from "../../components/CardBuilder/SocialMediaForm";
import BranchForm from "../../components/CardBuilder/BranchForm";
import ThemeColorForm from "../../components/CardBuilder/ThemeColorForm";
import {ref, reactive} from "vue";
import {useMutation, useQuery, useSubscription} from "@vue/apollo-composable";
import Constants, {getAddressString} from "../../constants/Constants";
import moment from 'moment';
import {SUBSCRIBE_CARD_OVERVIEW_BY_CUSTOMER_ID} from "../../graphql/subscriptions/dc";
import {inlineEdit} from "../../mixins/inlineEdit";
import PublishCard from "../../components/CardBuilder/PublishCard";
import axios from 'axios';
import ThemeForm from "../../components/CardBuilder/ThemeForm";
import RequiredDomainForm from "../../components/CardBuilder/RequiredDomainForm";
import ShareDialog from "../../components/CardBuilder/ShareDialog";
import BizDetailBackForm from "../../components/CardBuilder/BizDetailBackForm";
import updateMultiple from "../../composables/updateMultiple";
import {UPDATE_CUSTOMER_WITH_WIDGET} from "../../graphql/mutations/organizations";
import {GET_DOMAIN} from "../../graphql/queries/organizations";

export default {
  name: "ProductDashboard",
  components: {
    BizDetailBackForm,
    ShareDialog,
    RequiredDomainForm,
    ThemeForm,
    PublishCard, ThemeColorForm, BranchForm, SocialMediaForm, PaymentDetailForm, TestimonialForm,
    CertificateForm, VideoForm, ProductForm, BizDetailForm, AboutUsForm, SeoDetailForm
  },
  mixins: [inlineEdit],
  setup() {
    const customerId = ref(JSON.parse(localStorage.user).id);
    const subDomain = ref(localStorage.subDomain);
    const overview = reactive({});
    const {onResult} = useSubscription(SUBSCRIBE_CARD_OVERVIEW_BY_CUSTOMER_ID, () => ({customerId: customerId.value}));
    onResult(r => {
      if (r.data.dc_customer_widgets.length) Object.assign(overview, r.data.dc_customer_widgets[0]);
      ['business_detail', 'customers', 'seo_details', 'about_us', 'products', 'videos', 'certificates', 'testimonials', 'qrcodes', 'branches', 'themes', 'social_media'].forEach(w => {
        overview[w] = overview[w] || {widget_data: {}, widget_title: '', is_active: 'true'};
        overview[w].is_active = (['true', true].includes(overview[w].is_active));
        overview[w].widget_data = overview[w].widget_data || {};
        if (['products', 'videos', 'certificates', 'qrcodes', 'branches', 'testimonials'].includes(w) && overview[w].widget_data.length) {
          overview[w].count = overview[w].widget_data[0].count;
          overview[w].widget_data.sort((a, b) => a.sequence - b.sequence);
        }
      });
    });

    const {onResult: domainResult} = useQuery(GET_DOMAIN, () => ({id: customerId.value}),
        () => ({enabled: !subDomain.value, fetchPolicy: 'network-only'}));
    domainResult(r => {
      subDomain.value = r.data.organizations[0].website;
    });

    const { mutate: mUpdateCustomer } = useMutation(UPDATE_CUSTOMER_WITH_WIDGET);

    return {overview, customerId, subDomain, mUpdateCustomer};
  },
  data() {
    return {
      Constants, moment,
      totalBusinessDetailFields: 13,
      bizFormDialog: false,
      bizBackFormDialog: false,
      seoFormDialog: false,
      productFormDialog: false,
      contactUsdialog: false,
      themeColorDialog: false,
      themeDialog: false,
      paymentDetailsDialog: false,
      testimonialDialog: false,
      linksDialog: false,
      certificatesDialog: false,
      videoDialog: false,
      aboutUsFormDialog: false,
      mapKeyToImage: {
        websiteLink: 'globe',
        androidLink: 'playstore',
        iosLink: 'app-store',
        instagramLink: 'instagram',
        twitterLink: 'twitter',
        whatsappNumber: 'whatsapp',
        facebookLink: 'facebook',
        linkedinLink: 'linkedin',
        youtubeLink: 'youtube',
        pinterestLink: 'pinterest',
        blogLink: 'blog'
      },
      getSocialMedia: (obj, keys) => keys.filter(k => obj[k]),
      getAddressString,
      publishCardDialog: false,
      publishCardLoading: false,
      requiredDomainDialog: false,
     /* subDomain: localStorage.subDomain, */
      shareDialog: false
    }
  },
  computed: {
    url() {
      return `https://${this.subDomain}.${Constants.publish_app_domain}`
    }
  },
  methods: {
    copyUrl() {
      navigator.clipboard.writeText(this.url);
      this.$q.notify('URL copied to Clipboard');
    },
    getYoutubeThumbnail(videoUrl) {
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      let match = videoUrl.match(regExp);
      let videoId = (match && match[7].length == 11) ? match[7] : false;
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    },
    onUpdateStatus(column, status) {
      this.$q.dialog({
        title: 'Confirm', cancel: true, persistent: true,
        message: `Are you sure you want to ${status ? 'active' : 'inactive'} ${Constants.mapColToWidget[column]} widget ?`,
      }).onOk(() => this.updateStatus(column, status)).onCancel(() => this.revertStatus(column))
    },
    revertStatus(column) {
      this.overview[column].is_active = !this.overview[column].is_active;
    },
    updateStatus(column, status) {
      /* title = title || Constants.mapColToWidget[column]; */
      this.updateWidgetStatus(column, status.toString()).then(response => {
        if (response.status) this.$q.notify(`${Constants.mapColToWidget[column]} widget status updated successfully`);
        else this.$q.notify(response.message);
      })
    },
    redirectToPreview() {
      window.open(`https://preview.${Constants.publish_app_domain}/?orgId=${this.customerId}`);
    },
    openDomainPopup(){
      this.requiredDomainDialog = true;
    },
    onPublishCard() {
      if(!localStorage.subDomain) this.requiredDomainDialog = true;
      else this.publishCard(localStorage.subDomain);
    },
    publishCard(domain) {
      localStorage.setItem('subDomain', domain);
      this.subDomain = domain;
      this.publishCardLoading = true;
      this.publishCardDialog = true;
      let request = {
        orgId: this.customerId,
        domain
      };
      axios.post(`${Constants.api_url}publishDataStore`, request).then(async (response) => {
        if (response.data.status) {
         /* await this.$refs['canvas-card'].getCustomerById(domain, this.customerId); */
          await this.updateCustomer(domain);
          this.$refs['publish-card'].setSubDomain(domain);
          this.subDomain = domain;
        } else {
          this.publishCardDialog = false;
          this.$q.notify(response.data.message || 'Fail to publish card !')
        }
        this.publishCardLoading = false;
      })
    },
    async updateCustomer(website){
      await updateMultiple({
        mutate: this.mUpdateCustomer,
        id: this.customerId,
        customer: {website},
        widget: {is_update: false, is_publish: true}
      });
    }
  },


};
</script>

<style scoped>
/deep/ .q-tab__label {
  font-size: 14px;
}

.alertText{
  font-size: 14px;
  color: red;
  margin-bottom: 5px;
  font-weight: 600;
  animation: blink 2s linear infinite;
}

@keyframes blink{
  50%{opacity: 0.3;}
}

/*blinkText{*/
/*  font-size: 14px;*/
/*  color: red;*/
/*  */
/*}*/


/*.alertText {*/
/*  font-weight: 600;*/
/*  background-image: -webkit-linear-gradient(70deg, #e00909, #e00909);*/
/*  -webkit-background-clip: text;*/
/*  -webkit-text-fill-color: transparent;*/
/*  -webkit-animation: blink 2s infinite linear;*/
/*}*/

/*@-webkit-keyframes blink {*/
/*  from {*/
/*    -webkit-filter: hue-rotate(0deg);*/
/*    opacity: 0.5;*/
/*  }*/
/*  to {*/
/*    -webkit-filter: hue-rotate(-360deg);*/
/*    opacity: 1;*/
/*  }*/
/*}*/


.block-ellipsis {
  display: block;
  display: -webkit-box;
  height: 80px;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/deep/ .q-carousel {
  height: auto !important;
}

/deep/ .q-img__image {
  object-fit: contain !important;
}
</style>

<style>
.info-icon{
  float: right;
  visibility: hidden;
  cursor: pointer;
}
.my-card:hover .info-icon{
  visibility: visible;
}
.custom-chip {
  background: #F1F1F1;
  border-radius: 50px;
}

.theme-color-indicator {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #01AEF0;
  display: inline-block;
}

.indicator {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.imageContainor {
  width: 150px;
  /*border: 1px solid lightgrey;*/
}

.aj-imageContainer-2 {
  width: 100px;
}

.aj-imageContainer-3 {
  width: 50px;
}

.image {
  width: 100%;
}

.widget-image {
  max-width: 125px;
  height: 83px;
  object-fit: contain;
  border: 1px solid lightgrey;
}

.round-image {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  object-fit: contain;
}

.aj-container {
  max-width: 1140px;
  margin: 0 auto;
}

.aj-avatar-upload {
  height: 64px;
  width: 64px;
  border-radius: 100%;
  object-fit: cover;
  border:1px solid lightgrey;
  /*background: lightgrey;*/
}

.aj-uploader {
  border: 1px dashed lightgrey;
  border-radius: 5px;
  height: 100px;
}

.aj-card-illustration {
  width: 100px;
}

.aj-video-preview-container {
  height: 200px;
}

.aj-linkIcons {
  width: 32px;
}

.testimonial-person-name {
  width: calc(100% - 56px);
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.testimonial-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.alert-box {
  border-left: 3px solid #f7d987;
  background: #fcf2d6;
}

.live-box {
  border-left: 3px solid #00abec;
  background: #e3f6fd;
}
</style>

<style lang="scss" scoped>
.list-delete-icon {
  visibility: hidden;
}

.list-item:hover {
  .list-delete-icon {
    visibility: visible;
    color: red;
  }
}

.aj-map-preview {
  height: 150px;
  background: lightgrey;
}
</style>
