import gql from "graphql-tag";

export const CREATE_UPDATE_CUSTOMER = gql`
mutation upsertOrganization($customer:[organizations_insert_input!]!, $updateColumns: [organizations_update_column!]!) {
  insert_organizations(objects: $customer, on_conflict: {constraint: organizations_pkey, update_columns: $updateColumns}){
    returning{id}
  }
}`

export const CREATE_UPDATE_CUSTOMER_WITH_SOCIAL_MEDIA = gql`
mutation upsertOrganizationWithSocialMedia($customer:[organizations_insert_input!]!, $updateColumns: [organizations_update_column!]!,
                  $dc_cms:[dc_cms_insert_input!]!, $updateColumnsDcCms: [dc_cms_update_column!]!,
                   $skipAddBranch: Boolean!, $branch:[dc_customer_branches_insert_input!]!) {
  insert_organizations(objects: $customer, on_conflict: {constraint: organizations_pkey, update_columns: $updateColumns}){
    returning{id}
  }
  insert_dc_cms(objects: $dc_cms, on_conflict: {constraint: dc_cms_customer_id_key, update_columns: $updateColumnsDcCms}){
    returning{id}
  }
  insert_dc_customer_branches(objects: $branch) @skip(if: $skipAddBranch){
    returning{id}
  }
}`

export const UPDATE_CUSTOMER_WITH_WIDGET = gql`
mutation ($id: uuid!, $customer:organizations_set_input, $widget:dc_customer_widgets_set_input) {
  update_organizations(where: {id: {_eq: $id}}, _set: $customer){
   affected_rows
  }  
  update_dc_customer_widgets(where: {customer_id: {_eq: $id}}, _set: $widget){
   affected_rows
  }  
}`