<template>
  <div>

    <q-dialog v-model="dialog" persistent transition-show="slide-up"
              transition-hide="slide-down" maximized>
      <q-card>
        <q-card-section class="aj-container row justify-between items-center pb-0">
          <div>
            <div class="text-h5 text-weight-medium">Complete Your Profile</div>
            <p style="font-size: 14px;color: #545b62;">We required your basic business details to complete your Registration with us. Please
              fill in the details below and continue to build your ByteCard</p>
          </div>
          <div>
            <q-btn icon="close" text-color="red-6" color="red-1" round @click="closeDialog" v-if="showCancelButton">
              <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
            </q-btn>
          </div>
        </q-card-section>

        <q-card-section class="aj-container">
          <q-form ref="qForm">
            <div class="row q-col-gutter-md">

              <div class="col-3">
                <h3 class="section-title text-weight-medium">Company Details</h3>
              </div>

              <div class="col-9 q-mb-md">
                <div class="row q-col-gutter-md">
                  <div class="col-12">
                    <q-input class="q-pb-none"  square label="Company Name*" v-model="form.name" lazy-rules outlined
                             :rules="[v => !!(v&&v.trim()) || 'Please enter Company Name']"/>
                  </div>


                  <div class="col-6">
                    <q-input class="q-pb-none" square label="Address Line 1*" v-model="form.address.addressLine1"
                             lazy-rules
                             outlined :rules="[v => !!(v&&v.trim()) || 'Please enter Address Line 1']"/>
                  </div>
                  <div class="col-6">
                    <q-input class="q-pb-none" square label="Address Line 2" v-model="form.address.addressLine2"
                             outlined/>
                  </div>
                  <div class="col-12">
                    <q-input class="q-pb-none col-4" square label="Area" v-model="form.address.area" outlined/>
                  </div>

                  <div class="col-6">
                    <q-select class="q-pb-none col-4 searchBox" square label="City/Dist*" v-model="form.address.city" outlined
                              :rules="[v => !!v || 'Please select City/Dist']" debounce="400" use-input clearable
                              :options="cityArr" option-label="city" @filter="searchCity" :loading="cityLoading"
                              @update:model-value="autoFillStateAndCountry">
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">No results</q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>
                  <div class="col-6">
                    <q-input class="q-pb-none col-4" square label="Pincode*" v-model="form.address.pincode" lazy-rules
                             outlined mask="######" :rules="[v => !!(v&&v.trim()) || 'Please enter Pincode']"/>
                  </div>

                  <div class="col-6">
                    <q-select class="q-pb-none col-6 searchBox" square label="State*" v-model="form.address.state"
                              outlined
                              :rules="[v => !!v || 'Please select State']" debounce="500" use-input clearable
                              :options="stateArr" option-label="state" @filter="searchState" :loading="stateLoading"
                              @update:model-value="autoFillCountry">
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">No results</q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>
                  <div class="col-6">
                    <q-select class="q-pb-none col-6 searchBox" square label="Country*" v-model="form.address.country"
                              outlined
                              :rules="[v => !!v || 'Please select Country']" debounce="500" clearable
                              :options="countryArr" option-label="name" @update:model-value="autoFillDialCode"
                              emit-value :loading="countryLoading">
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">No results</q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>

                  <div class="col-12">
                    <q-select  square label="Category" v-model="form.category" outlined use-input clearable
                              :options="categoryOptions" map-options option-label="name" option-value="id" emit-value
                              @filter="(...args) => filterFn(...args, 'categoryOptions', 'tempCategoryOptions')"/>
                  </div>

                </div>
              </div>


              <div class="col-3">
                <h3 class="section-title text-weight-medium">Personal Details</h3>
              </div>

              <div class="col-9 q-mb-sm">
                <div class="row q-col-gutter-md">
                  <div class="col-6">
                    <q-input class="q-pb-none" square label="Person Name*" v-model="form.business_detail.personName"
                             lazy-rules
                             outlined :rules="[v => !!(v&&v.trim()) || 'Please enter Person Name']"/>
                  </div>
                  <div class="col-6">
                    <q-input class="q-pb-none" square label="Designation" v-model="form.designation"
                             lazy-rules outlined maxlength="50"/>
                  </div>

                  <div class="col-12">
                    <q-input class="q-pb-none" label="Email*" square v-model="form.email" lazy-rules outlined disable
                             :rules="[v => !!v || 'Please enter Email', v => Constants.EmailPattern.test(v) || 'Invalid Email']"/>
                  </div>

                  <div class="col-6">
                    <div class="row">  <!-- @filter="filterCountryCode" use-input -->
                      <q-select class="q-pb-none col-2" square outlined :options="Countries"
                                v-model="form.mobile_country_code" option-value="dialCode" emit-value label=""
                                :rules="[v => !!v || 'Please enter Country Code']">
                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps">
                            <q-item-section>
                              <q-item-label>{{ scope.opt.name }} ({{ scope.opt.dialCode }})</q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                      <q-input class="col-10 q-pb-none" square label="Contact Number*" v-model="form.mobile" lazy-rules
                               outlined
                               mask="##########"
                               :rules="[v => !!v || 'Please enter Contact Number', v => v.length === 10 || 'Invalid Contact Number']"/>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="row">  <!-- @filter="filterCountryCode" use-input -->
                      <q-select class="q-pb-none col-2" square outlined :options="Countries"
                                v-model="form.whatsapp_country_code" option-value="dialCode" emit-value label=""
                                :rules="[v => !!v || 'Please enter Country Code']">
                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps">
                            <q-item-section>
                              <q-item-label>{{ scope.opt.name }} ({{ scope.opt.dialCode }})</q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                      <q-input class="q-pb-none col-10" square label="WhatsApp Number*" v-model="form.whatsapp" lazy-rules
                               outlined mask="##########"
                               :rules="[v => !!v || 'Please enter WhatsApp Number', v => v.length === 10 || 'Invalid WhatsApp Number']"/>
                    </div>
                  </div>



                </div>
              </div>


              <div class="col-3">
                <h4 class="section-title text-weight-medium q-mb-md">Social Media URLs</h4>
              </div>
              <div class="col-9 q-mb-xl">
                <div class="row q-col-gutter-md">
                  <div class="col-6">
                    <q-input class="q-pb-none" square label="Instagram Link" v-model="socialMedia.instagramLink"
                             outlined/>
                  </div>
                  <div class="col-6">
                    <q-input class="q-pb-none" square label="Twitter Link" v-model="socialMedia.twitterLink"
                             outlined/>
                  </div>
                  <div class="col-6">
                    <q-input class="q-pb-none" square label="Website Link" v-model="socialMedia.websiteLink" outlined/>
                  </div>
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>

        <q-footer class="bg-white text-right q-pb-md q-pr-md " style="box-shadow: 0px -2px 10px 0px rgba(0,0,0,0.13);padding: 14px">
<!--        <q-footer class="bg-white" style="box-shadow: 0px -2px 10px 0px rgba(0,0,0,0.13)">-->
          <q-btn label="Cancel" size="md" class="q-mr-md bg-grey-3 text-grey-8 text-capitalize"
                 unelevated v-if="showCancelButton" @click="closeDialog"/>
          <q-btn :label="showCancelButton?'Save':'Save And Continue to Build ByteCard'" size="md" color="primary" class="text-capitalize"
                 unelevated @click="handleSubmit" :loading="formLoading"/>
        </q-footer>
      </q-card>
    </q-dialog>

    <BytecardIntroduction v-model:dialog="bytecardIntroDialog" @openDomainDialog="domainDialog=true"/>
    <SubdomainForm v-model:dialog="domainDialog"/>

  </div>
</template>

<script>
import moment from 'moment';
import Constants from "../../constants/Constants";
import {reactive, ref} from 'vue';
import {useQuery, useMutation} from '@vue/apollo-composable';
import {GET_BUSINESS_CATEGORIES, GET_ORGANIZATION_BY_ID} from "../../graphql/queries/organizations";
import useSearchCity from "../../composables/searchCity";
import useSearchState from "../../composables/searchState";
import useSearchCountry from "../../composables/searchCountry";
import {countryCode} from '../../mixins/countryCode';
import {GET_SOCIAL_MEDIA_BY_CUSTOMER_ID} from "../../graphql/queries/dc";
import SubdomainForm from "../CardBuilder/SubdomainForm";
import BytecardIntroduction from "../CardBuilder/BytecardIntroduction";
import axios from "axios";
let vForm = {
  id: '', name: null, mobile: null, email: '', whatsapp: null, mobile_country_code: '+91', whatsapp_country_code:'+91',
  address: {addressLine1: '', addressLine2: '', area: '', city: '', state: '', country:'', pincode: ''},
  business_detail: {personName: ''}, category:null, designation:null
};
let vSocialMedia = { websiteLink:'', androidLink:'', iosLink:'', instagramLink:'', twitterLink:'', whatsappNumber:'',
        facebookLink:'', linkedinLink:'', youtubeLink:'', pinterestLink:'', blogLink:'' };
export default {
  name: "BusinessDetailForm",
  components: {BytecardIntroduction, SubdomainForm},
  props: ['dialog'],
  emits: ['update:dialog'],
  mixins: [countryCode],
  setup(props) {
    const customerId = ref(JSON.parse(localStorage.user).id);

    const form = reactive(vForm);
    const {onResult} = useQuery(GET_ORGANIZATION_BY_ID, () => ({id: customerId.value}),
        () => ({enabled: !!customerId.value && props.dialog, fetchPolicy: 'network-only'}));
    onResult(r => {
      Object.copyExistingKeys(form, JSON.parse(JSON.stringify(r.data.organizations[0])));
      form.address = form.address || {
        addressLine1: '',
        addressLine2: '',
        area: '',
        city: '',
        state: '',
        country: '',
        pincode: ''
      };
      form.business_detail = form.business_detail || {personName: '', instagramLink: null, twitterLink: null};
    });

    const socialMedia = ref({...vSocialMedia});
    const {onResult: onResultSocialMedia} = useQuery(GET_SOCIAL_MEDIA_BY_CUSTOMER_ID, () => ({customerId: customerId.value}),
            () => ({enabled: !!customerId.value && props.dialog, fetchPolicy:'network-only'}));
    onResultSocialMedia(r => {
      if(r.data.dc_cms[0]?.social_media) Object.copyExistingKeys(socialMedia.value, r.data.dc_cms[0].social_media);
    });

    const categoryOptions = ref([]);
    const tempCategoryOptions = ref([]);
    const {onResult: onResultCategory} = useQuery(GET_BUSINESS_CATEGORIES, undefined, () => ({fetchPolicy:'network-only'}));
    onResultCategory(r => {categoryOptions.value = r.data.business_categories;
                               tempCategoryOptions.value = r.data.business_categories});

    const {cityArr, searchCity, cityLoading} = useSearchCity();
    const {stateArr, searchState, stateLoading} = useSearchState();
    const {countryArr, searchCountry, countryLoading} = useSearchCountry();
    // const {mutate: mSaveBusinessDetail} = useMutation(CREATE_UPDATE_CUSTOMER_WITH_SOCIAL_MEDIA);

    return {form, cityArr, searchCity, cityLoading, stateArr, searchState, stateLoading,
            countryArr, searchCountry, countryLoading, categoryOptions, tempCategoryOptions, socialMedia, customerId};
  },
  data() {
    return {
      Constants,
      moment,
      formLoading: false,
      showCancelButton: false,
      domainDialog: false,
      bytecardIntroDialog: false
    }
  },
  watch: {
    'dialog': function () {
      this.showCancelButton = !!localStorage.isBusinessDetailCompleted
    }
  },
  methods: {
    filterFn (val, update, abort, options, tempOptions) {
      if (val === '') return update(() => {this[options] = this[tempOptions]});

      update(() => {
        const needle = val.toLowerCase();
        this[options] = this[tempOptions].filter(v => v.name.toLowerCase().indexOf(needle) > -1);
      })
    },
    autoFillStateAndCountry(val){
      if (val) {
        this.form.address.state = val.state;
        this.form.address.country = val.country;
        this.form.address.city = val.city;
        this.form.mobile_country_code = this.form.whatsapp_country_code = val.dial_code;
      }
    },
    autoFillCountry(val) {
      if (val) {
        this.form.address.state = val.state;
        this.form.address.country = val.country;
        this.form.mobile_country_code = this.form.whatsapp_country_code = val.dial_code;
      }
    },
    autoFillDialCode(val) {
      if (val) {
        this.form.address.country = val.name;
        this.form.mobile_country_code = this.form.whatsapp_country_code = val.dial_code;
      }
    },
    handleSubmit() {
      this.$refs['qForm'].validate().then(success => {
        if (success) this.saveBusinessDetail();
      })
    },
   /* async saveBusinessDetail() {
      this.formLoading = true;
      let dc_cms = { customer_id: this.customerId, social_media: this.socialMedia,
        created_by: this.customerId, updated_by: this.customerId };
      let updateColumnsDcCms = ['social_media', 'updated_by'];
      // ------ Add Default Branch at first time ---------
      let branch = {}, skipAddBranch = true;
      if(!this.showCancelButton) {
        branch = {
          name: 'Main Branch', iframe_code: null, lat: null, long: null, is_default:true,
          working_hours: Constants.DefaultWorkingHours, working_hours_title: 'Working Hours', customer_id: this.customerId, sequence:1
        };
        branch['phones'] = [{phone: this.form.mobile, country_code: this.form.mobile_country_code}];
        branch['emails'] = [this.form.email];
        branch['address'] = this.form.address;
        skipAddBranch = false;
        if(navigator.geolocation) {
          await new Promise(resolve => {
            navigator.geolocation.getCurrentPosition(p => {
              branch.lat = p.coords.latitude;
              branch.long = p.coords.longitude;
              resolve();
            }, e => { resolve(); });
          })
        }
        dc_cms['seo_details'] = {title: this.form.name, description:''};
        updateColumnsDcCms.push('seo_details');
      }
      // --------x----------x------------x---------x--------

      await mutation({
        mutate: this.mSaveBusinessDetail,
        variables: {
          customer: {...this.form, is_completed:true},
          updateColumns: [...Object.keys(this.form), 'is_completed'],
          dc_cms, updateColumnsDcCms,
          skipAddBranch, branch
        }
      });
      this.formLoading = false;
      this.$q.notify('Business Detail saved successfully');
      this.closeDialog();
      if (!this.showCancelButton) {
        localStorage.setItem('isBusinessDetailCompleted', 'true');
        this.bytecardIntroDialog = true;
      }
    }, */
    async saveBusinessDetail() {
      this.formLoading = true;
      let lat, long;
      if(!this.showCancelButton) {
        if(navigator.geolocation) {
          await new Promise(resolve => {
            navigator.geolocation.getCurrentPosition(p => {
              lat = p.coords.latitude;
              long = p.coords.longitude;
              resolve();
            }, e => { resolve(); });
          })
        }
      }

      let headers = { token: localStorage.token };
      let request = {
        name: this.form.name,
        category: this.form.category,
        personName: this.form.business_detail.personName,
        email: this.form.email,
        mobile: this.form.mobile,
        mobileCc: this.form.mobile_country_code,
        whatsapp: this.form.whatsapp,
        whatsappCc: this.form.whatsapp_country_code,
        oId: this.customerId,
        designation: this.form.designation,
        twitterLink: this.socialMedia.twitterLink,
        websiteLink: this.socialMedia.websiteLink,
        instagramLink: this.socialMedia.instagramLink,
        lat, long,
        ...this.form.address
      };
      axios.post(`${Constants.api_url}completeBusinessProfile`, request, {headers}).then(async (response) => {
        if(response.data.status) {
          this.$q.notify('Business Detail saved successfully');
          this.closeDialog();
          if(!this.showCancelButton) {
            localStorage.setItem('isBusinessDetailCompleted', 'true');
            this.bytecardIntroDialog = true;
          }
        } else this.$q.notify(response.data.message || 'Fail to save Business Detail!')
        this.formLoading = false;
      })
    },
    resetForm() {
      this.form.name = '';
      this.form.mobile = null;
      this.form.email = '';
      this.form.whatsapp = null;
      this.form.category = null;
      this.form.designation = null;
      this.form.mobile_country_code = '+91';
      this.form.whatsapp_country_code = '+91';
      this.form.address = {addressLine1: '', addressLine2: '', area: '', city: '', state: '', country: '', pincode: ''};
      this.form.business_detail = {personName: '', instagramLink: null, twitterLink: null};
      this.socialMedia = {...vSocialMedia};
    },
    closeDialog() {
      this.$refs['qForm'].resetValidation();
      this.resetForm();
      this.$emit('update:dialog', false);
    }
  }
}
</script>

<style scoped>
.card-content {
  max-height: calc(100vh - 116px);
  overflow-y: auto
}

.aj-container {
  max-width: 1140px;
  margin: 0 auto;
}
/deep/ .q-field__native {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.searchBox {
  /*height: 50px !important;*/
}
.section-title{
  font-size: 18px;
}
</style>
