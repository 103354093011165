import gql from "graphql-tag";

export const GET_ORGANIZATION_BY_ID = gql`
query getOrganizationById($id: uuid!){
  organizations(where: { id: {_eq: $id}}){
    id
    name
    mobile
    email
    website
    logo
    whatsapp
    mobile_country_code
    whatsapp_country_code
    business_detail
    address
    category
    designation
    slogan 
    services
    service_title
  }
}`

export const GET_DOMAIN = gql`
query getDomain($id: uuid!){
  organizations(where: { id: {_eq: $id}}){
    id  
    website
  }
}`

export const GET_ORGANIZATION_BACK_DETAILS_BY_ID = gql`
query getOrganizationById($id: uuid!){
  organizations(where: { id: {_eq: $id}}){
    id  
    logo    
    slogan 
    services
    service_title
    email
  }
}`

export const GET_BUSINESS_CATEGORIES = gql`
query getBusinessCategories{
  business_categories: o_business_categories(where: { is_active: {_eq: true}, deleted_at: {_is_null: true}}){
    id
    name   
  }
}`