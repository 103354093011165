import {ref} from "vue";
import {useQuery} from "@vue/apollo-composable";
import {SEARCH_COUNTRY} from "../graphql/queries/territories";

export default function useSearchCountry() {
    const countryFilter = ref('');
    const countryArr = ref([{name:'India', dial_code:'+91'}]);
    let updateFn = () => {};

    const {onResult, loading:countryLoading} = useQuery(SEARCH_COUNTRY, () => ({countryFilter: `%${countryFilter.value}%`}),
        () => ({enabled: countryFilter.value, clientId:'clientB'}));
    onResult(({data}) => {
        countryArr.value = data.countries;
        updateFn();
    });

    function searchCountry(val, update, abort) {
        if (val.length < 1) return abort();
        countryFilter.value = val;
        updateFn = update;
        setTimeout(() => {if(!countryLoading.value) update(() => {})}, 500);      /* Not efficient */
    }

    return {countryArr, searchCountry, countryLoading}
}