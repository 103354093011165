import gql from "graphql-tag";

export const CREATE_UPDATE_DC_CMS = gql`
mutation upsert_dc_cms($dc_cms:[dc_cms_insert_input!]!, $updateColumns: [dc_cms_update_column!]!) {
  insert_dc_cms(objects: $dc_cms, on_conflict: {constraint: dc_cms_customer_id_key, update_columns: $updateColumns}){
    returning{id}
  }
}`

export const CREATE_UPDATE_DC_PRODUCT = gql`
mutation upsert_dc_products($product:[dc_customer_products_insert_input!]!, $updateColumns: [dc_customer_products_update_column!]!) {
  insert_dc_customer_products(objects: $product, on_conflict: {constraint: dc_customer_products_pkey, update_columns: $updateColumns}){
    returning{id}
  }
}`

export const CREATE_UPDATE_DC_VIDEO = gql`
mutation upsert_dc_videos($video:[dc_customer_videos_insert_input!]!, $updateColumns: [dc_customer_videos_update_column!]!) {
  insert_dc_customer_videos(objects: $video, on_conflict: {constraint: dc_customer_videos_pkey, update_columns: $updateColumns}){
    returning{id}
  }
}`

export const CREATE_UPDATE_DC_CERTIFICATE = gql`
mutation upsert_dc_certificates($certificate:[dc_customer_certificates_insert_input!]!, $updateColumns: [dc_customer_certificates_update_column!]!) {
  insert_dc_customer_certificates(objects: $certificate, on_conflict: {constraint: dc_customer_certificates_pkey, update_columns: $updateColumns}){
    returning{id}
  }
}`

export const CREATE_UPDATE_DC_TESTIMONIAL = gql`
mutation upsert_dc_testimonials($testimonial:[dc_customer_testimonials_insert_input!]!, $updateColumns: [dc_customer_testimonials_update_column!]!) {
  insert_dc_customer_testimonials(objects: $testimonial, on_conflict: {constraint: dc_customer_testimonials_pkey, update_columns: $updateColumns}){
    returning{id}
  }
}`

export const CREATE_UPDATE_DC_QR_CODES = gql`
mutation upsert_dc_qrcodes($qrcode:[dc_customer_qrcodes_insert_input!]!, $updateColumns: [dc_customer_qrcodes_update_column!]!) {
  insert_dc_customer_qrcodes(objects: $qrcode, on_conflict: {constraint: dc_customer_qrcodes_pkey, update_columns: $updateColumns}){
    returning{id}
  }
}`

export const CREATE_UPDATE_DC_BRANCH = gql`
mutation upsert_dc_branches($branch:[dc_customer_branches_insert_input!]!, $updateColumns: [dc_customer_branches_update_column!]!) {
  insert_dc_customer_branches(objects: $branch, on_conflict: {constraint: dc_customer_branches_pkey, update_columns: $updateColumns}){
    returning{id}
  }
}`
