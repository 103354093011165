<template>
  <div class="aj-container">

    <div class="row justify-between items-center q-mt-lg q-mb-lg">
      <h1 class="text-h5 q-my-none" style="font-weight: 600">Our Available Products</h1>
    </div>
    <div class="row q-col-gutter-lg cursor-pointer">
      <div class="col-sm-12 col-md-12" @click="$router.push('/cardBuilder')">
        <q-card flat bordered class="my-card no-border-radius">
          <q-card-section>
            <div class="row justify-between no-wrap">
              <div class="row items-center">
                <div>
                  <img src="../assets/images/businessCard.png" style="width: 80px" alt="businessDetails">
                </div>
                <div class="q-ml-md">
<!--                  <h1 class="text-h6 text-weight-bold" style="font-weight: 600">ByteCard</h1>-->
                  <img src="../assets/images/ByteCardLogo.png" alt="ByteCardLogo" style="height: 30px; margin:8px 0 -2px 0">
                  <p class="text-body2 text-weight-medium q-mb-none text-grey-7">Come with us and let's have your own brand on digital platform</p>
                </div>
              </div>
              <div class="col-2 text-right" style="width: 31.6667%">
                <q-btn text-color="blue-6" color="blue-1" class="text-capitalize" size="md" label="Build ByteCard"
                       @click="$router.push('/cardBuilder')"/>
                <!-- <q-linear-progress stripe class="q-mt-md" size="5px" :value="0.4"/> -->
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="row justify-between items-center q-mt-xl q-mb-lg">
      <h1 class="text-h5 q-my-none" style="font-weight: 600">Upcoming Products</h1>
    </div>
    <div class="row q-col-gutter-lg cursor-pointer">
      <div class="col-sm-12 col-md-12" @click="$router.push('/byteBiz')">
        <q-card flat bordered class="my-card no-border-radius">
          <q-card-section>
            <div class="row justify-between no-wrap">
              <div class="row items-center">
                <div>
                  <img src="../assets/images/businessCard.png" style="width: 80px" alt="businessDetails">
                </div>
                <div class="q-ml-md">
                  <h1 class="text-h6 text-weight-bold" style="font-weight: 600">ByteBiz</h1>
                  <p class="text-body2 text-weight-medium q-mb-none text-grey-7">Let's manage your business and team with us with more ease</p>
                </div>
              </div>
              <div class="col-2 text-right" style="width: 23.6667%">
                <q-btn text-color="blue-6" color="blue-1" class="text-capitalize" size="md" label="Know More"
                       @click="$router.push('/byteBiz')"/>
                <!-- <q-linear-progress stripe class="q-mt-md" size="5px" :value="0.4"/> -->
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
   <!-- <div class="row justify-between items-center q-mt-lg q-mb-lg">
      <h1 class="text-h5 q-my-none" style="font-weight: 600">Dashboard</h1>
    </div>
    <div class="row q-col-gutter-lg">
      <div class="col-xs-12 col-md-6" @click.native="$router.push('/cardBuilder')" style="cursor: pointer">
        <q-card flat bordered class="my-card no-border-radius">
          <q-card-section>
            <div class="row justify-between">
              <div>
                <h1 class="text-h6 text-weight-bold" style="font-weight: 600">Bytecard</h1>
                <q-btn outline class="text-capitalize q-mt-lg text-grey-8" @click.native="$router.push('/cardBuilder')" label="Show Details"/>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-xs-12 col-md-6" @click.native="$router.push('/byteGST')" style="cursor: pointer">
        <q-card flat bordered class="my-card no-border-radius">
          <q-card-section>
            <div class="row justify-between">
              <div>
                <h1 class="text-h6 text-weight-bold" style="font-weight: 600">ByteGST</h1>
                <q-btn outline class="text-capitalize q-mt-lg text-grey-8" @click.native="$router.push('/byteGST')" label="Show Details"/>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Dashboard",
}
</script>

<style scoped>
.aj-custom-path {
  background: #E0F4FC;
  width: 80px;
  height: 80px;
  border-radius: 12px;
  position: relative;
  text-align: center;
  transform: rotate(20deg);
}
.aj-dashboard-path-count{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotate(-20deg) translate(-10%,-50%);
}
.aj-custom-path:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  border-radius: 12px;
  background: #E0F4FC;
  transform: rotate(135deg);
}

/*.aj-custom-path{*/
/*  background: grey;*/
/*  height: 120px;*/
/*  width: 120px;*/
/*  border-radius: 50px;*/
/*  clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);*/
/*}*/
</style>
