import gql from "graphql-tag";

export const SUBSCRIBE_CARD_OVERVIEW_BY_CUSTOMER_ID = gql`
subscription cardOverview($customerId: uuid!){
  dc_customer_widgets(where:{customer_id:{_eq: $customerId}}) {
    id
    products
    videos
    certificates
    qrcodes
    branches
    testimonials
    themes
    about_us
    seo_details
    business_detail
    customers
    social_media   
    updated_at
    is_update
    is_publish
  }
}`